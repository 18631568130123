import axios from 'axios';
import {useState, useContext} from 'react'; 
import { TranslationContext, DialogContext } from "../../App";
import {SaveButton} from './../edit-elements'
import TextField from "@material-ui/core/TextField";

export const FirmwareUpload = (props) => { 
    const {translations} = useContext(TranslationContext)
    const {apiPath, setSuccessZero, setNotifyPopUp} = useContext(DialogContext)
    const [selectedFile, setSelectedFile] = useState(null)
    const [versionName, setVersionName] = useState("")
    
    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const onFileUpload = () => {
        if(selectedFile === null || versionName === null) 
            return;

        const formData = new FormData();
        formData.append( 
            "binFile", 
            selectedFile, 
            selectedFile.name 
          );
        formData.append("pID", props.pID)
        formData.append("version", versionName)
        
        async function uploadFirmwareFile() {
            const token = localStorage.getItem("token");
            const response = await axios.post(`${apiPath}uploadFirmware.php`, formData, { headers: { Authorization: `Bearer ${token}` } }); 

            if(response.data.success)
                setNotifyPopUp({state : true, name : translations?.products?.successfullFirmwareEntry, text : translations?.backend[response.data.message]})
            else
                setSuccessZero({state : true, name : translations?.products?.unsuccessfullFirmwareEntry, text : translations?.backend[response.data.message]});
                
          }
          
          uploadFirmwareFile();
    }     
     
    return ( 
        <div style={{margin : "auto"}}>
            <div className="cellDual">
                <TextField
                    fullWidth={true}
                    label={translations?.core?.file}
                    placeholder={""}
                    disabled={true}
                    value={selectedFile !== null && selectedFile != undefined ? selectedFile.name : ""}
                />
            </div>
            <div className="cellDual">
                <TextField
                    fullWidth={true}
                    label={translations?.core?.version}
                    placeholder={""}
                    disabled={false}
                    onChange={(event) => {setVersionName(event.target.value)}}
                    value={versionName}
                />
            </div>
            <div style={{textAlign: "center"}}>
                <label htmlFor="files" className={'buttonAdd'}>{translations?.products?.uploadFirmware}</label>
                <input id="files" type="file" onChange={(event) => onFileChange(event)} name={translations?.product?.chooseFile} style={{display : "none"}}/> 
                
                <SaveButton 
                    klasaButton={"buttonSave"}
                    name={translations?.core?.save}
                    handleButtonClick={() => onFileUpload()}
                />
            </div>
        </div> 
    ); 
} 