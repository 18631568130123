import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import ErrorPrivilegesPath from "../auth/error-privileges";
import MaterialTable from "material-table";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {SaveButton} from './../edit-elements'
import { NTextField } from "./../edit-elements";
import EditSection from './../C-servers/servers-edit-section';
import './../styles/globalClasses.css'
import {DialogContext, TranslationContext} from './../../App';
import {useHistory} from 'react-router-dom';

const Servers = () => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const {translations} = useContext(TranslationContext)
  const history = useHistory();
  const {setSuccessZero, apiPath, selectedModule, setSelectedModule, refreshState} = useContext(DialogContext)
  const [currentServer, setCurrentServer] = useState({}); // Datamajoris e pred-selektirana kompanija
  const [servers, setServers] = useState([]);
  const [addServerVisible, setAddServerVisible] = useState(false)
  const [firstRender, setFirstRender] = useState(false)

  const response = useQuery("servers", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiPath}fetchServers.php`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if(response.data.success){
        setFirstRender(true);
        setServers(response.data.servers);
        if(typeof(currentServer.id) === "undefined")
            setCurrentServer(response.data.servers[0])
        else{
            response.data.servers.forEach((server)=>{
                if(server.id === currentServer.id )
                    setCurrentServer(server)
            })
        }
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingServers, text : translations?.backend[response.data.message]});
      }

      return response.data;
    } catch (err) {
    }
  });

  const mutation = useMutation((serverData) => {
    const token = localStorage.getItem("token");

    switch(serverData.tip){
      case "domain":
      case "certificate":
        return axios.put(`${apiPath}updateServer.php`, serverData, { headers: { Authorization: `Bearer ${token}` } });
      case "add":
        return axios.post(`${apiPath}addServer.php`, serverData, { headers: { Authorization: `Bearer ${token}` } });  
      default: 
        break;
    }
  });

  const updateServer = (serverData) => {
    mutation.mutate(
      { ...serverData },
      {
        onError: (error) => {
        },
        onSuccess: (dataReturned) => {
          if(dataReturned.data.success)
            response.refetch();
          else{
            switch(serverData.tip){
              case "domain":
                  setSuccessZero({state : true, name : translations?.servers?.errorUpdateDomain, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "certificate":
                  setSuccessZero({state : true, name : translations?.servers?.errorUpdateCertificate, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "add":
                  setSuccessZero({state : true, name : translations?.servers?.errorAddServer, text : translations?.backend[dataReturned.data.message]});
                  break;
              default: 
                break;
            }
          }
        },
      }
    );
  };

  const handleConfirm = ({tip, newValues}) => {
    updateServer({tip : tip, domain : newValues[0], certificate : newValues[1]})
  }

  useEffect(() => {
    if(selectedModule !== "/servers")
      setSelectedModule("/servers")
  }, [])

  if(!firstRender && redirect.state === false)
    return <React.Fragment></React.Fragment>
  
  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>

  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <React.Fragment>
      <div className={"tableFS"}>
          <MaterialTable
          title={translations?.servers?.tableServers}
          options={{
              padding: "dense",
              actionsCellStyle : {paddingLeft : "10px"}, 
          }}
          localization={{
              header: { actions: translations?.core?.actions },
              pagination : {
                firstTooltip : translations?.core?.firstPage,
                lastTooltip : translations?.core?.lastPage,
                nextTooltip : translations?.core?.nextPage,
                previousTooltip : translations?.core?.previousPage,
                labelRowsSelect: translations?.core?.rows,
                labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
              },
              body : {
                emptyDataSourceMessage : translations?.core?.emptyTable
              },
              toolbar: {
                searchPlaceholder: translations?.core?.search,
                searchTooltip : translations?.core?.search
              },
          }}
          columns={[
              { title: translations?.servers?.domain, field: "domain",
                  cellStyle : {padding : "0px 5px", paddingLeft : "45px"}, headerStyle : {paddingLeft : "45px"}},
              { title: translations?.servers?.certificate, field: "certificate", cellStyle : {padding : "0px 3px", paddingRight : "20px"}, headerStyle : {padding : "3px"}},
              { title: "id", field: "id", hidden : true},
          ]}
          data={servers.map((dat) => {
              return {...dat}
          })}
          actions={[
              (rowData) => ({
              icon:
                  currentServer.id === rowData.id
                  ? CheckBoxIcon
                  : CheckBoxOutlineBlankIcon,
              tooltip: translations?.servers?.selectServer,
              onClick: (event, rowData) => {
                  setCurrentServer((rowData));
              },
              }),
              (rowData) => ({
                  icon: DeleteOutlineIcon,
                  tooltip: translations?.servers?.deleteServer,
                  onClick: (event, rowData) => {
                  updateServer({tip : "delete", serverID : rowData.id});
                  },
              }),
              {
                icon: RefreshIcon,
                tooltip: translations?.core?.refresh,
                isFreeAction: true,
                onClick: (event) => response.refetch()
              }
          ]}
          onRowClick={(event, rowData) => {
            setCurrentServer((rowData));
          }}
          />
          {!addServerVisible &&
            <div style={{textAlign: "center", marginTop : "20px"}}>
              <SaveButton
                name={translations?.servers?.addServer}
                handleButtonClick={() => {setAddServerVisible(true)}}
                klasaButton={"buttonAdd"}
              />
            </div>
          }
          {addServerVisible &&
            <div className="addSection">
                <h2 className={"addSectionGlavenNaslov"}>{translations?.servers?.addServer}</h2>
                <NTextField 
                  naslov={[translations?.servers?.domain, translations?.servers?.certificate]}
                  labels={[translations?.servers?.domain, translations?.servers?.certificate]}
                  inits={["", ""]}
                  tip={"add"}
                  autoFocus={[true, false]}
                  placeholder={["example.datamajoris.net", "c7b2267e59d853eb6af5c33bb02898e82b2177cd"]}
                  multiline={[false, false]}
                  handleEnter={handleConfirm}
                  klasa={"childAddFS"}
                  klasaNaslov={"naslovMal"}
                  klasaButton={"buttonConfirm"}
                  save
                  buttonName={translations?.core?.confirm}
                  cancel={true}
                  cancelKlasa={"buttonCancel"}
                  cancelFunc={() => setAddServerVisible(false)}
                  allowEnter={false}
                />
            </div>
          }
      </div>
      
      <EditSection current={currentServer} updateServer={updateServer}></EditSection>
    </React.Fragment>
  );
};

export default Servers;
