import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import MaterialTable from 'material-table';
import {
  RemoveCircleOutlineRounded,
} from '@material-ui/icons';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import './../styles/globalClasses.css';
import { DialogContext , TranslationContext} from './../../App';
import Access from '../C-users/access';
import {formatDatumVreme} from './../formatDates';
import ErrorPrivilegesPath from '../auth/error-privileges';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import SupervisedUserCircleTwoToneIcon from '@material-ui/icons/SupervisedUserCircleTwoTone';
import RemoveCircleTwoToneIcon from '@material-ui/icons/RemoveCircleTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {useHistory} from 'react-router-dom';

export const Users = () => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const {translations} = useContext(TranslationContext)
  const {setSuccessZero, apiPath, setNotifyPopUp, selectedModule, setSelectedModule, refreshState} = useContext(DialogContext);
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({})
  const [firstRender, setFirstRender] = useState(false)

  const response = useQuery('users', async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${apiPath}fetchUsers.php`, { headers: {"Authorization" : `Bearer ${token}`} });

      if(response.data.success){
        setFirstRender(true);
        setUsers(response.data.users);
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingDevices, text : translations?.backend[response.data.message]});
      }

      return response.data.users;
    } catch (err) {
    }
  });

  const mutation = useMutation((userData) => {
    const token = localStorage.getItem("token");
    
    return axios.put(`${apiPath}updateUser.php`, userData, { headers: { Authorization: `Bearer ${token}` } });
  });


  const updateUser = (userData) => {
    mutation.mutate(
      { ...userData },
      {
        onError: (error) => {
        },
        onSuccess: (dataReturned) => {
          if(dataReturned.data.success)
            response.refetch();
          else{
            setSuccessZero({state : true, name : translations?.users?.errorUpdatingUser, text : translations?.backend[dataReturned.data.message]})
          }
        }
      }
    );
  };

  useEffect(() => {
    if(selectedModule !== "/users")
      setSelectedModule("/users")
  }, [])

  if(!firstRender && redirect.state === false)
    return <React.Fragment></React.Fragment>

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>
  
  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <>
      <div className={"tableFS"}>
      <MaterialTable
          title={translations?.users?.tableUsers}
          options={{
            padding : "dense",
            headerStyle : {padding : "3px"},
            actionsCellStyle : {paddingLeft : "0px"},
          }}
          localization={{
            header: { actions: translations?.core?.actions },
            pagination : {
              firstTooltip : translations?.core?.firstPage,
              lastTooltip : translations?.core?.lastPage,
              nextTooltip : translations?.core?.nextPage,
              previousTooltip : translations?.core?.previousPage,
              labelRowsSelect: translations?.core?.rows,
              labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
            },
            body : {
              emptyDataSourceMessage : translations?.core?.emptyTable
            },
            toolbar: {
              searchPlaceholder: translations?.core?.search,
              searchTooltip : translations?.core?.search
            },
          }}
          // style={{fontSize : "0.85em"}}
          columns={[
            {title: translations?.users?.firstName,field: 'firstName', cellStyle : {paddingLeft : "10px"}, headerStyle : {paddingLeft : "10px"}},
            {title: translations?.users?.lastName ,field: 'lastName',},
            {title: translations?.users?.email, field: 'email'},
            {title: translations?.users?.banned, field: 'banned',},
            {title: translations?.users?.deleted,field: 'deleted',},
            {title: translations?.users?.role , field: 'userRole' },
            {title: translations?.users?.lastChangeBy ,field: 'lastChangeFrom',},
            {title: translations?.users?.lastChangeTime ,field: 'lastChangeTimestamp',},
            {title: translations?.users?.confirmed,field: 'isconfirmed',hidden: true,},
            {title: translations?.users?.banned ,field: 'isbanned',hidden: true,},
            {title: translations?.users?.deleted,field: 'isdeleted',hidden: true,},
            {title: translations?.users?.admin ,field: 'isadmin',hidden: true,},
            {title: "id" ,field: 'id',hidden: true,},
          ]}
          data={users.map((user) => {
            let banned = user.isbanned === "1" ? translations?.users?.banned : translations?.users?.unbanned;
            let deleted = user.isdeleted === "1" ? translations?.users?.deleted : translations?.users?.notDeleted;
            let userRole = user.isadmin === "1" ? translations?.users?.admin : translations?.users?.user;
            let lastChangeTimestamp = formatDatumVreme(user.lastChangeTimestamp, translations?.data?.day);

            return {...user, banned : banned, deleted : deleted, userRole : userRole, lastChangeTimestamp : lastChangeTimestamp}
          })}
          actions={[
            (rowData) => ({
              icon: currentUser.id === rowData.id ? CheckBoxTwoToneIcon : CheckBoxOutlineBlankIcon,
              tooltip: translations?.users?.selectUser,
              disabled: Boolean(rowData.isdeleted === "1"),
              onClick: (event, rowData) => {
                users.forEach((user) => {
                  if(user.id === rowData.id)
                    setCurrentUser((user));
                  if(rowData.id === currentUser.id)
                    setCurrentUser('');
                })
              },
            }),
            (rowData) => ({
              icon: rowData.isconfirmed === "1" ? CheckCircleTwoToneIcon : CheckCircleOutlineRoundedIcon,
              tooltip: rowData.isconfirmed === "1" ? translations?.users?.confirmed : translations?.users?.confirmUser,
              disabled: Boolean(rowData.isdeleted === "1"),
              onClick: (event, rowData) => {
                if(rowData.isconfirmed === "0"){
                  updateUser({ id: rowData.id, isconfirmed: true });
                  setNotifyPopUp({state : true, name : translations?.users?.userConfirmationPopUp
                    , text : translations?.users?.userConfirmationPopUpMessage})
                }
              },
            }),
            (rowData) => ({
              icon: rowData.isbanned === "1" ? RemoveCircleTwoToneIcon : RemoveCircleOutlineRounded,
              disabled: Boolean(rowData.isdeleted === "1"),
              tooltip: rowData.isbanned === "1"
                ? translations?.users?.unbannedTooltip
                : translations?.users?.bannedTooltip,
              onClick: (event, rowData) => {
                updateUser({ id: rowData.id, isbanned: true });
              },
            }),
            (rowData) => ({
              icon: rowData.isadmin === "1" ? SupervisedUserCircleTwoToneIcon : SupervisorAccountOutlinedIcon,
              tooltip: rowData.isadmin === "1"
                ? translations?.users?.setAsUser
                : translations?.users?.setAsAdmin,
              disabled: Boolean(!rowData.isconfirmed === "0" || rowData.isbanned === "1" || rowData.isdeleted === "1"),
              onClick: (event, rowData) => {
                updateUser({ id: rowData.id, isadmin: true });
              },
            }),
            (rowData) => ({
              icon: rowData.isdeleted === "1" ? HighlightOffTwoToneIcon : HighlightOffIcon,
              tooltip: rowData.isdeleted === "1"
                ? translations?.users?.deleted
                : translations?.users?.deleteUserAction,
              disabled: Boolean(rowData.isdeleted === "1"),
              onClick: (event, rowData) => {
                updateUser({ id: rowData.id, isdeleted: true });
              }
            }),
            {
              icon: RefreshIcon,
              tooltip: translations?.core?.refresh,
              isFreeAction: true,
              onClick: (event) => response.refetch()
            }
          ]} 
          onRowClick={(event, rowData) => {
            users.forEach((user) => {
              if(rowData.isdeleted !== "1"){
                if(user.id === rowData.id)
                  setCurrentUser((user));
                if(rowData.id === currentUser.id)
                  setCurrentUser('');
              }
            })
          }}
        />
      </div>
      {typeof(currentUser?.id) !== "undefined" &&
        <Access id={currentUser.id}/>
      }
    </>
  );
};

