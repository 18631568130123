import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { FormControl, InputLabel, makeStyles, createStyles, MenuItem, Select } from '@material-ui/core';
import ErrorPrivilegesPath from "../auth/error-privileges";
import {DialogContext, TranslationContext} from './../../App'
import {Graph} from './../C-reports/graph';
import {SaveButton} from './../edit-elements'
import {useHistory} from 'react-router-dom';
import {ToggleSwitch} from "./../C-reports/toggle-switch";

const SPLIT_CARD_GRAPH = 900
const DEFAULT_ZOLT = 800;
const DEFAULT_CRVEN = 1200;

const setScatterData = (sensorData) => {
    return sensorData
      ?.reverse()
      .map((sensor, index) => ({
        valueY: `${Number(sensor.CO2)}`,
        valueY2: `${Number(sensor.Tem)}`,
        Time: new Date(sensor.t.replace(/-/g, "/")).getTime(),
    }));
}

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    graphContainer: {
        margin : "auto",
        marginTop : "30px",
        width : "95%",
        position : "relative",
        top : "-40px",
        [breakpoints.down(SPLIT_CARD_GRAPH)]: {
            width : "90%",
            margin : "0 10px",
            float : "none",
            boxSizing : "border-box",
            top : "-20px"
      },
    },
    formControl: {
        margin: '0.5em',
        display : "inline-block",
        width : "100px",
        position : "relative",
        top : "70px",
        left : "90px",
        zIndex : 5,
        [breakpoints.down(700)]: {
            left : "20px",
            top  : "50px"
      },
    },
    buttonCancelStyle : {
        margin : "auto",
        width : "75px",
        display : "block",
        marginTop : "50px"
    }
  })
);

export const GraphDM = (props) => {
    const {apiPath, refreshState } = useContext(DialogContext)
    const history = useHistory();
    const { translations } = useContext(TranslationContext);
    const classes = useStyles();
  
    const [ pragCrven, setPragCrven ] = useState(DEFAULT_CRVEN);
    const [ pragZolt, setPragZolt ] = useState(DEFAULT_ZOLT);
    const [currentGraph, setCurrentGraph] = useState("CO2")
    
    const [ sensorData, setSensorData ] = useState([]);
    const [ interval, setInterval ] = useState(1);

    const [redirect, setRedirect] = useState({state : false, status : 401})

    const refetch = useQuery('sensor-data', async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${apiPath}getDeviceData.php?highPrivileges=1&serialID=` + props.serialID + '&interval=' + interval, { headers: { Authorization: `Bearer ${token}` } });
            if(response.data.success){
              setSensorData(setScatterData(response.data.records));
            }
            else{
              // TO DO
              if(response.data.status === 401 || response.data.status === 403) // unauthorized
                setRedirect({state : true, status : response.data.status})
              setSensorData(setScatterData([]));
            }

            return response.data.record;
        } catch (err) {
        }
    }, {
      refetchOnWindowFocus: false,
      enabled: false
    });
    
    const refetchDeviceState = useQuery('device-state', async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${apiPath}deviceState.php?highPrivileges=1&serialID=` + props.serialID, { headers: { Authorization: `Bearer ${token}` } });
        if(response.data.success){
          setPragCrven(response?.data?.data?.pragCrven);
          setPragZolt(response?.data?.data?.pragZolt);
        }
        else if (response.data.success === 0) {
          // TO DO
          if(response.data.status === 401 || response.data.status === 403) // unauthorized
            setRedirect({state : true, status : response.data.status})
        }

        return response.data.data;
      } catch (err) {
      }
    });

    const refetchDevice = () => {
        refetch.refetch()
        refetchDeviceState.refetch()
    }    

    useEffect(() => {
      refetchDevice();
    }, [interval, props.serialID])
    
    if(redirect.state){
      // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
      if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
        history.go(0);
      else
        return <ErrorPrivilegesPath  status={redirect.status} />
    }

    // da ne menuvame controlled - uncontrolled input dodeka building, place i device se undefined
    if (props.serialID === undefined)
      return <React.Fragment></React.Fragment>

    return (
      <>
        <div style={{marginTop : "-60px", marginBottom : "40px"}}>
            <h2 className={"editSectionGlavenNaslov"} style={{position : "relative", top : "50px"}}>{translations?.devices?.inventoryGraphTitle}</h2>
            <FormControl className={classes.formControl}>
              <InputLabel id="mesto">{translations?.data?.interval}</InputLabel>
              <Select
                  autoWidth={true}
                  className={classes.formSelectPlace}
                  value={interval}
                  onChange={(event) => { setInterval(Number(event.target.value)) }}
              >
                  {[1, 3, 7].map((singleInterval) => (
                  // FIX: key may not be unique
                  <MenuItem key={singleInterval} value={singleInterval}>
                      {singleInterval !== 1 && singleInterval} {singleInterval === 1 ? translations?.data?.day : translations?.data?.days}
                  </MenuItem>
                  ))}
              </Select>
              <div style={{position : "relative", top : "-54px", left : "74px"}}>
                <ToggleSwitch setCurrentGraph={setCurrentGraph} currentGraph={currentGraph}/>
              </div>
            </FormControl>
            
            <div className={classes.graphContainer} >
                <Graph 
                    type={"wide"}
                    currentGraph={currentGraph}
                    sensorData={sensorData}
                    interval={interval}
                    pragCrven={pragCrven}
                    pragZolt={pragZolt}
                />
            </div>
            <div className={classes.buttonCancelStyle}>
                <SaveButton 
                    name={translations?.core?.cancel}
                    klasaButton={"buttonCancel"}
                    handleButtonClick={props.setNotShowGraph}
                />
            </div>
        </div>
      </>
    )
}
