import "./toggle-switch.css";

export const ToggleSwitch = (props) => {
    return (
        <div className="container-toggle">
            <div className="toggle-switch-div">
                <input type="checkbox" className="checkbox-toggle" name={"toggle-bar"} id={"toggle-bar"} />
                <label className="label-toggle" htmlFor={"toggle-bar"}  
                    onClick={() => {
                        let newCurrentGraph = props.currentGraph === "CO2" ? "T" : "CO2"
                        props.setCurrentGraph(newCurrentGraph);
                    }}
                >
                    <span className="inner-toggle" />
                    <span className="toggle-switch-span" />
                </label>
            </div>
        </div>
    );
};
