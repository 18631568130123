import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {NTextField, SaveButton, EditSectionNaslov} from './../edit-elements'
import ChooseLocation from './choose-location'
import MaterialTable from "material-table";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import './../styles/globalClasses.css';
import { TranslationContext } from "../../App";

const useStyles = makeStyles(theme => ({
  editDates : {
    margin : "auto",
    display : "block",
    marginBottom : "10px",
    marginTop : "20px",
    width : "260px",
  },
}));

const EditSection = ({current, updateDevice}) => {
    const {translations} = useContext(TranslationContext)
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState({name : false, comment : false, location : false, endDate : false, settings : false})
    const [newCurrent, setNewCurrent] = useState({});

    useEffect(()=>{
      setNewCurrent({...current})
    }, [current])
    
    const handleSelect = (tip) => {
      let newIsExpanded = {...isExpanded}
      newIsExpanded[tip] = !newIsExpanded[tip]
      setIsExpanded(newIsExpanded)
    }
    const handleDateUpdate = () => {
        const d = new Date(newCurrent.contractEndDate);
        const newEndDate = ((new Date(d.getTime())).toISOString().split('T')[0]) 
      handleEnter({tip : "endDate", newValues : [newEndDate]})
    }
  
    const handleEnter = ({tip, newValues, labels}) => {
    //   handleSelect(tip);  
      switch(tip){
        case "name":    
          setNewCurrent({...newCurrent, deviceName : newValues[0]})
          updateDevice({tip : tip, deviceName : newValues[0], serialID : current.serialID}); 
          break;
        case "comment": 
          setNewCurrent({...newCurrent, comment : newValues[0]})
          updateDevice({tip : tip, comment : newValues[0], serialID : current.serialID}); 
          break;
        case "endDate":
          setNewCurrent({...newCurrent, endDate : newValues[0]})
          updateDevice({tip : tip, endDate : newValues[0], serialID : current.serialID})
          break;
        case "location":
          setNewCurrent({...newCurrent, zgradaID : newValues[0], mestoID : newValues[1]})
          updateDevice({tip : tip, zgradaID : newValues[0], mestoID : newValues[1], serialID : current.serialID})
          break;
        case "settings":
          let setting = {};
          setting[newValues[0]] = newValues[1]
          updateDevice({tip : tip, changes : {...setting}, serialID : current.serialID})
          break;
        default: 
            break;
      }
    }
  
    return (
      <React.Fragment>
        <h2 className={"editSectionGlavenNaslov"}>{translations?.devices?.inventoryEdit}</h2>
        <div className={"editSectionLeft"}>
          <EditSectionNaslov naslov={translations?.devices?.name} expanded={isExpanded.name} tip={"name"} handleSelect={handleSelect}/>
          {
            isExpanded.name && 
            <NTextField 
              labels={[translations?.devices?.name]}
              inits={[newCurrent.deviceName]}
              tip={"name"}
              save={true}
              handleEnter={handleEnter}
              klasa={"cellSingle"}
              klasaButton={"buttonSave"}
            />
          }

          <EditSectionNaslov naslov={translations?.devices?.comment} expanded={isExpanded.comment} tip={"comment"} handleSelect={handleSelect}/>
          {
            isExpanded.comment && 
            <NTextField 
              labels={[translations?.devices?.comment]}
              inits={[newCurrent.comment]}
              tip={"comment"}
              multiline={[true]}
              save={true}
              handleEnter={handleEnter}
              klasa={"cellMultiline"}
              klasaButton={"buttonSave"}
            />
          }

          <EditSectionNaslov naslov={translations?.locations?.location} expanded={isExpanded.location} tip={"location"} handleSelect={handleSelect}/>
          {isExpanded.location && 
              <ChooseLocation companyID={current.companyID} handleEnter={handleEnter} zgradaName={current.zgradaName} mestoName={current.mestoName}
          />}
        </div>
        <div className={"editSectionRight"}>
          <EditSectionNaslov naslov={translations?.companies?.contractEnding} expanded={isExpanded.endDate} tip={"endDate"} handleSelect={handleSelect}/>
          {isExpanded.endDate &&  (<div >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  label={translations?.companies?.contractEnding}
                  fullWidth={false}
                  className={classes.editDates}
                  value={newCurrent.contractEndDate}
                  onChange={(date) => {
                    setNewCurrent({...newCurrent, contractEndDate : date})
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <div style={{textAlign: "center"}}>
                <SaveButton 
                  name={translations?.core?.save}
                  handleButtonClick={handleDateUpdate}
                  klasaButton={"buttonSave"}
                />
              </div>
              </div>)
            }

          <EditSectionNaslov naslov={translations?.devices?.settings} expanded={isExpanded.settings} tip={"settings"} handleSelect={handleSelect}/>
          { isExpanded.settings && 
            <PropertyTableDevice deviceSettings={newCurrent.deviceSettings} handleEnter={handleEnter}/>
          }
        </div>
      </React.Fragment>
    )
  }
  
export default EditSection;  


export const PropertyTableDevice = ({deviceSettings, handleEnter}) => {
  const {translations} = useContext(TranslationContext)
  const [currentProperty, setCurrentProperty] = useState({})
  const [keys, setKeys] = useState([])
  const [settings, setSettings] = useState({})

  useEffect(()=>{
    setCurrentProperty({visible : false, key : "", value : ""})
    if (typeof(deviceSettings) !== "undefined"){
      let k = Object.keys(deviceSettings)
      setKeys(k);
    }
    setSettings(deviceSettings)
  }, [deviceSettings])

  return (
    <React.Fragment>
      <MaterialTable
        options={{
          padding: "dense",
          actionsCellStyle : {paddingLeft : "12px"}
        }}
        localization={{
          header: { actions: translations?.core?.actions },
          pagination : {
            firstTooltip : translations?.core?.firstPage,
            lastTooltip : translations?.core?.lastPage,
            nextTooltip : translations?.core?.nextPage,
            previousTooltip : translations?.core?.previousPage,
            labelRowsSelect: translations?.core?.rows,
            labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
          },
          body : {
            emptyDataSourceMessage : translations?.core?.emptyTable
          },
          toolbar: {
            searchPlaceholder: translations?.core?.search,
            searchTooltip : translations?.core?.search
          },
        }}
        columns={[
          { title: translations?.devices?.property, field: "property", cellStyle : {paddingLeft : "35px"}, headerStyle : {paddingLeft : "35px"}},
          { title: translations?.devices?.value, field: "value", cellStyle : {paddingLeft : "15px"}},
        ]}
        data={keys.map((dat) => {
          return {property : dat, value : settings[dat]}
        })}
        title={translations?.devices?.tableSettings}
        actions={[
          (rowData) => ({
            icon: EditOutlinedIcon,
            tooltip: translations?.devices?.editKeyValuePair,
            onClick: (event, rowData) => {
              setCurrentProperty({visible : true, key : rowData.property, value : rowData.value});
            },
          }),
        ]}
        onRowClick={(event, rowData) => {
          setCurrentProperty({visible : true, key : rowData.property, value : rowData.value});
        }}
      />
      {
        currentProperty.visible && 
        <NTextField 
          labels={[translations?.devices?.property, translations?.devices?.value]}
          inits={[currentProperty.key, currentProperty.value]}
          tip={"settings"}
          disabled={[true, false]}
          handleEnter={(args) => {
            let ob = {...settings};
            ob[args.newValues[0]] = args.newValues[1];
            setCurrentProperty({key : args.newValues[0], value : args.newValues[1]})
            setSettings({...ob})
            handleEnter(args)
          }}
          klasa={"cellDual"}
          save={true}
          klasaButton={"buttonSave"}
        />
      }
    </React.Fragment>
  )
}