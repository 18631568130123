export const formatDatum = (datum_in, extraTime = false) => {
    if(typeof(datum_in) !== "string")
      return '–'
    if(datum_in === 0 || datum_in === "0")
      return '–';

    if(extraTime)
      datum_in = datum_in.split(' ')[0]

    let datum = datum_in.split('-');
  
    let novDatum = datum[2] + "." + datum[1] + "." + datum[0];
  
    return novDatum;
  }
  
export const formatDatumVreme = (datumVreme, today = false) => {
    if(typeof(datumVreme) !== "string")
      return "–";
    if(datumVreme === 0 || datumVreme === "0")
      return "–";

    let datum = (datumVreme.split(' ')[0]).split('-');
    let vreme = (datumVreme.split(' ')[1]).split(':');
  
    let novDatum = datum[2] + "." + datum[1] + "." + datum[0];
    let novoVreme = vreme[0] + ":" + vreme[1];
  
    if(today){
      let currentDate = new Date()
      if (Number(datum[2]) === currentDate.getDate() && Number(datum[1]) === currentDate.getMonth() + 1  && Number(datum[0]) === currentDate.getFullYear())
        return novoVreme + "h " + today;
    }

    return novDatum + " " + novoVreme + "h";
}

export const formatDatumVremeTS = (datumVreme, today = "") => {
  if(datumVreme === 0)
    return '–'

  const formatOptions = { year: "numeric", month: "2-digit", day: "2-digit", hour : "2-digit", minute : "2-digit", second : "2-digit"}

  if(today !== ""){
    let currentDate = new Date().toLocaleString('de-DE', formatOptions).split(', ')
    let datumVremeString = new Date(datumVreme).toLocaleString('de-DE', formatOptions).split(', ')

    if(currentDate[0] === datumVremeString[0])
      return datumVremeString[1].substring(0, 5) + "h " + today

    return datumVremeString[0] + " " + datumVremeString[1].substring(0, 5) + "h"
  }

  return new Date(datumVreme).toLocaleString('de-DE')
}