import React, {useState, useContext } from "react";
import axios from "axios";
import { useQuery} from "react-query";
import ErrorPrivilegesPath from "../auth/error-privileges";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {SaveButton} from './../edit-elements'
import Select from "react-select";
import './../styles/globalClasses.css';
import { NTextField } from "./../edit-elements";
import { DialogContext, TranslationContext} from './../../App';
import {useHistory} from 'react-router-dom';

const SellDevices = ({setCriticalValue, setCriticalAction}) => {
    const [redirect, setRedirect] = useState({state : false, status : 401})
    const {translations} = useContext(TranslationContext)
    const {setSuccessZero, apiPath, refreshState} = useContext(DialogContext)
    const history = useHistory();
    const [sellVisible, setSellVisible] = useState(false)
    const [productsDropDown, setProductsDropDown] = useState([]);
    const [companiesDropDown, setCompaniesDropDown] = useState([]);
    const [currentCompany, setCurrentCompany] = useState({});
    const [currentProduct, setCurrentProduct] = useState({});
    const [contractEndDate, setContractEndDate] = useState((new Date((new Date()).getTime() - (60000 * (new Date()).getTimezoneOffset()))).toISOString().split('T')[0]) // momentalen datum vo pravilen format

    useQuery("products-dropdown", async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${apiPath}fetchProducts.php`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if(response.data.success){
          let newProducts = [];
          response.data.products.forEach((product, index)=>{
            newProducts.push({label : product.productName, value : product.pID})
            if(index === 0) setCurrentProduct(newProducts[0])
          })
          setProductsDropDown(newProducts);
        }
        else {
          // TO DO
          if(response.data.status === 401 || response.data.status === 403) // unauthorized
            setRedirect({state : true, status : response.data.status})
          else
            setSuccessZero({state : true, name : translations?.locations?.errorFetchingProducts, text : translations?.backend[response.data.message]});
        }
    
        return response.data;
      } catch (err) {
      }
    })
    useQuery("companies-dropdown", async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${apiPath}fetchCompanies.php`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if(response.data.success){
          let newCompanies = [];
          response.data.companies.forEach((company, index)=>{
            newCompanies.push({label : company.companyName, value : company.companyID})
            if (index === 0) setCurrentCompany(newCompanies[0])
          })
          setCompaniesDropDown(newCompanies);
        }
        else {
          // TO DO
          if(response.data.status === 401 || response.data.status === 403) // unauthorized
            setRedirect({state : true, status : response.data.status})
          else
            setSuccessZero({state : true, name : translations?.locations?.errorFetchingCompanies, text : translations?.backend[response.data.message]});
        }
    
        return response.data;
      } catch (err) {
      }
    })
 
    const stopSale = () => {
        setSellVisible(false);
    }
    const handleConfirm = (values) => {
        const d = new Date(contractEndDate);
        const newEndDate = ((new Date(d.getTime())).toISOString().split('T')[0])
        setCriticalValue({tip : "sell", pID : currentProduct.value, companyID : currentCompany.value, number :  values.newValues[0], endDate : newEndDate})
        setCriticalAction({state : true, name : translations?.devices?.criticalSellDevicesNaslov,
                           text : `${values.newValues[0]} ${translations?.devices?.criticalSellDevicesText[0]} \n\n${currentCompany.label}`})
        setSellVisible(false);
    }

    if(redirect.state){
      // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
      if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
        history.go(0);
      else
        return <ErrorPrivilegesPath  status={redirect.status} />
    }

    return (
        <div className="addSection">
            {!sellVisible &&
              <div style={{textAlign: "center"}}>
                <SaveButton
                  name={translations?.devices?.sellDevices}
                  klasaButton={"buttonAdd"}
                  handleButtonClick={() => {setSellVisible(true)}}
                />
              </div>
            }
            {sellVisible &&
                <React.Fragment>
                  <h2 className={"addSectionGlavenNaslov"}>{translations?.devices?.sellDevices}</h2>
                  <div className={"childAddFS"}  style={{top : "10px", position : "relative"}}>
                      <h3 className={"naslovMal"}>{translations?.companies?.company}</h3>
                      <Select
                          onChange={(newValue, actionMeta) => {
                          if (actionMeta.action === "select-option")
                              setCurrentCompany(newValue);
                          }}
                          options={companiesDropDown}
                          value={currentCompany}
                      />
                  </div>
                  <div className={"childAddFS"}  style={{top : "10px", position : "relative"}}>
                      <h3 className={"naslovMal"}>{translations?.products?.productType}</h3>
                      <Select
                          onChange={(newValue, actionMeta) => {
                          if (actionMeta.action === "select-option")
                              setCurrentProduct(newValue);
                          }}
                          options={productsDropDown}
                          value={currentProduct}
                      />
                  </div>
                  <div className={"childAddFS"}>
                      <h3 className={"naslovMal"}>{translations?.companies?.contract}</h3>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          label={translations?.companies?.contractEnding}
                          value={contractEndDate}
                          onChange={(date) => {
                            setContractEndDate(date)
                          }}
                          KeyboardButtonProps={{
                          'aria-label': 'change date',
                          }}
                      />
                      </MuiPickersUtilsProvider>
                  </div>
                  <NTextField 
                    naslov={[translations?.devices?.amount]}
                    labels={[translations?.devices?.amount]}
                    inits={[0]}
                    tip={"add"}
                    handleEnter={handleConfirm}
                    klasa={"childAddFS"}
                    klasaNaslov={"naslovMal"}
                    klasaButton={"buttonConfirm"}
                    save={true}
                    buttonName={translations?.core?.confirm}
                    cancel={true}
                    cancelKlasa={"buttonCancel"}
                    cancelFunc={stopSale}
                    allowEnter={false}
                  />
                </React.Fragment>
            }
      </div>
    )
}

export default SellDevices;