import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    dialogBackground: {
      zIndex: "10",
      position: "fixed",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
    },
    dialogFront: {
      backgroundColor: "white",
      margin: "auto",
      marginTop: "35vh",
      borderRadius: "5px",
      width: "300px",
      border: "1px solid black",
      padding: "7px",
    },
}));

export const ErrorDialog = ({ handleOnClick, name, text}) => {
  const classes = useStyles();

  return (
    <div className={classes.dialogBackground} style={{backgroundColor: "rgba(242, 224, 191, 0.3)"}}
      onClick={() => {
        handleOnClick(false)
      }}>
      <div className={classes.dialogFront}>
        <h3>{name}</h3>
        <hr />
        <p>{text}</p>
      </div>
    </div>
  );
};

export const NotificationDialog = ({ handleOnClick, name, text}) => {
  const classes = useStyles();
  
  return (
    <div className={classes.dialogBackground} style={{backgroundColor: "rgba(182, 223, 240, 0.3)"}}
      onClick={() => {
        handleOnClick(false)
      }}>
      <div className={classes.dialogFront}>
        <h3>{name}</h3>
        <hr />
        <p>{text}</p>
      </div>
    </div>
  );
};