import React, {useState, useContext, createContext} from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { createStyles, makeStyles,} from '@material-ui/core';
import backgroundImage from './styles/footer30.jpg';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {DialogContext} from './../App';
import { TranslationContext } from './../App';
import { Tooltip } from '@material-ui/core';
import {MegaDiv} from './header.js'

const useStyles = makeStyles(() =>
  createStyles({
    footer : {
        position : "fixed",
        bottom : "0",
        width : "100%",
        height : "30px",
        borderTop : "1px solid black",
        textAlign : "left",
        paddingLeft : "20px",
        boxSizing : "border-box",
        // backgroundColor : "#002057",
        lineHeight: "30px",
        fontSize : "0.7em",
        color : "white",
        zIndex : "105",
    },
    version : {
      position : "fixed",
      zIndex : "105",
      right : "10px",
      bottom : "0.5px"
    },
    helpIcon : {
      position : "fixed",
      zIndex : "105",
      right : "85px",
      cursor : "pointer",
      width : "20px",
      height : "20px",
      bottom : "10px",
    },
    helpExpandedDiv : {
      display : "inline-block",
      position : "fixed",
      borderRadius : "5px",
      bottom : "30px",
      right : "10px",
      backgroundColor : "white",
      fontWeight : "bold",
      zIndex : "105"
    },
    helpExpandedUl : {
      border : "1px solid black",
      borderBottom : "none",
      borderRadius : "5px 5px 0 0",
      listStyleType: "none",
      margin : "0px",
      padding : "0px",
      "& li" : {
        padding : "5px",
        backgroundColor : "#f5f9ff",
        cursor : "pointer",
      },
      "& li:hover" : {
        backgroundColor : "#DDD",
      },
      "& li:first-child" : {
        borderRadius : "5px 5px 0 0"
      }
    },
  })
);

export const GuidesContext = createContext({});

export const Footer = () => {
    const classes = useStyles();
    const location = useLocation();
    const {apiPath} = useContext(DialogContext);
    const {translations, langCode} = useContext(TranslationContext)
    const [helpExpanded, setHelpExpanded] = useState(false)
    const [uredExpanded, setUredExpanded] = useState(false)

    const handleUredExpand = () => {
      setHelpExpanded(false)
      setUredExpanded(true)

    }

    const handleDownload = (event, typeOfGuide) => {
      setHelpExpanded(false)
      setUredExpanded(false)
      let documentName = event.target.innerHTML;
      
      async function getDocument() {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${apiPath}getDocument.php?typeOfGuide=${typeOfGuide}&lang=${langCode}`, {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${token}`, 
              Accept : "application/octet-stream",
              ContentType : "application/octet-stream",
              ContentDisposition: "attachment;",
            } 
          });

        if(response.data.size !== 0){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${documentName}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url)
        }
      }
      
      getDocument();
    }

    const renderHelpMenu = () => {
      if(helpExpanded === false && uredExpanded === false)
        return <React.Fragment></React.Fragment>
      
      if(location.pathname === "/login" || location.pathname === "/register")
        return <React.Fragment></React.Fragment>

      if(uredExpanded === true){
        return (
          <React.Fragment>
              <div className={classes.helpExpandedDiv}>
                <ul className={classes.helpExpandedUl}>
                    <li onClick={(event) => handleDownload(event, "deviceGuide-v1")}>{translations?.core?.deviceGuidev1}</li>
                    <li onClick={(event) => handleDownload(event, "deviceGuide-v1.1")}>{translations?.core["deviceGuidev1.1"]}</li>
                </ul>  
              </div>  
          </React.Fragment>
        )
      }

      // User
      if(localStorage.getItem("userType") !== null){
        let userType = Number(localStorage.getItem("userType"))
        if(userType === 0){        
          return (
            <React.Fragment>
              <div className={classes.helpExpandedDiv}>
                <ul className={classes.helpExpandedUl}>
                    <li onClick={(event) => handleDownload(event, "userGuide")}>{translations?.core?.userGuide}</li>
                </ul>  
              </div>  
            </React.Fragment>
          )
        }
        else if (userType === 1){
          return (
            <React.Fragment>
              <div className={classes.helpExpandedDiv}>
                <ul className={classes.helpExpandedUl}>
                    <li onClick={(event) => handleUredExpand()}>{translations?.core?.deviceGuide}</li>
                    <li onClick={(event) => handleDownload(event, "adminGuide")}>{translations?.core?.adminGuide}</li>
                    <li onClick={(event) => handleDownload(event, "userGuide")}>{translations?.core?.userGuide}</li>
                </ul>  
              </div>  
            </React.Fragment>
          )
        }
        else if (userType === 2){
          return (
            <React.Fragment>
              <div className={classes.helpExpandedDiv}>
                <ul className={classes.helpExpandedUl}>
                    <li onClick={(event) => handleUredExpand()}>{translations?.core?.deviceGuide}</li>
                    <li onClick={(event) => handleDownload(event, "adminGuide")}>{translations?.core?.adminGuide}</li>
                    <li onClick={(event) => handleDownload(event, "userGuide")}>{translations?.core?.userGuide}</li>
                    <li onClick={(event) => handleDownload(event, "DMadminGuide")}>{translations?.core?.DMadminGuide}</li>
                </ul>  
              </div>  
            </React.Fragment>
          )
        }
      }
    }
    
    if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
      return <React.Fragment></React.Fragment>

    return (
      // <footer className={classes.footer}>
      <React.Fragment>
        <GuidesContext.Provider value={{ handleDownload, handleUredExpand }}>
          {renderHelpMenu()}
          <footer className={classes.footer} style={{ backgroundImage: `url(${backgroundImage})` }}>
            &copy; DATAMAJORIS &nbsp;{new Date().getFullYear()}
            {location.pathname !== "/login" &&
              location.pathname !== "/register" &&
              (helpExpanded ? (
                <div
                  className={classes.helpIcon}
                  onClick={() => {
                    setHelpExpanded(!helpExpanded);
                    if(uredExpanded)
                      setUredExpanded(false)
                  }}
                >
                  {translations?.core?.guides || "Guides"}
                </div>
              ) : (
                // <HelpOutlineIcon className={classes.helpIcon} onClick={() => setHelpExpanded(!helpExpanded)}/>
                <Tooltip title={translations?.core?.helpIconTooltip || "Превземи упатства"} aria-label="help">
                  {/* <HelpOutlineIcon className={classes.helpIcon} onClick={() => setHelpExpanded(!helpExpanded)}/> */}
                  <div
                    className={classes.helpIcon}
                    onClick={() => {
                      setHelpExpanded(!helpExpanded);
                      if(uredExpanded)
                        setUredExpanded(false)
                    }}
                  >
                    {translations?.core?.guides || "Guides"}
                  </div>
                </Tooltip>
              ))}
            <span className={classes.version}>{translations?.core?.version}</span>
          </footer>
        </GuidesContext.Provider>
        {(helpExpanded || uredExpanded) && <MegaDiv action={setHelpExpanded} action2={setUredExpanded} />}
      </React.Fragment>
    );
}
