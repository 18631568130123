import {useContext} from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./styles/globalClasses.css";
import {TranslationContext} from './../App';

const useStyles = makeStyles((theme) => ({
    criticalActionBack: {
      zIndex: "10",
      backgroundColor: "rgba(187, 196, 237, 0.3)",
      position: "fixed",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
    },
    criticalActionDialog: {
      backgroundColor: "white",
      margin: "auto",
      marginTop: "35vh",
      borderRadius: "5px",
      width: "400px",
      border: "1px solid black",
    },
}));

const CriticalAction = ({name, handleYes, handleNo, text}) => {
    const classes = useStyles();
    const {translations} = useContext(TranslationContext)
    
    return (
      <div className={classes.criticalActionBack}>
        <div className={classes.criticalActionDialog} style={{textAlign : "center"}}>
          <h3 >{name}</h3>
          <hr />
          <pre>{text}</pre>
          {/* style={{fontFamily : "inherit"}} */}
          <button className={"buttonConfirm"} onClick={() => {handleYes()}}>{translations?.core?.confirm}</button>
          <button className={"buttonCancel"} onClick={() => {handleNo()}}>{translations?.core?.cancel}</button>
        </div>
      </div>
    );
  };

  export default CriticalAction;
