import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Box, FormControl, InputLabel, makeStyles, createStyles, MenuItem, Select } from '@material-ui/core';
import ErrorPrivilegesPath from "../auth/error-privileges";
import {DialogContext, TranslationContext} from './../../App'
import {CircleIndicator} from  './../C-reports/card';
import {Graph} from './../C-reports/graph';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Tooltip } from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {ToggleSwitch} from "./../C-reports/toggle-switch";

const SPLIT_CARD_GRAPH = 900
const SPLIT_DROPDOWN = 700;
const DEFAULT_ZOLT = 800;
const DEFAULT_CRVEN = 1200;

const setScatterData = (sensorData) => {
    return sensorData
      ?.reverse()
      .map((sensor, index) => ({
        valueY: `${Number(sensor.CO2)}`,
        valueY2: `${Number(sensor.Tem)}`,
        Time: new Date(sensor.t.replace(/-/g, "/")).getTime(),
    }));
}

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    circleContainer : {
      float : "left",
      display : "inline-block",
      [breakpoints.down(SPLIT_CARD_GRAPH)]: {
        float : "none",
        width : "320px",
        display: "block",
        margin : "auto",
        marginTop : "30px",
        marginBottom : "30px",
        transform : "scale(0.9)"
      },
      [breakpoints.down(SPLIT_DROPDOWN)] : {
        display : "block",
        margin : "auto",
        transform : "scale(0.7)",
        marginTop : "-10px",
        marginBottom : "0px"
      },
    },
    graphContainer: {
      float : "left", 
      marginTop : "50px",
      width : "calc(100% - 350px)",
      [breakpoints.down(SPLIT_CARD_GRAPH)]: {
        width : "90%",
        margin : "0 10px",
        float : "none",
        boxSizing : "border-box",
        marginBottom : "80px"
      },
    },
    formControl: {
      margin: '0.5em'
    },
    formSelectBuilding: {
      minWidth: '80px'
    },
    formSelectPlace: {
      minWidth: '70px'
    },
    formSelectDevice: {
      minWidth: '70px'
    },
    refreshIcon : {
      float : "right", 
      display : "inline-block",
      width : "30px",
      height : "30px",
      position : "relative",
      top : "30px",
      right : "10px",
      cursor : "pointer",
      userSelect : "none",
      [breakpoints.down(SPLIT_DROPDOWN)]: {
        top : "80px"
      },
    }
  })
);

export const Reports = () => {
    const {setSuccessZero, apiPath, selectedModule, setSelectedModule, refreshState } = useContext(DialogContext)
    const { translations } = useContext(TranslationContext);
    const classes = useStyles();
    const history = useHistory();
  
    const [ pragCrven, setPragCrven ] = useState(DEFAULT_CRVEN);
    const [ pragZolt, setPragZolt ] = useState(DEFAULT_ZOLT);
    
    const [ buildings, setBuildings ] = useState([]);
    const [ places, setPlaces ] = useState([]);
    const [ devices, setDevices ] = useState([]);

    const [ building, setBuilding ] = useState({ zgradaID: 0, zgradaName: '', dataZ: []});
    const [ place, setPlace ] = useState({});
    const [ device, setDevice ] = useState({});

    const [ sensorData, setSensorData ] = useState([]);
    const [ inputInterval, setInputInterval ] = useState(1);
    const [allowRefetch, setAllowRefetch] = useState(true)

    const [locData, setLocData] = useState([])
    
    const [firstRender, setFirstRender] = useState(false)
    const [redirect, setRedirect] = useState({state : false, status : 401})
    const [width, setWidth]   = useState(window.innerWidth);   
    const [currentGraph, setCurrentGraph] = useState("CO2")
  
    const updateDimensions = () => {setWidth(window.innerWidth)};
    const updateOrientation = () => {
        setTimeout(() => {setWidth(window.innerWidth)}, 10) 
        setTimeout(() => {setWidth(window.innerWidth)}, 50) 
        setTimeout(() => {setWidth(window.innerWidth)}, 100) 
    }
    
    useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      window.addEventListener("orientationchange", updateOrientation)
      return () => {
          window.removeEventListener("resize", updateDimensions)
          window.removeEventListener("orientationchange", updateOrientation)
      };
    }, []);
    
    const setLastSeenDeviceToStorage = () => {
      if(!firstRender) return;
      // zacuvaj relevantni parametri pri baranje na grafik
      let defaultsR = JSON.parse(localStorage.getItem("defaultsR")) || {}; 
      defaultsR["building"] = building.zgradaName;
      defaultsR["place"] = place.mestoName;
      defaultsR["device"] = device.deviceName;
      defaultsR["deviceID"] = device.deviceID;
      defaultsR["interval"] = inputInterval;
      
      localStorage.setItem("defaultsR", JSON.stringify(defaultsR));
    }
    const refetch = useQuery('sensor-data', async () => {
        try {
            let deviceID;
            let interval__ = inputInterval;
            const defaultsR = JSON.parse(localStorage.getItem("defaultsR")) || {};

            if (device.deviceID === undefined || device === null) {
              if(defaultsR.deviceID !== undefined && !isNaN(defaultsR.deviceID)) // ima zacuvan ured
                deviceID = defaultsR.deviceID
              if(defaultsR.interval !== undefined && !isNaN(defaultsR.interval)) // ima zacuvan ured
                interval__ = defaultsR.interval 
            }
            else
              deviceID = device.deviceID;

            const token = localStorage.getItem("token");
            const response = await axios.get(`${apiPath}getDeviceData.php?serialID=` + deviceID + '&interval=' + interval__, { headers: { Authorization: `Bearer ${token}` } });

            if(response.data.success){
              setFirstRender(true);
              setSensorData(setScatterData(response.data.records));
            }
            else{
              // TO DO
              if(response.data.status === 401 || response.data.status === 403) // unauthorized
                setRedirect({state : true, status : response.data.status})
              setSensorData(setScatterData([]));
            }

            return response.data.record;
        } catch (err) {
        }
    }, {
      refetchOnWindowFocus: false,
      enabled: false
    });
    
    const { data: deviceState, refetch: refetchDeviceState } = useQuery('device-state', async () => {
      try {
        let deviceID;

        if (device.deviceID === undefined || device === null) {
          const defaultsR = JSON.parse(localStorage.getItem("defaultsR")) || {};
          
          if(defaultsR.deviceID !== undefined && !isNaN(defaultsR.deviceID)) // ima zacuvan ured
            deviceID = defaultsR.deviceID
        }
        else
          deviceID = device.deviceID;

        const token = localStorage.getItem("token");
        const response = await axios.get(`${apiPath}deviceState.php?serialID=` + deviceID, { headers: { Authorization: `Bearer ${token}` } });
        
        if(response.data.success){
          setFirstRender(true);
          setPragCrven(response?.data?.data?.pragCrven);
          setPragZolt(response?.data?.data?.pragZolt);
        }
        else if (response.data.success === 0) {
          // TO DO
          if(response.data.status === 401 || response.data.status === 403) // unauthorized
            setRedirect({state : true, status : response.data.status})
        }

        return response.data.data;
      } catch (err) {
      }
    });

    useQuery('user-access', async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${apiPath}fetchUserAccess.php`, { headers: { Authorization: `Bearer ${token}` } });

        if(response.data.success){
          setFirstRender(true);
          response.data?.allowed.forEach((singleProduct) => {
            if(singleProduct.productGenericName === "DamaLuft"){
              setLocData(singleProduct);
              const defaultsR = JSON.parse(localStorage.getItem("defaultsR")) || {};
              
              if(defaultsR.interval !== undefined && [1, 3, 7].indexOf(defaultsR.interval) !== -1)
                  setInputInterval(defaultsR.interval)
  
              let newZgradi = [];
              let newMesta = [];
              let newUredi = [];
  
              let newZgrada = {};
              let newMesto = {};
              let newUred = {};
  
              // Resava problemi ako nekoj cepka vo localStorage i izbrisit primer samo "mesto" od objektot defaultsR
              let celosnoZacuvan = false
  
              singleProduct.dataP.forEach((singleZgrada, indexZgrada) => {
                newZgradi.push({zgradaID : singleZgrada.zgradaID, zgradaName : singleZgrada.zgradaName})
                if(typeof(defaultsR) !== "undefined"){
                  if(defaultsR.building !== undefined){ // ima zacuvana zgrada
                    if(singleZgrada.zgradaName === defaultsR.building){
                        newZgrada = newZgradi[newZgradi.length - 1]
                        singleZgrada.dataZ.forEach((singleMesto) => {
                          newMesta.push({mestoID : singleMesto.mestoID, mestoName : singleMesto.mestoName})
                          if(defaultsR.place !== undefined){ // ima zacuvano mesto
                            if(singleMesto.mestoName === defaultsR.place){
                              newMesto = newMesta[newMesta.length - 1]
                              singleMesto.dataM.forEach((singleUred) => {
                                  newUredi.push({deviceID : singleUred.deviceID, deviceName : singleUred.deviceName})
                                  if(defaultsR.device !== undefined){  // ima zacuvan ured
                                    if(singleUred.deviceName === defaultsR.device){
                                      newUred = newUredi[newUredi.length - 1]
                                      celosnoZacuvan = true
                                    }
                                  }
                                })
                              }
                          }
                        })
                      }
                  }
                }
              })

              setBuildings(newZgradi);
              setPlaces(newMesta);
              setDevices(newUredi);
              
              // ako imat zacuvan ured
              if(celosnoZacuvan){
                setDevice(newUred)
                setPlace(newMesto)
                setBuilding(newZgrada)
              }
              // ako nemat zacuvan ured
              else{
                setDevice({deviceID : "", deviceName : ""})
                setPlace({mestoName : "", mestoID : ""})
                setBuilding({zgradaName : "", zgradaID : ""})
              }

            }
          })
          // za kompanii bez svoi uredi
          if(response.data.allowed.length === 0){
            setDevice({deviceID : "", deviceName : ""})
            setPlace({mestoName : "", mestoID : ""})
            setBuilding({zgradaName : "", zgradaID : ""})
          }
        }
        else if (response.data.success === 0) {
          // TO DO
          if(response.data.status === 401 || response.data.status === 403) // unauthorized
            setRedirect({state : true, status : response.data.status})
          else
            setSuccessZero({state : true, name : translations?.locations?.errorFetchingAccess, text : translations?.backend[response.data.message]});
        }

        return response.data.allowed;
      } catch(err) {
      }
    });

    const handleBuldingSelect = (e) => {
      let newPlaces = []
      
      locData.dataP.forEach((singleZgrada) => {
          if(singleZgrada.zgradaID === e.target.value){
              setBuilding({zgradaID : singleZgrada.zgradaID, zgradaName : singleZgrada.zgradaName})
              singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                  newPlaces.push({mestoID : singleMesto.mestoID, mestoName : singleMesto.mestoName})
              })
          }
      })

      setPlaces(newPlaces);
      setDevice({deviceID : "", deviceName : ""})
      setPlace({mestoName : "", mestoID : ""})
    }

    const handlePlaceSelect = (e) => {
      let newUredi = [];
      
      locData.dataP.forEach((singleZgrada) => {
          if(singleZgrada.zgradaID === building.zgradaID){
              singleZgrada.dataZ.forEach((singleMesto) => {
                  if(singleMesto.mestoID === e.target.value){
                      setPlace({mestoID : singleMesto.mestoID, mestoName : singleMesto.mestoName})
                      singleMesto.dataM.forEach((singleUred, indexUred) => {
                          newUredi.push({deviceID : singleUred.deviceID, deviceName : singleUred.deviceName})
                      })
                  }
              })
          }
      })

      setDevices(newUredi)
      setDevice({deviceID : "", deviceName : ""})
    }

    useEffect(() => {
      const intervalId = setInterval(() => {
        console.log("refetching")
        refetch.refetch()
        refetchDeviceState()
        
      }, 300000);// 5 minutes
    
      return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refetchDevice = (refresh=false) => {
      if(allowRefetch || refresh){
        setLastSeenDeviceToStorage()
        refetch.refetch()
        refetchDeviceState()
      }
      setAllowRefetch(false)
    }    

    useEffect(() => {
      refetchDevice();
    }, [inputInterval, device])

    useEffect(() => {
      if(selectedModule !== "/reports")
        setSelectedModule("/reports")
    }, [])
    
    if(!firstRender && redirect.state === false)
      return <React.Fragment></React.Fragment>

    if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
      return <React.Fragment></React.Fragment>

    if(redirect.state){
      // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
      if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
        history.go(0);
      else
        return <ErrorPrivilegesPath  status={redirect.status} />
    }

    // da ne menuvame controlled - uncontrolled input dodeka building, place i device se undefined
    if (building?.zgradaID === undefined || place?.mestoID === undefined || device?.deviceID === undefined)
      return <React.Fragment></React.Fragment>

    return (
      <>
        <div className={classes.refreshIcon}>
          <Tooltip title={translations?.core?.refresh || "Освежи податоци"} aria-label="refresh">
            <RefreshIcon onClick={() => {refetchDevice(true)}} />
          </Tooltip>
        </div>
        <div>
          <Box display={'flex'} justifyContent={'center'} >
            <FormControl className={classes.formControl}>
              <InputLabel id="zgrada">{translations?.locations?.building}</InputLabel>
              <Select
                labelId="zgrada"
                id="zgrada"
                value={building?.zgradaID}
                onChange={handleBuldingSelect}
                autoWidth={true}
                className={classes.formSelectBuilding}
              >
                {buildings.map((building) => (
                  <MenuItem key={building.zgradaID} value={building.zgradaID}>
                    {building.zgradaName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="mesto">{translations?.locations?.place}</InputLabel>
              <Select
                labelId="mesto"
                id="mesto"
                value={place?.mestoID}
                autoWidth={true}
                className={classes.formSelectPlace}
                onChange={handlePlaceSelect}
              >
                {places.map((place) => (
                  <MenuItem key={place.mestoID} value={place.mestoID}>
                    {place.mestoName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="mesto">{translations?.devices?.device}</InputLabel>
              <Select
                labelId="product"
                id="product"
                value={device?.deviceID}
                autoWidth={true}
                className={classes.formSelectDevice}
                onChange={(e) => {
                  setAllowRefetch(true)
                  setDevice(devices.find(d => d.deviceID === e.target.value))
                }}
              >
                {devices.map((device_) => (
                  <MenuItem key={device_.deviceID} value={device_.deviceID}>
                    {device_.deviceName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {width >= SPLIT_DROPDOWN && (
              <>
                <FormControl className={classes.formControl} style={{marginLeft : "50px"}}>
                  <InputLabel id="mesto">{translations?.data?.interval}</InputLabel>
                  <Select
                    autoWidth={true}
                    className={classes.formSelectPlace}
                    value={inputInterval}
                    onChange={(event) => {
                      setAllowRefetch(true)
                      setInputInterval(Number(event.target.value))
                    }}
                  >
                    {[1, 3, 7].map((singleInterval) => (
                      // FIX: key may not be unique
                      <MenuItem key={singleInterval} value={singleInterval}>
                        {singleInterval !== 1 && singleInterval} {singleInterval === 1 ? translations?.data?.day : translations?.data?.days}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ToggleSwitch setCurrentGraph={setCurrentGraph} currentGraph={currentGraph}/>
              </>
            )}
          </Box>
          
          {width < SPLIT_DROPDOWN && (
              <Box display={'flex'} justifyContent={'center'} >
                <FormControl className={classes.formControl} >
                  <InputLabel id="mesto">{translations?.data?.interval}</InputLabel>
                  <Select
                    autoWidth={true}
                    className={classes.formSelectPlace}
                    value={inputInterval}
                    onChange={(event) => {
                      setAllowRefetch(true)
                      setInputInterval(Number(event.target.value))
                    }}
                  >
                    {[1, 3, 7].map((singleInterval) => (
                      // FIX: key may not be unique
                      <MenuItem key={singleInterval} value={singleInterval}>
                        {singleInterval !== 1 && singleInterval} {singleInterval === 1 ? translations?.data?.day : translations?.data?.days}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ToggleSwitch setCurrentGraph={setCurrentGraph} currentGraph={currentGraph}/>
              </Box>
            )}

          <div className={classes.circleContainer}>
            <CircleIndicator deviceState={deviceState} currentGraph={currentGraph} />
          </div>

          <div className={classes.graphContainer} >
              <Graph 
                currentGraph={currentGraph}
                sensorData={sensorData}
                interval={inputInterval}
                pragCrven={pragCrven}
                pragZolt={pragZolt}
              />
          </div>
        </div>
      </>
    )
}
