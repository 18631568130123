import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import MaterialTable from "material-table";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PublicOffOutlinedIcon from '@mui/icons-material/PublicOffOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import RefreshIcon from '@material-ui/icons/Refresh';
import ErrorPrivilegesPath from "../auth/error-privileges";
import 'date-fns';
import SellDevices from './../C-inventar/sell-devices'
import EditSection from './../C-inventar/inventory-edit-section'
import CriticalAction from "./../critical-action"
import {DialogContext, TranslationContext} from './../../App';
import {GraphDM} from './../C-inventar/graphDM.js'
import './../styles/globalClasses.css';
import {useHistory} from 'react-router-dom';
import { formatDatum } from "../formatDates";

const Inventar = () => {
  const [redirect, setRedirect] = useState({state : false, status : 401, tryAgain : false})
  const {translations, langCode, setLangCode} = useContext(TranslationContext)
  const history = useHistory();
  const {setSuccessZero, apiPath, selectedModule, setSelectedModule, refreshState } = useContext(DialogContext)
  const [currentDevice, setCurrentDevice] = useState({}); // Datamajoris e pred-selektirana kompanija
  const [inventory, setInventory] = useState([]);
  const [criticalAction, setCriticalAction] = useState({state : false, name : "", text : ""})
  const [criticalValue, setCriticalValue] = useState({});
  const [notShowGraph, setNotShowGraph] = useState(true);
  const [firstRender, setFirstRender] = useState(false)
  
  const response = useQuery("inventory", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiPath}fetchInventory.php`, { headers: { Authorization: `Bearer ${token}` } });

      if(response.data.success){
        setFirstRender(true);
        response.data.total === "0" ? setInventory([]) : setInventory(response.data.devices);
        if(typeof(currentDevice.serialID) === "undefined")
          setCurrentDevice(response.data.devices[0])
        else{
          response.data.devices.forEach((device)=>{
              if(device.serialID === currentDevice.serialID )
              setCurrentDevice(device)
          })
        }
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingDevices, text : translations?.backend[response.data.message]});
      }

      return response.data;
    } catch (err) {
    }
  });

  const mutation = useMutation((deviceData) => {
    const token = localStorage.getItem("token");

    switch(deviceData.tip){
      case "visibility":
        return axios.put(`${apiPath}updateDeviceVisibility.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "public":
        return axios.put(`${apiPath}updateDevicePublic.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "name":
        return axios.put(`${apiPath}updateDeviceName.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "comment":
        return axios.put(`${apiPath}updateDeviceComment.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "location":
        return axios.put(`${apiPath}updateDeviceLocation.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "endDate":
        return axios.put(`${apiPath}updateDeviceEndDate.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "settings":
        return axios.put(`${apiPath}updateDeviceSettings.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "sell":
        return axios.post(`${apiPath}sellDevices.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });  
      default: 
        break;
    }
  });

  const mutationTranslations = useMutation(() => {
    return axios.get(`${apiPath}updateTranslations.php?key=theKeyIs1`);
  });

  const updateTranslations = () => {
    mutationTranslations.mutate({},
      {
        onError: (error) => {
        },
        onSuccess: (dataReturned) => {
          window.alert("Translations successfully updated. Refresh the page to take effect.")
        },
      }
    );
  };

  const updateDevice = (deviceData) => {
    mutation.mutate(
      { ...deviceData },
      {
        onError: (error) => {
        },
        onSuccess: (dataReturned) => {
          if(dataReturned.data.success)
            response.refetch();
          else{
            switch(deviceData.tip){
              case "sell":
                  setSuccessZero({state : true, name : translations?.devices?.errroSellingDevices, 
                      text : translations?.backend[dataReturned.data.message[0]] + dataReturned.data.variables + translations?.backend[dataReturned.data.message[1]]});
                  break;
              case "visibility":
                  setSuccessZero({state : true, name : translations?.devices?.errorVisibility, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "public":
                  setSuccessZero({state : true, name : translations?.devices?.errorPublic, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "name":
                  setSuccessZero({state : true, name : translations?.devices?.errorName, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "comment":
                  setSuccessZero({state : true, name : translations?.devices?.errorComment, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "location":
                setSuccessZero({state : true, name : translations?.devices?.errorLocation, text : translations?.backend[dataReturned.data.message]});
                break;
              case "endDate":
                setSuccessZero({state : true, name : translations?.devices?.errorEndDate, text : translations?.backend[dataReturned.data.message]});
                break;
              case "settings":
                setSuccessZero({state : true, name : translations?.devices?.errorSettings, text : translations?.backend[dataReturned.data.message]});
                break;
              default: 
                break;
            }
          }
        },
      }
    );
  };

  const handleCritical = () => {
    updateDevice({...criticalValue});
    setCriticalValue({})
    setCriticalAction({state : false, name : "", text : ""})
  } 

  useEffect(() => {
    if(selectedModule !== "/inventar")
      setSelectedModule("/inventar")
  }, [])

  if(!firstRender && redirect.state === false)
    return <React.Fragment></React.Fragment>

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>
  
  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <React.Fragment>
      <div className={"tableFS"}>
        <MaterialTable
          title={translations?.devices?.tableInventory}
          options={{
            padding: "dense",
            headerStyle : {padding : "3px"},
            actionsCellStyle : {paddingLeft : "8px"},
          }}
          localization={{
            header: { actions: translations?.core?.actions },
            pagination : {
              firstTooltip : translations?.core?.firstPage,
              lastTooltip : translations?.core?.lastPage,
              nextTooltip : translations?.core?.nextPage,
              previousTooltip : translations?.core?.previousPage,
              labelRowsSelect: translations?.core?.rows,
              labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
            },
            body : {
              emptyDataSourceMessage : translations?.core?.emptyTable
            },
            toolbar: {
              searchPlaceholder: translations?.core?.search,
              searchTooltip : translations?.core?.search
            },
          }}
          style={{fontSize : "0.85em"}}
          columns={[
            { title: translations?.companies?.company, field: "companyName", 
                  cellStyle : {padding : "0px 5px", paddingLeft : "45px"}, headerStyle : {paddingLeft : "45px"}},
            { title: translations?.companies?.companyID, field: "companyID" , hidden : true},
            { title: translations?.products?.productType, field: "productName"},
            { title: "pID", field: "pID", hidden : true},
            { title: "Visible", field: "isvisible", hidden : true},
            { title: translations?.devices?.serial, field: "serialNumber"},
            { title: translations?.locations?.building, field: "zgradaName"},
            { title: translations?.locations?.place, field: "mestoName"},
            { title: translations?.devices?.device, field: "deviceName"},
            { title: translations?.companies?.contractEnding, field: "contractEndDate"},
            { title: translations?.products?.firmwareTitle, field: "fVersion"},
            { title: translations?.devices?.comment, field: "comment"},
            { title: "id", field: "serialID", hidden : true},
          ]}
          data={inventory.map((dat) => {
            let com;
            dat.comment.length > 40 ? com = dat.comment.slice(0, 37) + "..." : com = dat.comment; 
            let isvis = parseInt(dat.isvisible)
            let isPub = parseInt(dat.ispublic)
            return {...dat, comment : com, isvisible : isvis, ispublic : isPub, contractEndDate : formatDatum(dat.contractEndDate)}
          })}
          actions={[
            (rowData) => ({
              icon:
                currentDevice.serialID === rowData.serialID
                  ? CheckBoxIcon
                  : CheckBoxOutlineBlankIcon,
              tooltip: translations?.devices?.selectDevice,
              onClick: (event, rowData) => {
                inventory.forEach((device) => {
                  if(device.serialID === rowData.serialID)
                    setCurrentDevice((device));
                })
              },
            }),
            (rowData) => ({
              icon: rowData.isvisible ? VisibilityIcon : VisibilityOffOutlinedIcon,
              tooltip: translations?.devices?.toggleVisibility,
              onClick: (event, rowData) => {
                rowData.isvisible = !rowData.isvisible;
                updateDevice({tip : "visibility", isvisible : true, serialID : rowData.serialID})
              },
            }),
            (rowData) => ({
              icon: rowData.ispublic ? PublicOutlinedIcon : PublicOffOutlinedIcon,
              tooltip: rowData.ispublic ? translations?.devices?.toggleToPrivate : translations?.devices?.toggleТоPublic,
              onClick: (event, rowData) => {
                rowData.ispublic = !rowData.ispublic;
                updateDevice({tip : "public", ispublic : true, serialID : rowData.serialID})
              },
            }),
            (rowData) => ({
              icon: ShowChartIcon,
              tooltip: translations?.data?.showChart,
              onClick: (event, rowData) => {
                if(rowData.serialID === currentDevice.serialID)
                  setNotShowGraph(!notShowGraph)
                else
                  setNotShowGraph(false)

                inventory.forEach((device) => {
                  if(device.serialID === rowData.serialID)
                    setCurrentDevice((device));
                })
              },
            }),
            {
              icon: RefreshIcon,
              tooltip: translations?.core?.refresh,
              isFreeAction: true,
              onClick: (event) => response.refetch()
            }
          ]}  
          onRowClick={(event, rowData) => {
            inventory.forEach((device) => {
              if(device.serialID === rowData.serialID)
                setCurrentDevice((device));
            })
          }}
        />
        {!notShowGraph && (
          <GraphDM  serialID={currentDevice.serialID} setNotShowGraph={setNotShowGraph} />
        )}
        <SellDevices setCriticalValue={setCriticalValue} setCriticalAction={setCriticalAction}></SellDevices>
      </div>
      {/* <div className={"editSection"} > */}
        <EditSection current={currentDevice} updateDevice={updateDevice}></EditSection>
      {/* </div> */}
      {criticalAction.state && 
        <CriticalAction 
            name={criticalAction.name}
            text={criticalAction.text}
            handleYes={handleCritical} 
            handleNo={() => {setCriticalAction({state : false, name : "", text : ""})}} 
        />
      }
    </React.Fragment>
  );
};

export default Inventar;
