import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import ErrorPrivilegesPath from "../auth/error-privileges";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { NTextField, SaveButton } from "./../edit-elements";
import CriticalAction from "../critical-action";
import './../styles/globalClasses.css';
import { DialogContext, TranslationContext} from './../../App';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  icon: {
    margin: "4px",
  },
  selectDiv: {
    display: "inline-block",
    width: "250px",
    [theme.breakpoints.down('601')]: {
      width: "200px"
    },
  },
  iconsDiv: {
    margin: "5px",
    display: "inline-block",
    verticalAlign: "middle",
    boxSizing: "border-box",
    width: "70px",
    [theme.breakpoints.down('601')]: {
      marginLeft: "0"
    },
  },
  naslov: {
    width: "140px",
    margin: "auto",
    marginBottom: "30px",
    borderBottom: "2px solid #213a80",
    textAlign: "center",
    paddingBottom: "3px",
    marginLeft: "130px",
    [theme.breakpoints.down('601')]: {
      marginLeft: "110px"
    },
  },
  dropDownSegment : {
    width: "350px",
    height: "110px",
    marginLeft: "15px",
    boxSizing: "border-box",
    marginBottom: "20px",
    [theme.breakpoints.down('601')]: {
      width: "300px",
    },
  },
  locationsDiv : {
    width : "350px",
    boxSizing : "border-box",
    margin : "auto",
    marginTop : "20px",
    [theme.breakpoints.down('601')]: {
      width: "300px"
    },
  }
}));

const Locations = (props) => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const history = useHistory();
  const {translations} = useContext(TranslationContext)
  const {setSuccessZero, apiPath, refreshState} = useContext(DialogContext)
  const classes = useStyles();
  const [locData, setLocData] = useState([]);
  const [zgradi, setZgradi] = useState({});
  const [mesta, setMesta] = useState({});
  const [uredi, setUredi] = useState({});
  const [currentZgrada, setCurrentZgrada] = useState({});
  const [currentMesto, setCurrentMesto] = useState({});
  const [currentUred, setCurrentUred] = useState({});
  const [editZgrada, setEditZgrada] = useState(false);
  const [editMesto, setEditMesto] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const [companyID, setCompanyID] = useState(props.companyID);
  const [lastLocation, setLastLocation] = useState({
        zgradaID: 1, mestoID: 1, add: false, delete: false, edit: false,});
  const [criticalAction, setCriticalAction] = useState({state : false, name : "", text : ""})
  const [criticalValue, setCriticalValue] = useState({});

  useEffect(() => {
    let timer1 = setTimeout(() => {
      response.refetch();
    }, 1);

    setCompanyID(props.companyID);
    return () => {
      clearTimeout(timer1);
    };
  }, [props.companyID, companyID]);

  const response = useQuery("company-locations", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiPath}fetchLocations.php?companyID=${companyID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        let newZgradi = [];
        let newMesta = [];
        let newUredi = [];
        let zID = 1;
        let mID = 1;

        response.data.locations.forEach((singleZgrada, indexZgrada) => {
          newZgradi.push({
            label: singleZgrada.zgradaName,
            value: singleZgrada.zgradaName,
          });

          // na prv render (base case) ili na brisenje na zgrada
          if (locData.length === 0 || (lastLocation.delete && locData.length > response.data.locations.length)) {
            if (indexZgrada === 0) {
              zID = singleZgrada.zgradaID;
              setCurrentZgrada(newZgradi[0]);
              singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                newMesta.push({
                  label: singleMesto.mestoName,
                  value: singleMesto.mestoName,
                });
                if (indexMesto === 0) {
                  mID = singleMesto.mestoID;
                  setCurrentMesto(newMesta[0]);
                  singleMesto.dataM.forEach((singleUred, indexUred) => {
                    if (singleUred.deviceName !== null) {
                      newUredi.push({
                        value: singleUred.deviceName,
                        label: singleUred.deviceName,
                      });
                      if (indexUred === 0) setCurrentUred(newUredi[0]);
                    } else {
                      setCurrentUred({});
                    }
                  });
                }
              });
            }
          }
          // ako se rabotit za delete na mesto ili za edit (prviot del za zgradata se sveduva na istoto zatoa)
          else if (lastLocation.edit || (lastLocation.delete && locData.length === response.data.locations.length)) {
            if (singleZgrada.zgradaID === lastLocation.zgradaID) {
              zID = singleZgrada.zgradaID;
              setCurrentZgrada(newZgradi[newZgradi.length - 1]);
              singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                newMesta.push({
                  label: singleMesto.mestoName,
                  value: singleMesto.mestoName,
                });
                // tuka se razdeluvaat edit i delete. Edit treba da ostane isto, a delete treba da dobie prvo mesto
                if (lastLocation.edit) {
                  if (singleMesto.mestoID === lastLocation.mestoID) {
                    mID = singleMesto.mestoID;
                    setCurrentMesto(newMesta[newMesta.length - 1]);
                    singleMesto.dataM.forEach((singleUred, indexUred) => {
                      if (singleUred.deviceName !== null) {
                        newUredi.push({
                          value: singleUred.deviceName,
                          label: singleUred.deviceName,
                        });
                        if (indexUred === 0) setCurrentUred(newUredi[0]);
                      } else {
                        setCurrentUred({});
                      }
                    });
                  }
                }
                if (lastLocation.delete) {
                  if (indexMesto === 0) {
                    mID = singleMesto.mestoID;
                    setCurrentMesto(newMesta[0]);
                    singleMesto.dataM.forEach((singleUred, indexUred) => {
                      if (singleUred.deviceName !== null) {
                        newUredi.push({
                          value: singleUred.deviceName,
                          label: singleUred.deviceName,
                        });
                        if (indexUred === 0) setCurrentUred(newUredi[0]);
                      } else {
                        setCurrentUred({});
                      }
                    });
                  }
                }
              });
            }
          }
          // posledniot slucaj e koga se dodava zgrada ili mesto
          else if (lastLocation.add) {
            // se dodala zgrada
            if (locData.length !== response.data.locations.length) {
              // se targetira poslednata zgrada
              if (indexZgrada === response.data.locations.length - 1) {
                zID = singleZgrada.zgradaID;
                setCurrentZgrada(newZgradi[newZgradi.length - 1]);
                singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                  mID = singleMesto.mestoID;
                  newMesta.push({ label: singleMesto.mestoName, value: singleMesto.mestoName });
                  setCurrentMesto(newMesta[0]);
                  setCurrentUred({});
                });
              }
            }
            // se dodalo mesto (ednakov broj na zgradi)
            else {
              // ako ima razlicen broj na mesta togas dodadenoto mesto e vo ovaa zgrada
              if (singleZgrada.dataZ.length !== locData[indexZgrada].dataZ.length) {
                zID = singleZgrada.zgradaID;
                setCurrentZgrada(newZgradi[newZgradi.length - 1]);
                singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                  newMesta.push({ label: singleMesto.mestoName, value: singleMesto.mestoName });
                  // ako se raboti za poslednoto mesto
                  if (indexMesto === singleZgrada.dataZ.length - 1) {
                    mID = singleMesto.mestoID;
                    setCurrentMesto(newMesta[newMesta.length - 1]);
                    setCurrentUred({});
                  }
                });
              }
            }
          }
          // ova e slucajot kajsto se menvit kompanija (so klik na checkbox poleto)
          else{
            if (indexZgrada === 0) {
              zID = singleZgrada.zgradaID;
              setCurrentZgrada(newZgradi[0]);
              singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                newMesta.push({
                  label: singleMesto.mestoName,
                  value: singleMesto.mestoName,
                });
                if (indexMesto === 0) {
                  mID = singleMesto.mestoID;
                  setCurrentMesto(newMesta[0]);
                  singleMesto.dataM.forEach((singleUred, indexUred) => {
                    if (singleUred.deviceName !== null) {
                      newUredi.push({
                        value: singleUred.deviceName,
                        label: singleUred.deviceName,
                      });
                      if (indexUred === 0) setCurrentUred(newUredi[0]);
                    } else {
                      setCurrentUred({});
                    }
                  });
                }
              });
            }
          } 
        });

        setLocData(response.data.locations);
        setZgradi(newZgradi);
        setMesta(newMesta);
        setUredi(newUredi);
        setLastLocation({
          zgradaID: zID,
          mestoID: mID,
          add: false,
          edit: false,
          delete: false,
        });
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchinfLocations, text : translations?.backend[response.data.message]});
      }
    } catch (err) {
      // setData(dataConst.allowed);
    }
  });
  
  const mutation = useMutation((locationsData) => {
    const token = localStorage.getItem("token");

    switch(locationsData.tip){
      case "edit-zgrada":
      case "edit-mesto":
        return axios.put(
          `${apiPath}updateLocationName.php`,
          { ...locationsData, companyID: companyID },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      case "add":
        return axios.post(
          `${apiPath}addLocation.php`,
          { ...locationsData, companyID: companyID },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      case "delete":
        return axios.post(
          `${apiPath}deleteLocation.php`,
          { ...locationsData, companyID: companyID },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      default: 
    }
  });

  const updateLocations = (locationsData) => {
    mutation.mutate(locationsData, {
      onError: (error) => {
      },
      onSuccess: (dataReturned) => {
        if (dataReturned.data.success) {
          // na uspesen Add ili Remove ce se napravi refetch na locations.
          response.refetch();
        }
        else{
          switch(locationsData.tip){
            case "edit-zgrada":
              setSuccessZero({state : true, name : translations?.locations?.errorEditingBuilding, text : translations?.backend[dataReturned.data.message]});
                break;
            case "edit-mesto":
              setSuccessZero({state : true, name : translations?.locations?.errorEditingPlace, text : translations?.backend[dataReturned.data.message]});
              break;
            case "add":
              setSuccessZero({state : true, name : translations?.locations?.errorAddingLocation, text : translations?.backend[dataReturned.data.message]});
              break;
            case "delete":
              setSuccessZero({state : true, name : translations?.locations?.errorDeletingLocation, text : translations?.backend[dataReturned.data.message]});
              break;
            default: 
              break;
          }
        }
      },
    });
  };

  const handleChangeZgrada = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      setCurrentZgrada(newValue);
      locData.forEach((singleZgrada) => {
        if (singleZgrada.zgradaName === newValue.value) {
          let newMesta = [];
          let newUredi = [];
          singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
            if (indexMesto === 0) {
              setLastLocation({
                ...lastLocation,
                zgradaID: singleZgrada.zgradaID,
                mestoID: singleMesto.mestoID,
              });
              setCurrentMesto({
                value: singleMesto.mestoName,
                label: singleMesto.mestoName,
              });
              singleMesto.dataM.forEach((singleUred, indexUred) => {
                if (singleUred.deviceName !== null) {
                  if (indexUred === 0) {
                    setCurrentUred({
                      value: singleUred.deviceName,
                      label: singleUred.deviceName,
                    });
                  }
                  newUredi.push({
                    value: singleUred.deviceName,
                    label: singleUred.deviceName,
                  });
                } else {
                  setCurrentUred({});
                }
              });
              setUredi(newUredi);
            }
            newMesta.push({
              label: singleMesto.mestoName,
              value: singleMesto.mestoName,
            });
          });
          setMesta(newMesta);
        }
      });
    }
  };

  const handleChangeMesto = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      setCurrentMesto(newValue);
      locData.forEach((singleZgrada) => {
        if (singleZgrada.zgradaName === currentZgrada.label) {
          let newUredi = [];
          singleZgrada.dataZ.forEach((singleMesto) => {
            if (singleMesto.mestoName === newValue.value) {
              setCurrentMesto({
                value: singleMesto.mestoName,
                label: singleMesto.mestoName,
              });

              singleMesto.dataM.forEach((singleUred, indexUred) => {
                if (singleUred.deviceName !== null) {
                  if (indexUred === 0) {
                    setLastLocation({
                      ...lastLocation,
                      zgradaID: singleZgrada.zgradaID,
                      mestoID: singleMesto.mestoID,
                    });
                    setCurrentUred({
                      value: singleUred.deviceName,
                      label: singleUred.deviceName,
                    });
                  }
                  newUredi.push({
                    value: singleUred.deviceName,
                    label: singleUred.deviceName,
                  });
                } else {
                  setCurrentUred({});
                  setLastLocation({
                    ...lastLocation,
                    zgradaID: singleZgrada.zgradaID,
                    mestoID: singleMesto.mestoID,
                  });
                }
              });
              setUredi(newUredi);
            }
          });
        }
      });
    }
    if (actionMeta.action === "create-option") {
      setLastLocation({ ...lastLocation, edit: false, delete: false, add: true, });
      locData.forEach((singleZgrada) => {
        if (singleZgrada.zgradaName === currentZgrada.value) 
          updateLocations({ add: true, zgradaName: singleZgrada.zgradaName, mestoName: newValue.value, tip : "add"});
      });
    }
  };

  const handleChangeUred = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      setCurrentUred(newValue);
    }
  };

  const handleCritical = () => {
    updateLocations({...criticalValue});
    setCriticalValue({});
    setCriticalAction({state : false, name : "", text : ""})
  } 

  const handleZgradaDelete = () => {
    setLastLocation({ ...lastLocation, edit: false, delete: true, add: false, });
    locData.forEach((singleZgrada) => {
      if (singleZgrada.zgradaName === currentZgrada.value)
        setCriticalValue({tip : "delete", zgradaID : singleZgrada.zgradaID})
    });
    setCriticalAction({state : true, name : translations?.locations?.criticalDeleteBuilding, 
                        text : `${translations?.locations?.criticalDeleteBuildingText[0]}\n\n${currentZgrada.label}\n\n` + 
                              `${translations?.locations?.criticalDeleteBuildingText[1]}\n\n` + 
                               `${locData[0]?.zgradaName}\n${locData[0]?.dataZ[0]?.mestoName}`})
  };
  const handleMestoDelete = () => {
    setLastLocation({ ...lastLocation, edit: false, delete: true, add: false, });
    locData.forEach((singleZgrada) => {
      if (singleZgrada.zgradaName === currentZgrada.value) {
        singleZgrada.dataZ.forEach((singleMesto) => {
          if (singleMesto.mestoName === currentMesto.value)
          setCriticalValue({tip: "delete", zgradaID: singleZgrada.zgradaID, mestoID: singleMesto.mestoID,});
        });
      }
    });
    setCriticalAction({state : true, name : translations?.locations?.criticalDeletePlace,
                       text : `${translations?.locations?.criticalDeletePlaceText[0]}\n\n${currentMesto.label}\n\n 
                               ${translations?.locations?.criticalDeletePlaceText[1]}\n\n` + 
                              `${locData[0]?.zgradaName}\n${locData[0]?.dataZ[0]?.mestoName}`})
  };

  const handleEdit = (locationsData) => {
    switch(locationsData.tip){
      case "edit-zgrada":
        setLastLocation({...lastLocation, edit: true, delete: false, add: false,});
        setEditZgrada(false)
        locData.forEach((singleZgrada) => {
          if (singleZgrada.zgradaName === currentZgrada.value) 
            updateLocations({ name: locationsData.newValues[0], zgradaID: singleZgrada.zgradaID, zgrada: true, tip : "edit-zgrada"});
        });
        break;
      case "edit-mesto":
        setLastLocation({...lastLocation, edit: true, delete: false, add: false,});
        setEditMesto(false);
        locData.forEach((singleZgrada) => {
          if (singleZgrada.zgradaName === currentZgrada.value) {
            singleZgrada.dataZ.forEach((singleMesto) => {
              if (singleMesto.mestoName === currentMesto.value) 
                updateLocations({ name: locationsData.newValues[0], zgradaID: singleZgrada.zgradaID, 
                    mestoID: singleMesto.mestoID, zgrada: false, tip : "edit-mesto" });
            });
          }
        });
        break;
      case "add":
        setLastLocation({...lastLocation, edit: false, delete: false, add: true,});
        setAddLocation(false);
        updateLocations({ companyID : companyID, zgradaName: locationsData.newValues[0], mestoName : locationsData.newValues[1], tip : "add" });
        break;
      default:  
    }
  };

  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <div className={classes.locationsDiv}> 
      <div className={classes.dropDownSegment }>
        <h3 className={classes.naslov}>{translations?.locations?.building}</h3>
        <div className={classes.iconsDiv}>
          <EditOutlinedIcon
            className={classes.icon}
            onClick={() => {
              setEditZgrada(true);
              setEditMesto(false);
            }}
          />
          <DeleteOutlineIcon
            className={classes.icon}
            onClick={() => {
              handleZgradaDelete();
            }}
          />
        </div>
        <div className={classes.selectDiv}>
          <Select
            maxMenuHeight={200}
            onChange={(v, a) => {
              handleChangeZgrada(v, a);
            }}
            options={zgradi}
            value={currentZgrada}
          />
        </div>
      </div>
      <div className={classes.dropDownSegment }>
        <h3 className={classes.naslov}>{translations?.locations?.place}</h3>
        <div className={classes.iconsDiv}>
          <EditOutlinedIcon
            className={classes.icon}
            onClick={() => {
              setEditMesto(true);
              setEditZgrada(false);
            }}
          />
          <DeleteOutlineIcon
            className={classes.icon}
            onClick={() => {
              handleMestoDelete();
            }}
          />
        </div>
        <div className={classes.selectDiv}>
          <CreatableSelect
            maxMenuHeight={200}
            onChange={(v, a) => {
              handleChangeMesto(v, a);
            }}
            options={mesta}
            value={currentMesto}
          />
        </div>
      </div>
      <div className={classes.dropDownSegment }>
        <h3 className={classes.naslov} style={{color:"gray", borderBottom:"2px solid gray"}}>{translations?.devices?.device}</h3>
        <div className={classes.iconsDiv}></div>
        <div className={classes.selectDiv}>
          <Select
            maxMenuHeight={120}
            onChange={(v, a) => {
              handleChangeUred(v, a);
            }}
            options={uredi}
            value={currentUred}
          />
        </div>
      </div>

      {!editMesto && !editZgrada && !addLocation && 
        <div style={{textAlign: "center"}}>
          <SaveButton 
            name={translations?.locations?.addBuilding}
            klasaButton={"buttonAdd"}
            handleButtonClick={() => {setAddLocation(true)}}
          />
        </div>
      }
      {editZgrada && 
        <React.Fragment>
          <NTextField 
            labels={[translations?.locations?.editBuilding]}
            inits={[currentZgrada.label]}
            tip={"edit-zgrada"}
            handleEnter={handleEdit}
            klasa={"cellSingle"}
            save={true}
            buttonName={translations?.core?.confirm}
            klasaButton={"buttonConfirm"}
            cancel={true}
            cancelKlasa={"buttonCancel"}
            cancelFunc={() => setEditZgrada(false)}
          />
        </React.Fragment>
      }
      {editMesto && 
        <React.Fragment>
          <NTextField 
            labels={[translations?.locations?.editMesto]}
            inits={[currentMesto.label]}
            tip={"edit-mesto"}
            handleEnter={handleEdit}
            klasa={"cellSingle"}
            save={true}
            buttonName={translations?.core?.confirm}
            klasaButton={"buttonConfirm"}
            cancel={true}
            cancelKlasa={"buttonCancel"}
            cancelFunc={() => setEditMesto(false)}
          />
        </React.Fragment>
      }
      {!editMesto && !editZgrada &&  addLocation && 
        <React.Fragment>
          <NTextField 
            labels={[translations?.locations?.building, translations?.locations?.place]}
            inits={["", ""]}
            placeholder={[translations?.locations?.buildingPlaceholder, translations?.locations?.placePlaceholder]}
            autoFocus={[true, false]}
            tip={"add"}
            handleEnter={handleEdit}
            klasa={"cellSingle"}
            save={true}
            buttonName={translations?.core?.confirm}
            klasaButton={"buttonConfirm"}
            cancel={true}
            cancelKlasa={"buttonCancel"}
            cancelFunc={() => setAddLocation(false)}
          />
        </React.Fragment>
      }
      {criticalAction.state && 
        <CriticalAction 
            name={criticalAction.name}
            text={criticalAction.text}
            handleYes={handleCritical} 
            handleNo={() => {setCriticalAction({state : false, name : "", text : ""})}} 
        />
      }
    </div>
  );
};

export default Locations;
