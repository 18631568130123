import React, {useState, useEffect, useContext} from "react";
import TextField from "@material-ui/core/TextField";
import {TranslationContext} from './../App';

export const NTextField = ({labels, inits, handleEnter, tip, save, autoFocus, multiline, 
                            allowEnter, disabled, klasa, buttonName, klasaButtonDiv, 
                            klasaButton, placeholder, naslov, klasaNaslov, cancel, cancelName, cancelKlasa, cancelFunc}) => {
    const {translations} = useContext(TranslationContext)
    const [newValues, setNewValues] = useState([])
    
    useEffect(()=>{
      setNewValues(inits)
    }, [labels, inits])
    const handleButtonClick = () => {
      handleEnter({tip : tip, newValues : newValues, labels : labels})
    }
  
    return (
      <React.Fragment>
        {typeof(newValues[0]) !== "undefined" && newValues?.map((newValue, index) => {
          return (
            <div key={index} className={klasa}>
              {typeof(naslov) !== "undefined" && typeof(naslov[index]) !== "undefined" &&  <h3 className={klasaNaslov}>{naslov[index]}</h3>}
              <TextField
                fullWidth={true}
                label={labels[index]}
                autoFocus={typeof(autoFocus) === "undefined" ? true : autoFocus[index]}
                multiline={typeof(multiline) === "undefined" ? false : multiline[index]}
                disabled={typeof(disabled) === "undefined" ? false : disabled[index]}
                placeholder={typeof(placeholder) !== "undefined" && inits[index] === "" ? placeholder[index] : ""}
                onChange={(event) => {
                  let tempValues = [...newValues];
                  tempValues[index] = event.target.value;
                  setNewValues(tempValues);
                }}
                value={newValues[index]}
                onKeyPress={(event) => {
                  if (event.key === "Enter" && typeof(allowEnter) === "undefined" && labels.length - 1 === index && 
                      ((typeof(multiline) !== "undefined" && !multiline[labels.length - 1]) || typeof(multiline) === "undefined")) 
                    handleEnter({tip : tip, newValues : newValues, labels : labels})
                }}          
              />
            </div>
          );
        })}
        {(typeof(save) !== "undefined" || typeof(cancel) !== "undefined") && (
          <div className={klasaButtonDiv} style={{textAlign: "center"}}>
            {save === true && 
              <SaveButton  
                name={typeof(buttonName) === "undefined" ? translations?.core?.save : buttonName}
                klasaButton={typeof(klasaButton) === "undefined" ? '' : klasaButton}
                handleButtonClick={handleButtonClick}
              />
            }
            {cancel === true && 
              <SaveButton  
                name={typeof(cancelName) === "undefined" ? translations?.core?.cancel : cancelName}
                klasaButton={typeof(cancelKlasa) === "undefined" ? '' : cancelKlasa}
                handleButtonClick={cancelFunc}
              />
            }
          </div>
        )}
      </React.Fragment>
    )
  };
  
export const SaveButton = ({name, klasaButton, handleButtonClick}) => {
  return ( 

      <button className={klasaButton}
        onClick={() => {
          handleButtonClick(true);
        }}
      >
        {name}
      </button>

  )
}

export const EditSectionNaslov = ({expanded, naslov, tip, handleSelect}) => {
  return (  
    // <h3 className={expanded ? classes.editSectionNaslovSelected : classes.editSectionNaslov}
    <h3 className={expanded ? "editSectionNaslovOn" : "editSectionNaslovOff"}
        onClick={() => {handleSelect(tip)}}>
      {naslov}
    </h3>
  )
}
 
