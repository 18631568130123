import React, {useState, useContext} from "react";
import { NTextField, SaveButton } from "../edit-elements";
import { TranslationContext } from "../../App";

const AddCompany = ({addCompany}) => {
    const {translations} = useContext(TranslationContext)
    const [addCompanyVisible, setAddCompanyVisible] = useState(false)

    const handleConfirm = (ob) => {
        addCompany({companyName : ob.newValues[0], mail1 : ob.newValues[1], mail2 : ob.newValues[3], mail3 : ob.newValues[4], password : ob.newValues[2]})
        setAddCompanyVisible(false);
    }

    return (
      <div className="addSection">
          {!addCompanyVisible && 
            <div style={{textAlign: "center", marginTop : "20px"}}>
              <SaveButton
                name={translations?.companies?.addCompany}
                klasaButton={"buttonAdd"}
                handleButtonClick={() => {setAddCompanyVisible(true)}}
              />
            </div>
          }
          {addCompanyVisible && 
            <React.Fragment>
              <h2 className={"addSectionGlavenNaslov"}>{translations?.companies?.addCompany}</h2>
              <NTextField 
                naslov={[translations?.companies?.companyName, translations?.companies?.domain1, translations?.companies?.password, 
                        translations?.companies?.domain2, translations?.companies?.domain3]}
                labels={[translations?.companies?.companyName, translations?.companies?.domain1label, translations?.companies?.password,
                        translations?.companies?.domain2label, translations?.companies?.domain3label]}
                inits={["", "", "", "", ""]}
                placeholder={[translations?.companies?.companyPlaceholder, "@example.com", translations?.companies?.passwordPlaceholder, "@example.com", "@example.com"]}
                tip={"add"}
                save={true}
                handleEnter={handleConfirm}
                klasa={"childAddFS"}
                klasaNaslov={"naslovMal"}
                klasaButton={"buttonConfirm"}
                buttonName={translations?.core?.confirm}
                cancel={true}
                cancelFunc={() => setAddCompanyVisible(false)}
                cancelKlasa={"buttonCancel"}
                allowEnter={false}
              />
          </React.Fragment>
          }
    </div>
  );
};

export default AddCompany;
