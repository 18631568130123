import React, { useEffect, useState, useContext } from "react";
import {NTextField, SaveButton, EditSectionNaslov} from './../edit-elements'
import Select from "react-select";
import { PropertyTableDevice } from "../C-inventar/inventory-edit-section";
import './../styles/globalClasses.css';
import { TranslationContext } from "../../App";

const EditSection = ({current, updateTestDevice, typesDropDown}) => {
    const {translations} = useContext(TranslationContext)
    const [isExpanded, setIsExpanded] = useState({type : false, comment : false, settings : false})
    const [newCurrent, setNewCurrent] = useState({});
    const [types, setTypes] = useState([])
    const [currentType, setCurrentType] = useState({})

    useEffect(()=>{
      setNewCurrent({...current})
      let newTypes = [];
      let cType = {label : "Default Product", value : 1};
      typesDropDown.forEach((type, index)=>{
        newTypes.push({label : type.productName, value : type.pID})
        if (type.pID === current.pID) cType = newTypes[index];
      })
      setTypes(newTypes)
      setCurrentType(cType)
    }, [current, typesDropDown])
    
    const handleSelect = (tip) => {
      let newIsExpanded = {...isExpanded}
      newIsExpanded[tip] = !newIsExpanded[tip]
      setIsExpanded(newIsExpanded)
    }
  
    const handleEnter = ({tip, newValues, labels}) => {
    //   handleSelect(tip);  
      switch(tip){
        case "type":
          updateTestDevice({tip : tip, pID : currentType.value, serialID : current.serialID}); 
          break;
        case "comment": 
          setNewCurrent({...newCurrent, comment : newValues[0]})
          updateTestDevice({tip : tip, comment : newValues[0], serialID : current.serialID}); 
          break;
        case "settings":
          let setting = {};
          setting[newValues[0]] = newValues[1]
          updateTestDevice({tip : tip, changes : {...setting}, serialID : current.serialID})
          break;
        default: 
            break;
      }
    }
  
    return (
      <React.Fragment>
        <h2 className={"editSectionGlavenNaslov"}>{translations?.devices?.testEdit}</h2>
        <div className={"editSectionLeft"}> 
          <EditSectionNaslov naslov={translations?.devices?.comment} expanded={isExpanded.comment} tip={"comment"} handleSelect={handleSelect}/>
          {
            isExpanded.comment && 
            <NTextField 
              labels={[translations?.devices?.comment]}
              inits={[newCurrent.comment]}
              tip={"comment"}
              multiline={[true]}
              save={true}
              handleEnter={handleEnter}
              klasa={"cellMultiline"}
              klasaButton={"buttonSave"}
            />
          }
          
          <EditSectionNaslov naslov={translations?.products?.productType} expanded={isExpanded.type} tip={"type"} handleSelect={handleSelect}/>
          {isExpanded.type && 
            <div className={"dropDownSingle"} style={{marginTop : "30px"}}>
              <Select
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  onChange={(newValue, actionMeta) => {
                  if (actionMeta.action === "select-option")
                      setCurrentType(newValue);
                  }}
                  options={types}
                  value={currentType}
              />
              <div style={{textAlign: "center", marginTop : "10px"}}>
                <SaveButton 
                  name={translations?.core?.save}
                  klasaButton={"buttonSave"}
                  handleButtonClick={() => handleEnter({tip : "type"})}
                />
              </div>
            </div>
          }
        </div>
        <div className={"editSectionRight"}>
          <EditSectionNaslov naslov={translations?.devices?.settings} expanded={isExpanded.settings} tip={"settings"} handleSelect={handleSelect}/>
          { isExpanded.settings && 
            <PropertyTableDevice handleEnter={handleEnter} deviceSettings={newCurrent.deviceSettings} />   
          }
        </div>
      </React.Fragment>
    )
  }

export default EditSection;  