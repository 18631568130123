import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useMutation } from 'react-query';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorOutlineRounded } from '@material-ui/icons';
import { DialogContext } from '../../App';
import {TranslationContext} from './../../App';
import coverImage from './../styles/DamaLUFTCover.jpg';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "var(--orangeDark)",
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    paddingTop: '5px',
    marginBottom: '-10px',
  },
  splitScreen : {
    display : "flex",
    gap : "100px"
  },
  coverImage : {
    position : "relative",
    top : "55px",
    width : "45vw",
    borderRadius : "10px",
    [theme.breakpoints.down(1600)] : {
      top : "80px"
    },
    [theme.breakpoints.down(1360)] : {
      top : "105px"
    },
    [theme.breakpoints.down(1150)] : {
      top : "130px"
    }
  },
  coverImageContainer : {
    [theme.breakpoints.down(1000)] : {
      display : "none"
    }
  }
}));

export const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { translations } = useContext(TranslationContext);
  const { apiPath, setUserInfo, setSuccessfullLogin } = useContext(DialogContext)
  const [login, setLogin] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  
  const mutation = useMutation((formData) => {
    const token = localStorage.getItem("token");
    return axios.post(`${apiPath}login.php`, formData, { headers: { Authorization: `Bearer ${token}` } });  
  });

  const onSubmit = (event) => {
    event.preventDefault();
    setError('');
    const data = new FormData(event.target);

    mutation.mutate(
      {         
        email: data.get('email'),
        password: data.get('password') 
      },
      {
        onSuccess: (dataReturned) => {
          if(dataReturned.data.success === 0){
            setSuccessfullLogin(false)
            // Error Messages go here !!!
            setError(translations?.backend[dataReturned.data.message])
            return
          }
          
          setUserInfo(dataReturned.data.user)
          
          let newUserType = 0; // 0 - user
          if(Number(dataReturned.data.user.isadmin) === 1)
            newUserType = 1; // 1 - admin
          if(Number(dataReturned.data.user.isDMadmin) === 1)
            newUserType = 2; // 2 - DMadmin

          if(localStorage.getItem("userType") !== null){
            if(Number(localStorage.getItem("userType")) !== newUserType){
              localStorage.removeItem("defaultsR") 
              localStorage.removeItem("defaultsA")
            }
          }
          
          let newCompanyPass = dataReturned.data.user.companyPass
          if(localStorage.getItem("code") !== null){
            if(localStorage.getItem("code") !== newCompanyPass){
              localStorage.removeItem("defaultsR") 
              localStorage.removeItem("defaultsA")
            }
          }

          localStorage.setItem("code", newCompanyPass)
          localStorage.setItem("userType", newUserType)

          // dataReturned.data.user === "1" ? setAdmin(true) : setAdmin(false) // deprecated
          localStorage.setItem('token', dataReturned.data.token);
          
          history.push('/reports');
          setSuccessfullLogin(true);
        },
        onError: (error) => {
          setError(error.response.data.message);
        }
      }
    );
  };
  
  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>
  
  return (
    <Container component='main' maxWidth='xl' style={{padding : "0px"}}>
      <CssBaseline />
        <div className={classes.splitScreen}>
          <Container maxWidth='xs' className={classes.coverImageContainer}>
            <img src={coverImage} className={classes.coverImage}></img>
          </Container>
          <Container maxWidth='xs'>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {translations?.authentication?.signIn}
              </Typography>
              <form className={classes.form} onSubmit={onSubmit}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label={translations?.users?.email}
                  onFocus={() => {window.scroll(0, 300);}} 
                  name='email'
                  value={login.email}
                  onChange={(evt) => {
                    window.scroll(0, 250);
                    setLogin((state) => ({ ...state, email: evt.target.value }))
                  }}
                  autoFocus
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label={translations?.authentication?.password}
                  onFocus={() => {window.scroll(0, 300);}} 
                  type='password'
                  id='password'
                  value={login.password}
                  onChange={(evt) =>setLogin((state) => ({ ...state, password: evt.target.value }))}
                />
                <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                  { translations?.authentication?.signIn }
                </Button>
                <Grid container>
                  <Grid item style={{ justifyContent: 'center', fontSize : "0.8em" }}>
                    <Link to='/register'>{ translations?.authentication?.noAccountSignUp}</Link>
                  </Grid>
                </Grid>
                {/* Boolean error (string ce davat sekogas tocno) */}
                {!!error && (
                  <>
                    <div style={{ textAlign: 'center', paddingTop: '15px', marginBottom : "50px", color: 'red', }}>
                      <ErrorOutlineRounded style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                      <label className={classes.errorMessage}>{error}</label>
                    </div>
                  </>
                )}
              </form>
            </div>
          </Container>
        </div>
    </Container>
  );
};
