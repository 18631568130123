import Item from "./item";
import React, { useContext } from "react";
import { AccessContext } from "./access";
import { makeStyles } from "@material-ui/core/styles";
import { TranslationContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  childContainer: {
    boxSizing: "border-box",
    width: "25%",
    display : "inline-block",
    marginBottom : "30px",
    // borderRight: "1px solid #213a80",
    // marginTop: "100px",
    [theme.breakpoints.down(1151)]: {
      width : "50%",
    },
    [theme.breakpoints.down(601)]: {
      width : "100%"
    },
  },
  naslov: {
    width: "70%",
    margin: "auto",
    marginBottom: "30px",
    borderBottom: "2px solid #213a80",
    textAlign: "center",
    paddingBottom: "3px",
    [theme.breakpoints.down(1051)]: {
      marginTop : "20px"
    },
  },
}));

const AccessChild = ({ type }) => {
  const classes = useStyles();
  const data = useContext(AccessContext);
  const {translations} = useContext(TranslationContext)

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>
  else if (data.data[0] === undefined) return <React.Fragment></React.Fragment>;
  else if (type === "produkt") {
    return (
      <div className={classes.childContainer}>
        <h3 className={classes.naslov}>DamaLuft</h3>
        {data.data.map((singleProduct) => {
          let containsSelected = false
          singleProduct.dataP.forEach((singleZgrada) => {
            if(singleZgrada.checked){
              containsSelected = true;
              return
            }
            singleZgrada.dataZ.forEach((singleMesto) => {
              if(singleMesto.checked){
                containsSelected = true
                return
              }
            })
          })
          return (
            <Item
              type={type}
              key={singleProduct.productID}
              id={singleProduct.productID}
              name={translations?.access?.allAccess}
              selected={singleProduct.selected}
              checked={singleProduct.checked}
              visible={singleProduct.visible}
              containsSelected={containsSelected}
            ></Item>
          );
        })}
      </div>
    );
  } else if (type === "zgrada") {
    return (
      <div className={classes.childContainer}>
        <h3 className={classes.naslov}>{translations?.locations?.building}</h3>
        {data.data.map((singleProduct) => {
          if (singleProduct.selected) {
            return singleProduct.dataP.map((singleZgrada) => {
              let containsSelected = false
              singleZgrada.dataZ.forEach((singleMesto) => {
                if(singleMesto.checked){
                  containsSelected = true
                  return
                }
              })
              singleZgrada.visible = true;
              return (
                <Item
                  type={type}
                  key={singleZgrada.zgradaID}
                  id={singleZgrada.zgradaID}
                  name={singleZgrada.zgradaName}
                  selected={singleZgrada.selected}
                  checked={singleZgrada.checked}
                  visible={singleZgrada.visible}
                  containsSelected={containsSelected}
                ></Item>
              );
            });
          } else
            return (<React.Fragment key={singleProduct.productID}></React.Fragment>);
        })}
      </div>
    );
  } else if (type === "mesto") {
    return (
      <div className={classes.childContainer}>
        <h3 className={classes.naslov}>{translations?.locations?.place}</h3>
        {data.data.map((singleProduct) => {
          if (singleProduct.selected) {
            return singleProduct.dataP.map((singleZgrada) => {
              singleZgrada.visible = true;
              if (singleZgrada.selected) {
                return singleZgrada.dataZ.map((singleMesto) => {
                  singleMesto.visible = true;
                  return (
                    <Item
                      type={type}
                      key={singleMesto.mestoID}
                      id={singleMesto.mestoID}
                      name={singleMesto.mestoName}
                      selected={singleMesto.selected}
                      checked={singleMesto.checked}
                      visible={singleMesto.visible}
                      containsSelected={false}
                    ></Item>
                  );
                });
              } else
                return (<React.Fragment key={100 + singleZgrada.zgradaID}></React.Fragment>);
            });
          } else
            return (<React.Fragment key={singleProduct.productID}></React.Fragment>);
        })}
      </div>
    );
  } else if (type === "ured") {
    return (
      <div className={classes.childContainer}>
        <h3 className={classes.naslov}>{translations?.devices?.device}</h3>
        {data.data.map((singleProduct) => {
          if (singleProduct.selected) {
            return singleProduct.dataP.map((singleZgrada) => {
              singleZgrada.visible = true;
              if (singleZgrada.selected) {
                return singleZgrada.dataZ.map((singleMesto) => {
                  singleMesto.visible = true;
                  if (singleMesto.selected) {
                    return singleMesto.dataM.map((singleDevice) => {
                      singleDevice.visible = true;
                      return (
                        <Item
                          type={type}
                          key={singleDevice.deviceID}
                          id={singleDevice.deviceID}
                          name={singleDevice.deviceName}
                          selected={singleDevice.selected}
                          checked={singleDevice.checked}
                          visible={singleDevice.visible}
                          serialNumber={singleDevice.serialNumber}
                          containsSelected={false}
                        ></Item>
                      );
                    });
                  } else
                    return (<React.Fragment key={500 + singleMesto.mestoID}></React.Fragment>);
                });
              } else
                return (<React.Fragment key={100 + singleZgrada.zgradaID}></React.Fragment>);
            });
          } else 
              return (<React.Fragment key={singleProduct.productID}></React.Fragment>);
        })}
      </div>
    );
  } else
    return (
      <div className={classes.childContainer}></div>
    );
};

export default AccessChild;
