import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import  './styles/globalClasses.css';
import { DialogContext } from '../App';
import { TranslationContext } from '../App';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import backgroundImage from './styles/header60.jpg'
import { Tooltip } from '@material-ui/core';

const COECE_PIKSELI = 500;

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    header: {
      height : "60px"
    },
    imgLogo : {
      position : "absolute",
      left : "10px",
      top : "10px",
      cursor : "pointer",
      width : "200px",
      height : "40px",
      zIndex : "105",
      [breakpoints.down(1000)]: {
        top : "10px",
        width : "135px",
        height : "27px"
      },
    },
    damaLuft : {
      fontFamily : "futura_bk_btbook",
      textAlign : "center",
      position : "relative",
      top : "10px",
      margin : "auto",
      color : "white",
      marginTop : "0px",
      marginBottom : "0px",
      padding : "0px",
      transform: "scale(0.95)", 
      [breakpoints.down(1000)]: {
        display : "inline-block",
        top : "35px",
        left : "12px",
        fontSize : "1.25em",
        transform: "scale(0.85)",
      },
    },
    headerKompanija : {
      position : "absolute",
      top : "7px",
      height : "20px",
      padding : "0px",
      margin : "0px",
      right : "140px",
      color : "white",
      [breakpoints.down(1000)]: {
        right : "60px",
        top : "5px"
      },
    },
    headerIme : {
      position : "absolute",
      top : "27px",
      height : "20px",
      padding : "0px",
      margin : "0px",
      right : "140px",
      color : "white",
      [breakpoints.down(1000)]: {
        right : "60px",
        top : "30px"
      },
    },
    headerJazik : {
      cursor : "pointer",
      position : "absolute",
      top : "17px",
      height : "20px",
      padding : "0px",
      margin : "0px",
      right : "60px",
      color : "white",
      transform: "scale(0.9)",
      [breakpoints.down(1000)]: {
        right : "10px",
        top : "35px"
      },
    },
    headerJazikLogin : {
      cursor : "pointer",
      position : "absolute",
      top : "17px",
      height : "20px",
      padding : "0px",
      margin : "0px",
      right : "20px",
      color : "white",
      transform: "scale(0.9)",
    },
    headerExit : {
      cursor : "pointer",
      position : "absolute",
      top : "19px",
      height : "20px",
      padding : "0px",
      margin : "0px",
      right : "10px",
      color : "white",
      transform: "scale(1.3)",
      [breakpoints.down(1000)]: {
        top : "7px"
      },
    },
    userIcon : {
      position : "absolute",
      top : "19px",
      height : "20px",
      padding : "0px",
      margin : "0px",
      right : "15px",
      color : "white",
      transform: "scale(1.5)",
      zIndex : "105"
    },
    personExpandedDiv : {
      display : "inline-block",
      position : "absolute",
      borderRadius : "5px",
      top : "45px",
      right : "10px",
      backgroundColor : "white",
      fontWeight : "bold",
      zIndex : "105"
    },
    personExpandedUl : {
      border : "1px solid black",
      borderRadius : "5px",
      listStyleType: "none",
      margin : "0px",
      padding : "0px",
      "& li" : {
        padding : "5px",
        backgroundColor : "#f5f9ff",
      },
    },
  })
);

export const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const {userInfo, setUserInfo, setSelectedModule, setSuccessfullLogin} = useContext(DialogContext);
  const {langCode, setLangCode, translations} = useContext(TranslationContext);
  const [width, setWidth]   = useState(window.innerWidth);
  const [personExpanded, setPersonExpanded] = useState(false)

  const updateDimensions = () => {setWidth(window.innerWidth)};
  const updateOrientation = () => {
      setTimeout(() => {setWidth(window.innerWidth)}, 10) 
      setTimeout(() => {setWidth(window.innerWidth)}, 50) 
      setTimeout(() => {setWidth(window.innerWidth)}, 100) 
  }
  
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    window.addEventListener("orientationchange", updateOrientation)
    return () => {
        window.removeEventListener("resize", updateDimensions)
        window.removeEventListener("orientationchange", updateOrientation)
    };
}, []);

  const handleClose = () => {
    setSuccessfullLogin(false)
    setUserInfo({})
    setSelectedModule("/reports")
    localStorage.removeItem('token');
    history.push('/login');
  };

  const handleSetLangCode = () => {
    // Loop : mk - en - sr - mk
    let newLangCode;
    if(langCode === "mk") newLangCode = "en"
    if(langCode === "en") newLangCode = "sr"
    if(langCode === "sr") newLangCode = "mk"

    //localStorage.setItem("langCode", langCode === "mk" ? "en" : "mk")
    localStorage.setItem("langCode", newLangCode)
    // langCode === "mk" ? setLangCode("en") : setLangCode("mk")
    setLangCode(newLangCode)
  }

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>

  return (
    <header className={classes.header} style={{backgroundImage : `url(${backgroundImage})`}}>
        <img src={`../../logo.png`} alt='DataMajoris logo' className={classes.imgLogo}
          onClick={() => history.push('/reports')}/>

        <h1 className={classes.damaLuft}>DamaLUFT</h1>  

        {location.pathname !== '/login' && location.pathname !== '/register' && (
          <React.Fragment>
            {width > COECE_PIKSELI && (
              <React.Fragment>
                {typeof(userInfo?.companyName) !== "undefined" &&
                  <h4 className={classes.headerKompanija}>{userInfo?.companyName}</h4>
                }
                {typeof(userInfo?.firstName) !== "undefined" && typeof(userInfo?.lastName) !== "undefined" &&
                  <h4 className={classes.headerIme}>{userInfo?.firstName + " " + userInfo?.lastName}</h4>
                }
                <Tooltip title={translations?.core?.exitTooltip || "Одјави се"} aria-label="exit">
                  <ExitToAppIcon className={classes.headerExit} onClick={() => {handleClose()}}/>
                </Tooltip>
              </React.Fragment>
            )}
            {width <= COECE_PIKSELI && (
              <React.Fragment>
                <PersonOutlineIcon className={classes.userIcon} onClick={() => setPersonExpanded(!personExpanded)}/>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {width > COECE_PIKSELI && (
          <Tooltip title={translations?.core?.changeLanguage || "Промени јазик"} aria-label="lang">
            <h4 className={location.pathname === '/login' || location.pathname === '/register' ? classes.headerJazikLogin : classes.headerJazik}
                onClick={() => {handleSetLangCode()}}>
                    {langCode.toUpperCase()}
            </h4>
          </Tooltip>
        )}

        {width <= COECE_PIKSELI && (location.pathname === '/login' || location.pathname === '/register') && (
          <h4 className={classes.headerJazikLogin}
              onClick={() => {handleSetLangCode()}}>
            {langCode.toUpperCase()}
          </h4>
        )}

        {personExpanded && width <= COECE_PIKSELI && (
          <React.Fragment>
            <div className={classes.personExpandedDiv}>
              <ul className={classes.personExpandedUl}>
                {typeof(userInfo?.companyName) !== "undefined" &&
                  <li style={{paddingBottom : "0px", borderTopLeftRadius : "10px", borderTopRightRadius : "5px"}}>{userInfo?.companyName}</li>
                }
                {typeof(userInfo?.firstName) !== "undefined" && typeof(userInfo?.lastName) !== "undefined" &&
                  <li style={{paddingTop : "0px", paddingBottom : "15px", fontWeight : "normal"}}>{userInfo?.firstName + " " + userInfo?.lastName}</li>
                }
                <li style={{height : "18px", paddingTop : "2px" , paddingBottom : "2px", borderTop : "1px solid black", cursor : "pointer"}}  onClick={() => {handleSetLangCode()}}>
                  <span style={{float : "left", fontWeight : "normal"}}>Одбери јазик</span>
                  <span style={{float : "right", marginLeft : "20px"}}>{langCode.toUpperCase()}</span>
                </li>
                <li style={{height : "22px", borderBottomRightRadius : "5px", borderBottomLeftRadius : "5px"}}>
                  <span style={{float : "left", fontWeight : "normal"}}>Одјави се</span>
                  <span style={{float : "right", color : "#001f52", marginLeft : "20px", cursor : "pointer"}}><ExitToAppIcon onClick={() => {handleClose()}}/></span>
                </li>
              </ul>  
            </div>   
          </React.Fragment>
        )}
        {personExpanded && width <= COECE_PIKSELI && (
          <MegaDiv action={setPersonExpanded} />
        )}
    </header>
  );
};



const useStyle2 = makeStyles(({ breakpoints }) =>
  createStyles({
    megaDiv: {
      width : "10000px",
      height : "10000px",
      position : "fixed",
      top : "0",
      left : "0",
      zIndex : "104"
    },
  })
);


export const MegaDiv = (props) => {
  const classes = useStyle2();

  return (
    <div className={classes.megaDiv} onClick={() => {
        props.action(false)
        if(props.action2 !== undefined)
          props.action2(false)
      }}
    >
    </div>    
  )
}