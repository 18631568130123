import React, {useState, useContext, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import ErrorPrivilegesPath from "../auth/error-privileges";
import MaterialTable from "material-table";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import AddCompany from "../C-companies/add-company";
import EditSection from "../C-companies/companies-edit-section";
import CriticalAction from "./../critical-action"
import { DialogContext, TranslationContext} from './../../App'
import {useHistory} from 'react-router-dom';

const Companies = () => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const {translations} = useContext(TranslationContext)
  const history = useHistory();
  const {setSuccessZero, apiPath, selectedModule, setSelectedModule, refreshState} = useContext(DialogContext)
  const [currentCompany, setCurrentCompany] = useState({}); // Datamajoris e pred-selektirana kompanija
  const [companies, setComapnies] = useState([]);
  const [criticalAction, setCriticalAction] = useState({state : false, name : "", text : ""})
  const [superCriticalAction, setSuperCriticalAction] = useState({state : false, name : "", text : ""})
  const [criticalValue, setCriticalValue] = useState({});
  const [firstRender, setFirstRender] = useState(false)
  
  const response = useQuery("companies", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiPath}fetchCompanies.php`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if(response.data.success){
        setFirstRender(true);
        response.data.total === "0" ? setComapnies([]) : setComapnies(response.data.companies);
        if(typeof(currentCompany.companyID) === "undefined")
          setCurrentCompany(response.data.companies[0])
        else{
          response.data.companies.forEach((company)=>{
              if(company.companyID === currentCompany.companyID )
              setCurrentCompany(company)
          })
        }
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingCompanies, text : translations?.backend[response.data.message]});
      }

      return response.data;
    } catch (err) {
    }
  });

  const mutation = useMutation((companyData) => {
    const token = localStorage.getItem("token");

    switch(companyData.tip){
      case "add":
        return axios.post(`${apiPath}addCompany.php`, companyData, { headers: { Authorization: `Bearer ${token}` } });
      case "name":
        return axios.put(`${apiPath}updateCompanyName.php`, companyData, { headers: { Authorization: `Bearer ${token}` } })
      case "mail1":
      case "mail2":
      case "mail3":
        return axios.put(`${apiPath}updateCompanyMail.php`, companyData, { headers: { Authorization: `Bearer ${token}` } })
      case "delete":
        return axios.post(`${apiPath}deleteCompany.php`, companyData, { headers: { Authorization: `Bearer ${token}` } })
      default:
    }
  });

  const updateCompany = (companyData) => {
    mutation.mutate(
      { ...companyData },
      {
        onError: (error) => {
        },
        onSuccess: (dataReturned) => {
          if(dataReturned.data.success)
            response.refetch();
          else{
            switch(companyData.tip){
              case "add":
                  setSuccessZero({state : true, name : translations?.companies?.errorAddCompany, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "edit":
                  setSuccessZero({state : true, name : translations?.companies?.errorEditCompany, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "delete":
                  setSuccessZero({state : true, name : translations?.companies?.errorDeleteCompany, text : translations?.backend[dataReturned.data.message]});
              case "mail1":
              case "mail2":
              case "mail3":
                setSuccessZero({state : true, name : translations?.companies?.errorUpdateCompanyMail, text : translations?.backend[dataReturned.data.message]});
                break
              default: 
                break;
            }
          }
        },
      }
    );
  };

  const addCompany = (values) => {
    updateCompany({tip : "add", ...values, })
  }
  
  const handleCritical = () => {
    setCriticalAction({state : false, name : "", text : ""})
    let cName = "";
    companies.forEach((company) => {
      if(company.companyID === criticalValue.companyID)
        cName = company.companyName;
    })
    setSuperCriticalAction({state : true, name : translations?.companies?.superCriticalDeleteCompany, 
                          text : translations?.companies?.superCriticalDeleteCompanyText[0] +
                                `\n\n${cName}\n`})
  } 

  const handleSuperCritical = () => {    
    updateCompany({...criticalValue});
    setSuperCriticalAction({state : false, name : "", text : ""})
    setCriticalValue({})
  }

  useEffect(() => {
    if(selectedModule !== "/companies")
      setSelectedModule("/companies")
  }, [])

  if(!firstRender && redirect.state === false)
    return <React.Fragment></React.Fragment>

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>

  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <React.Fragment>
      {/* <div className={"splitScreenDiv"}> */}
        <div className={"tableFS"}>
          <MaterialTable
            title={translations?.companies?.tableCompanies}
            options={{
              padding: "dense",
              headerStyle : {padding : "3px"},
              actionsCellStyle : {paddingLeft : "12px"}, 
            }}
            localization={{
              header: { actions: translations?.core?.actions },
              pagination : {
                firstTooltip : translations?.core?.firstPage,
                lastTooltip : translations?.core?.lastPage,
                nextTooltip : translations?.core?.nextPage,
                previousTooltip : translations?.core?.previousPage,
                labelRowsSelect: translations?.core?.rows,
                labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
              },
              body : {
                emptyDataSourceMessage : translations?.core?.emptyTable
              },
              toolbar: {
                searchPlaceholder: translations?.core?.search,
                searchTooltip : translations?.core?.search
              },
            }}
            columns={[
              { title: translations?.companies?.companyName, field: "companyName", 
                  cellStyle : {padding : "0px 5px", paddingLeft : "45px"}, headerStyle : {paddingLeft : "45px"}},
              { title: translations?.companies?.companyID, field: "companyID"},
              { title: translations?.companies?.companyCode, field: "companyPass"},
              { title: translations?.companies?.domain1, field: "mail1"},
              { title: translations?.companies?.domain2, field: "mail2"},
              { title: translations?.companies?.domain3, field: "mail3"},
            ]}
            data={companies.map((dat) => ({
              ...dat,
            }))}
            actions={[
              (rowData) => ({
                icon:
                  currentCompany.companyID === rowData.companyID
                    ? CheckBoxIcon
                    : CheckBoxOutlineBlankIcon,
                tooltip: translations?.companies?.selectCompany,
                onClick: (event, rowData) => {
                  setCurrentCompany(rowData);
                },
              }),
              (rowData) => ({
                icon: DeleteSweepIcon,
                tooltip: translations?.companies?.deleteCompany,
                onClick: (event, rowData) => {
                  setCriticalValue({tip : "delete", companyID : rowData.companyID})
                  setCriticalAction({state : true, name : translations?.companies?.criticalDeleteCompany
                                    , text : `${translations?.companies?.criticalDeleteCompanyText[0]}\n\n` + 
                                            `${rowData.companyName}\n\n${translations?.companies?.criticalDeleteCompanyText[1]}`})
                },
              }),
              {
                icon: RefreshIcon,
                tooltip: translations?.core?.refresh,
                isFreeAction: true,
                onClick: (event) => response.refetch()
              }
            ]}
            onRowClick={(event, rowData) => {
              setCurrentCompany(rowData);
            }}
          />
          <AddCompany addCompany={addCompany} />
        </div>
      
      <EditSection current={currentCompany} updateCompany={updateCompany}></EditSection>
      {criticalAction.state && 
        <CriticalAction 
            name={criticalAction.name}
            text={criticalAction.text}
            handleYes={handleCritical} 
            handleNo={() => {setCriticalAction({state : false, name : "", text : ""})}} 
        />
      }
      {superCriticalAction.state && 
        <CriticalAction 
            name={superCriticalAction.name}
            text={superCriticalAction.text}
            handleYes={handleSuperCritical} 
            handleNo={() => {setSuperCriticalAction({state : false, name : "", text : ""})}} 
        />
      }
    </React.Fragment>
  );
};

export default Companies;
