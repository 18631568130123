import { useContext } from 'react';
import {Redirect} from 'react-router-dom';
import { TranslationContext } from '../../App';

const POPUP_DELAY = 300;

const ErrorPrivilegesPath = ({status}) => {
  return <Redirect to="/login" />

  // const {translations} = useContext(TranslationContext)

  // if(localStorage.getItem("token") !== null){
  //   if (status === 401)
  //     setTimeout(() => {alert(translations?.core?.pathUnauthorized)}, POPUP_DELAY);
  //   else if (status === 403)
  //     setTimeout(() => {alert(translations?.core?.pathAccessDenied)}, POPUP_DELAY);
  //   else  
  //     setTimeout(() => {alert(translations?.core?.pathAnotherError)}, POPUP_DELAY);
  // }
  
  // return <Redirect to="/login" />
}

export default ErrorPrivilegesPath;