import React, { useContext, useEffect, useState } from 'react';
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { makeStyles } from '@material-ui/core';
import { TranslationContext } from '../../App';
import {formatDatumVremeTS} from './../formatDates';

const useStyles = makeStyles((theme) => ({
    tooltip : {
      color : "var(--blue)"    ,
      backgroundColor : "white",
      borderRadius : "10px",
      border : "1px solid black",
      lineHeight : "0.2em",
      padding : "0px 7px",
      whiteSpace : "nowrap",
    },
    compartment : {
      float : "left",
      width : "33.33%",
      height : "50px",
    },
    graphTitle : {
        color : "var(--blue)",
        [theme.breakpoints.down(500)] : {
            fontSize : "0.95em",
            position : "relative",
            top : "10px"
        },
    },
    dates : {
        textAlign : "right",
        lineHeight : "50px",
        color : "var(--blue)",
        [theme.breakpoints.down(500)] : {
            fontSize : "0.8em",
        },
        [theme.breakpoints.down(400)] : {
            fontSize : "0.7em",
        },
    }
}));

export const Graph = (props) => {
    const { translations } = useContext(TranslationContext);
    const classes = useStyles();
    const [ ticksYSet, setTicksYSet] = useState([]);
    const [ minDomain, setMinDomain ] = useState(0);
    const [ maxDomain, setMaxDomain ] = useState(1000);
    const [width, setWidth]   = useState(window.innerWidth);    

    const updateDimensions = () => {setWidth(window.innerWidth)};
    const updateOrientation = () => {
        setTimeout(() => {setWidth(window.innerWidth)}, 10) 
        setTimeout(() => {setWidth(window.innerWidth)}, 50) 
        setTimeout(() => {setWidth(window.innerWidth)}, 100) 
    }
    
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        window.addEventListener("orientationchange", updateOrientation)
        return () => {
            window.removeEventListener("resize", updateDimensions)
            window.removeEventListener("orientationchange", updateOrientation)
        };
    }, []);

    useEffect(() => {
        let [ min, max ] = determinDomainMinMax(props.sensorData);

        setMinDomain(min);
        setMaxDomain(max);

        let newTicks = []
        if(props.currentGraph === "CO2"){
            while(max >= 0) {
              newTicks.push(max);
              max -= 200;
            }
        }
        else{
            while(min <= max) {
                newTicks.push(min);
                min += 5;
            }
        }
        
        setTicksYSet(newTicks.reverse())
    }, [props])

    const getXTicks = () => { // TODO min max
        // 1 den === na sekoi 2h (vkupno 13)
        // 3 dena === na sekoi 6h (vkupno 13) + datum na 00:00 (osven na prviot i posledniot)
        // 7 dena === na sekoi 12h (vkupno 15) + datum na 00:00 (osven na prviot i posledniot)
  
        let addAmount = 0;
        if (props.interval === 1) addAmount = 2; // 2h
        if (props.interval === 3) addAmount = 6; // 2h
        if (props.interval === 7) addAmount = 12; // 2h
  
        let ticks = [];
        let pocetenDatum = (new Date(new Date().setDate(new Date().getDate()- (props.interval - 1))))
        let cistDatum = new Date(pocetenDatum.toDateString())
        
        for(let i = 0; i < 13 + (props.interval === 7 ? 2 : 0); i++)
          ticks.push(cistDatum.setHours(cistDatum.getHours() + (i !== 0 ? addAmount : 0)))
  
          return ticks;
      }
  
      const verticalTicksF = () => {
        let ticks = getXTicks()
  
        if(props.interval === 3) return [ticks[4], ticks[8]]
        if(props.interval === 7) return [ticks[2], ticks[4], ticks[6], ticks[8], ticks[10], ticks[12]]
  
        return []
      }
    
      const determinDomainMinMax = (data) => {
        if (data === undefined || data.length === 0)
            return props.currentGraph === "CO2" ? [0, 1000] : [0, 50]
            
        let min = 0;
        let max = 0;
        // Се однесува или на график за CO2 или за Температура
        
        if(props.currentGraph === "CO2"){
            data.forEach((podatok) => {
                if(Number(podatok.valueY) > max)
                    max = Number(podatok.valueY)
            })
            max = max + (max % 200 >= 150 ? (400 - max % 200) : (200 - max % 200))

            if(max <= Number(props.pragZolt))
                max = Number(props.pragZolt) - Number(props.pragZolt) % 200 + 200;
        }
        else{
            data.forEach((podatok) => {
                if(Number(podatok.valueY2) > max)
                    max = Number(podatok.valueY2)
            })

            let restMax = parseInt(max) % 5;
            max = restMax >= 3 ? (parseInt(max) - restMax) + 10 : parseInt(max) - restMax + 5
            
        }

        return [min, max];
      }
  
      const determinXDomainMinMax = () => {
        const xDomain = getXTicks()
        
        return [xDomain[0], xDomain[xDomain.length - 1]]
      }
  
      const getGraphTitleDate = () => {
        // format [godina, mesec, den]
        let datum = (new Date()).toISOString().split('T')[0].split('-')
        let pocetenDatum = (new Date(new Date().setDate(new Date().getDate()- (props.interval - 1)))).toISOString().split('T')[0].split('-')
  
        // se bara analiza za denes
        if (props.interval === 1)
          return pocetenDatum[2] + "." + pocetenDatum[1] + "." + pocetenDatum[0];
        // razlicna godina
        else if (pocetenDatum[0] !== datum[0])
          return pocetenDatum[2] + "." + pocetenDatum[1] + "." + pocetenDatum[0] + " - " + datum[2] + "." + datum[1] + "." + datum[0];
        else
          return pocetenDatum[2] + "." + pocetenDatum[1] + " - " + datum[2] + "." + datum[1];
      }
  
      const CustomTooltip = (props2) => {
        if (props2 && props2.payload && props2.payload.length > 0) {
            if(props.currentGraph === "CO2" && isNaN(props2.payload[0].payload.valueY))
                return <React.Fragment></React.Fragment>
            if(props.currentGraph === "T" && isNaN(props2.payload[0].payload.valueY2))
                return <React.Fragment></React.Fragment>

            return <>
                <div className={classes.tooltip}>
                    {props.currentGraph === "CO2" && <p>CO<sub>2</sub>:  {props2.payload[0].payload.valueY + " ppm"} </p>}
                    {props.currentGraph === "T"   && <p>{translations?.devices?.temperature}:  {parseFloat(props2.payload[0].payload.valueY2).toFixed(1) + " °C"} </p>}
                <p>{translations?.data?.logMax}: {formatDatumVremeTS(props2.payload[0].payload.Time, translations?.data?.day)} </p>
                </div>
            </>
        }
        return null;
      }
      
    const determineAspectRatio = () => {
        let ratioArray = []

        //        w > 1.5  1.4  1.3   1.2  1.1   1.0  0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3
        ratioArray = [2.8, 2.6, 2.4, 2.15, 1.9,  1.7, 1.5, 3.0, 2.6, 2.4, 2.1, 1.7, 1.4]

        if(props.type === "wide"){
            //        w > 1.5  1.4  1.3   1.2  1.1   1.0  0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3
            ratioArray = [3.5, 3.4, 3.3,  3.2, 3.2,  3.1, 3.0, 3.0, 2.4, 2.2, 2.0, 1.7, 1.4]
        }
        
    
        if (width < 1600 && width >= 300)
            return ratioArray[(15 - parseInt(width / 100)) % 15];
        if (width >= 1600 && width < 1950)
            return props.type === "wide" ? 4 : 2.4;
        if (width >= 1950)
            return 2.2;

        return 1
    }

    // zemeno od reports
    const getCO2Header = (ppms = false) => {
        return <React.Fragment>
                {props.currentGraph === "CO2" && <div style={{color : "#2974ab"}}>CO<sub><small>2</small></sub> {ppms && <small style={{fontWeight : "normal"}}>[ppm]</small>}</div>}
                {props.currentGraph === "T"   && <div style={{color : "#5ca9e0"}}>T <span style={{fontWeight : "normal"}}>[°C]</span></div>}
            </React.Fragment>;
    }

    if(props.interval === undefined || props.pragZolt === undefined || props.pragZolt === undefined || props.sensorData === undefined)
        return <React.Fragment></React.Fragment>

    return (
        <React.Fragment>
            <div>
                <div className={classes.compartment}></div>
                <div className={classes.compartment} style={{textAlign : "center", position : "relative", top : "-10px"}}>
                    <h2 className={classes.graphTitle}>{getCO2Header(true)}</h2></div>
                <div className={classes.compartment + " " + classes.dates}>{getGraphTitleDate()}</div>
            </div>
            <ResponsiveContainer aspect={determineAspectRatio()} >
            <LineChart data={props.sensorData} margin={{ top: 15, left: 10, bottom: 30, right : 10 }} >
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={<CustomTooltip />} />
                <XAxis type='number' dataKey='Time'
                    domain={determinXDomainMinMax()} 
                    ticks={getXTicks()}
                    tick={<CustomizedAxisTick interval={props.interval} width={width} />}
                    padding={{right: 15}} 
                    allowDataOverflow={true} 
                    interval={0}
                />
                <YAxis ticks={ticksYSet} domain={[minDomain, maxDomain]} />
                <Line dataKey={(ev) => props.currentGraph === "CO2" ? ev.valueY : ev.valueY2} 
                    stroke={props.currentGraph === "CO2" ? "#2974ab" : "#5ca9e0"} fill="#213a80" dot={false} strokeWidth={2} />
                {/* Pomosni linii */}
                {props.currentGraph === "CO2" && props.pragCrven < maxDomain && <ReferenceLine y={props.pragCrven} stroke="red" strokeDasharray="0" key={10} />}
                {props.currentGraph === "CO2" && props.pragZolt < maxDomain && <ReferenceLine y={props.pragZolt} stroke="orange" strokeDasharray="0" key={11} /> }
                {verticalTicksF().map((vTick, index) => { return <ReferenceLine x={vTick} stroke="black" strokeDasharray="3 7" key={index} /> })}
            </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    )
}

const CustomizedAxisTick = (props) => {
    const {x, y, payload} = props;

    let unixTimestamp = payload.value;
    let xLabel = ""
    let datum = false;
    let show = true

    // sekojdnevna
    xLabel = new Date(unixTimestamp).toLocaleTimeString("de-DE").slice(0, -3) + "h"
        
    let edges = []
    // i == -1 go opfakja utresniot datum, drugite i-ovci gi opfakjaat prethodnite datumi
    for (let i = -1; i < props.interval; i++){
        // parsirat datum i go prajt vo timestamp, dobro za sporedba so unixTimestamp sto idet kako input  
        let interStepEdge = (new Date(new Date().setDate(new Date().getDate() - i ))).toLocaleDateString('de-DE').split('.') // den mesec godina
        const edge = new Date(interStepEdge[2], Number(interStepEdge[1]) - 1, interStepEdge[0]).getTime()  // timestamp
        edges.push(edge) // tuka gi imame site timestamps na datumite od grafikot
    }

    // pravi pocetni labeli, kako i cisti datumi
    edges.forEach((edge) => {
        if (unixTimestamp === edges[0])
            xLabel = "24:00h" // kraen timestamp
        else if (unixTimestamp === edges[edges.length - 1])
            xLabel = "00:00h" // poceten timestamp
        else if (unixTimestamp === edge){
            // za sekoja druga timestamp vidi dali e do nekoj edge, ako e togas taa timestamp e datum
            xLabel = new Date(unixTimestamp).toLocaleString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", }).substring(0,5);
            datum = true
        }
    })

    if(props.interval === 1){
        if((props.width < 1100 && props.width >= 900) || (props.width < 800 && props.width >= 450))
            if(["02:00h", "06:00h", "10:00h", "14:00h", "18:00h", "22:00h"].indexOf(xLabel) !== -1) show = false
        if(props.width < 450)
            if(["02:00h", "04:00h", "06:00h", "10:00h", "12:00h", "14:00h", "18:00h", "20:00h", "22:00h"].indexOf(xLabel) !== -1) show = false
    }
    else if (props.interval === 3){
        if((props.width < 1100 && props.width >= 900) || (props.width < 800 && props.width >= 450))
            if(["06:00h", "18:00h"].indexOf(xLabel) !== -1) show = false
        if(props.width < 450)
            if(["06:00h", "12:00h", "18:00h"].indexOf(xLabel) !== -1) show = false
    }
    else if (props.interval === 7){
        if (props.width < 1200 && props.width >= 500)
            if(["12:00h"].indexOf(xLabel) !== -1) show = false
        if (props.width < 500){
            if (unixTimestamp !== edges[2] && unixTimestamp !== edges[5] && ["00:00h", "24:00h"].indexOf(xLabel) === -1)
                show = false
        }
    }

    return (
        <React.Fragment>
            {show && (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} fill="#666">
                        {datum ? 
                            <tspan textAnchor="middle" x="0" fontWeight="bold" stroke="var(--blue)">{xLabel}</tspan> :
                            <tspan textAnchor="middle" x="0">{xLabel}</tspan>
                        }
                    </text>
                </g>
            )}
        </React.Fragment>
    );
}