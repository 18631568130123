import { Route, RouteProps } from 'react-router-dom';
import { Wrapper } from './wrapper';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps) => {

  return (
    <Route
      {...rest}
      render={(routeProps) =>
          <Wrapper>
            <Component {...routeProps} />
          </Wrapper>
      }
    />
  );
};
