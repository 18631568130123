import React, { useState, useContext, useEffect } from "react";
import { AccessContext } from "./access";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import "./checkbox.css";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  item: {
    borderBottom: "1px solid #f79420",
    padding: "3px",
    margin: "5px",
    borderRadius: "5px",
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    height: "1.4em",
  },
  selectedSpan: {
    width: "2%",
    float: "left",
    backgroundColor: "rgb(247,148,32)",
    height: "100%",
  },
  unselectedSpan: {
    width: "2%",
    float: "left",
    backgroundColor: "white",
    height: "100%",
  },
  emptySpan: {
    width: "3%",
    float: "left",
    backgroundColor: "white",
    height: "100%",
  },
  nameSpan: {
    width: "65%",
    float: "left",
    height: "100%",
    lineHeight: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    fontSize: "1.2em",
  },
  nameSpanUred: {
    width: "95%",
    float: "left",
    height: "100%",
    lineHeight: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    fontSize: "1.2em",
  },
  checkSpan: {
    width: "15%",
    float: "left",
    height: "100%",
  },
  moreSpan: {
    width: "15%",
    float: "left",
    height: "100%",
  },
  tooltip: {
    backgroundColor: "white",
    // borderLeft: "1px solid #213a80",
    borderBottom: "1px solid #213a80",
    // borderRight: "1px solid #213a80",
    color: "#213a80",
    fontSize: "0.8em",
  },
  tooltipParagraph: {
    textAlign: "center",
    marginBottom: "0px",
    marginTop: "0px",
  },
}));

const Item = (props) => {
  const classes = useStyles();
  const { handleCheck, handleSelected } = useContext(AccessContext);
  const { type, id, name } = props;
  const [selected, setSelected] = useState(props.selected);
  const [checked, setChecked] = useState(props.checked);
  const [visible, setVisible] = useState(props.visible);
  const [containsSelected, setContainsSelected] = useState(props.containsSelected)

  useEffect(() => {
    setVisible(props.visible);
    setChecked(props.checked);
    setSelected(props.selected);
    setContainsSelected(props.containsSelected)
  }, [props.selected, props.visible, props.checked, props.containsSelected]);

  if (visible) {
    return (
      <div key={1} className={classes.item}>
        <span
          className={selected ? classes.selectedSpan : classes.unselectedSpan}
        ></span>
        <span className={classes.emptySpan}></span>
        
        {type === "ured" ? (
          <Tooltip
            title={
              <p className={classes.tooltipParagraph}>
                {name.length > 21 ? `${name}` : ""}
                {name.length > 21 ? <br /> : ""}
                {props.serialNumber}
              </p>
            }
            placement="bottom-end"
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <span className={classes.nameSpanUred}>
              {name.length > 21 ? name.slice(0, 19) + "..." : name}
            </span>
          </Tooltip>
          ) : name.length > 16 ? (
          <Tooltip
            title={name}
            placement="bottom-end"
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <span className={classes.nameSpan}>
              {name.slice(0, 14) + "..."}
            </span>
          </Tooltip>
        ) : (
          <span className={classes.nameSpan}>{name}</span>
        )}

        {type !== "ured" && (
          <span className={classes.checkSpan}>
            <label className={"checkbox bounce"}>
              <input
                type="checkbox"
                onChange={(event) => {
                  handleCheck(type, id, checked);
                  setChecked(!checked);
                }}
                checked={checked}
              />

              {containsSelected && !checked && (
                <svg viewBox="0 0 21 21" style={{strokeWidth:"1"}}>
                  {/* <line x1="6" y1="19" x2="19" y2="6"  /> */}
                  <line x1="11" y1="19" x2="19" y2="11" />
                  <line x1="16" y1="19" x2="19" y2="16"/>
                </svg>
              )}

              {checked && (
                <svg viewBox="0 0 21 21">
                  <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                </svg>
              )}
            </label>
          </span>
        )}
        {type !== "ured" && (
          <span className={classes.moreSpan}>
            <MenuIcon
              onClick={() => {
                handleSelected(type, id, selected);
                setSelected(!selected);
                setVisible(true);
              }}
            ></MenuIcon>
          </span>
        )}
      </div>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export default Item;
