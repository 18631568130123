import React, {useEffect} from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import MaterialTable from "material-table";
import { useContext, useState } from 'react';
import { VisibilityOffOutlined, Visibility, WifiOff, Wifi } from '@material-ui/icons';
import ErrorPrivilegesPath from '../auth/error-privileges';
import {DialogContext, TranslationContext} from './../../App';
import RefreshIcon from '@material-ui/icons/Refresh';
import './../styles/globalClasses.css';
import {formatDatum, formatDatumVreme} from './../formatDates';
import {useHistory} from 'react-router-dom';

const BATTERY_CHARGING = 950; // == 4.63V (znacit se polnit)
const WIFI_HELTH = 0.8

function getTemperature(rowData) {
    if(rowData.Tem === null)
        return <div>&#8211;</div>
    return rowData.Tem
}

function getCO2Header() {
    return <div>
            CO<sub>2 </sub><span style={{fontSize : "0.8em"}}>&nbsp;[ppm]</span> 
        </div>;
}

export function getBatteryStatusAndIcon(rowData, chargingTranslations, needToChargeTranslations) {
    if(rowData === undefined)
        return <div>&#8211;</div>
    if (rowData.battery === undefined || Number(rowData.battery) === 0)
        return <div>&#8211;</div>
    
    if (Number(rowData.battery) < Number(rowData.pragBaterija))
        return <><span style={{color: 'red'}}><b>{needToChargeTranslations}</b></span></>;
    else if(Number(rowData.battery) > BATTERY_CHARGING)
        return <><span style={{color: 'blue'}}><b>{chargingTranslations}</b></span></>;
    else 
        return <><span style={{color: 'green'}}><b>OK</b></span></>;
}

function getCO2StatusAndIcon(rowData) {
    if (rowData.pragCrven === undefined)
        return '–';
    if (Number(rowData.CO2) === 0)
        return '–';

    if (rowData.CO2 >= rowData.pragCrven)
        return <><span style={{color: 'red'}}><b>{rowData.CO2}</b></span></>;
    else if (rowData.CO2 < rowData.pragCrven && rowData.CO2 >= rowData.pragZolt)
        return <><span style={{color: 'orange'}}><b>{rowData.CO2}</b></span></>;
    else if (rowData.CO2 < rowData.pragZolt && rowData.CO2 > 350)
        return <><span style={{color: 'green'}}><b>{rowData.CO2 }</b></span></>;
    
    return '–';
}

export const Devices = () =>  {
    const [redirect, setRedirect] = useState({state : false, status : 401})
    const { translations } = useContext(TranslationContext);
    const history = useHistory();
    const {setSuccessZero, apiPath, selectedModule, setSelectedModule, refreshState } = useContext(DialogContext)
    const [firstRender, setFirstRender] = useState(false)
    const [ tableData, setTableData ] = useState([]);

    const fetchDevicesState = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${apiPath}deviceStateMultiple.php?productID=4700`, { headers: { Authorization: `Bearer ${token}` } });

            if(response.data.success){
                setFirstRender(true);
                let preparedData = [];

                response.data.data.forEach((item) => {
                    let itemPrepared = {
                        serialNumber: item.serialNumber,
                        lokacija: item.zgradaName + ' ' + item.mestoName + ' ' + item.deviceName,
                        isvisible: Number(item.isvisible),
                        CO2: Number(item.CO2),
                        battery: Number(item.battery),
                        Tem : item.Tem === null ? null : Number(item.Tem),
                        lastUpdate: formatDatumVreme(item.lastUpdate, translations?.data?.day),
                        pragCrven: Number(item.pragCrven),
                        pragZolt : Number(item.pragZolt),
                        pragBaterija : Number(item.pragBaterija),
                        contractEndDate: formatDatum(item.contractEndDate, true),
                        wifi: Number(item.wifi),
                    }
                    preparedData.push(itemPrepared)
                })
                setTableData(preparedData)
            }
            else{
                // TO DO
                if(response.data.status === 401 || response.data.status === 403) // unauthorized
                    setRedirect({state : true, status : response.data.status})
                else{
                    if(response.data.message === "noDevicesToShow")
                        setFirstRender(true)
                    setSuccessZero({state : true, name : translations?.devices?.errorFetchingDevices, text : translations?.backend[response.data.message]});
                }
            }

            return response.data.data;
        } catch (err) {
        }
    }

    const { refetch: refetchDevicesState } = useQuery('devices-state', fetchDevicesState);
    
    useEffect(() => {
        if(selectedModule !== "/devices")
          setSelectedModule("/devices")
    }, [])

    if(!firstRender && redirect.state === false)
        return <React.Fragment></React.Fragment>

    if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
        return <React.Fragment></React.Fragment>

    if(redirect.state){
        // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
        if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
            history.go(0);
        else
            return <ErrorPrivilegesPath  status={redirect.status} />
    }

    return(
        <>
            <div className={"tableFS"}>
                <MaterialTable
                    title={translations?.devices?.devices}
                    options={{
                        padding: "dense",
                        headerStyle : {padding : "3px"},
                        actionsCellStyle : {paddingLeft : "15px", paddingRight : "15px"},
                    }}
                    localization={{
                        header: { actions: translations?.core?.titleState },
                        pagination : {
                            firstTooltip : translations?.core?.firstPage,
                            lastTooltip : translations?.core?.lastPage,
                            nextTooltip : translations?.core?.nextPage,
                            previousTooltip : translations?.core?.previousPage,
                            labelRowsSelect: translations?.core?.rows,
                            labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
                        },
                        body : {
                            emptyDataSourceMessage : translations?.core?.emptyTable
                        },
                        toolbar: {
                            searchPlaceholder: translations?.core?.search,
                            searchTooltip : translations?.core?.search
                        },
                        }}
                    style={{fontSize : "0.85em"}}
                    columns={[
                        {   title: translations?.devices?.serial, field: 'serialNumber', width: '12%', cellStyle : {paddingLeft : "20px", paddingRight : "5px"}, headerStyle : {paddingLeft : "20px"}},
                        {   title: translations?.devices?.location,  field: 'lokacija'},
                        {   title:  getCO2Header(), field: 'CO2',  type: 'numeric', headerStyle : {whiteSpace : "nowrap"},
                            render: (rowData) => getCO2StatusAndIcon(rowData), width: '8%'},
                        {   title: translations?.devices?.battery, field: 'battery', type: 'numeric',    
                            render: (rowData) => getBatteryStatusAndIcon(rowData, translations?.data?.charging, translations?.data?.needToCharge), width: '8%' ,
                            align : "right"},
                        {   title: translations?.devices?.temperature + " [°C]", field: 'Tem', type: 'numeric',
                            render: (rowData) => getTemperature(rowData)},
                        {   title: translations?.devices?.lastUpdate,  field: 'lastUpdate',  type: 'numeric',    width: '12%' },
                        {   title: translations?.devices?.lastUpdate,  field: 'lastUpdate',  type: 'numeric'},
                        {   title: translations?.devices?.redLimit,  field: 'pragCrven', type: 'numeric', width: '8%' },
                        {   title: translations?.companies?.contractEnding, field: 'contractEndDate', type: 'numeric', width: '10%' },
                    ]}
                    data={tableData}
                    actions={[
                        (rowData) => ({
                            icon:  rowData.isvisible === 1 ? Visibility : VisibilityOffOutlined,
                            tooltip: rowData.isvisible === 1 ? translations?.devices?.deviceVisible : translations?.devices?.deviceInvisible,
                        }),
                            (rowData) => ({
                            icon: rowData.wifi > WIFI_HELTH ? Wifi : WifiOff,
                            tooltip: rowData.wifi > WIFI_HELTH ? translations?.devices?.deviceWiFiGood : translations?.devices?.deviceWiFiBad,
                        }),
                        {
                            icon: RefreshIcon,
                            tooltip: translations?.core?.refresh,
                            isFreeAction: true,
                            onClick: () => refetchDevicesState(),
                        }
                    ]}
                />
            </div>
        </>
    )
}