import React, { createContext, useContext, useEffect, useState } from "react";
import ErrorPrivilegesPath from "../auth/error-privileges";
import AccessChild from "./access-child";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { DialogContext, TranslationContext } from '../../App';
import './../styles/globalClasses.css';
import {useHistory} from 'react-router-dom';

export const AccessContext = createContext({});

const useStyles = makeStyles((theme) => ({
  accessBigContainer: {
    margin: "auto",
    marginTop: "50px",
    width: "1100px",
    marginBottom : "100px",
    boxSizing: "border-box",
    [theme.breakpoints.down(1151)]: {
      width : "550px"
    },
    [theme.breakpoints.down(601)]: {
      width : "275px"
    },
  },
  noDevices : {
    fontWeight : "bold",
    textAlign : "center",
    marginTop : "30px",
  }
}));

const Access = (props) => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const {setSuccessZero, apiPath, refreshState} = useContext(DialogContext)
  const history = useHistory();
  const {translations} = useContext(TranslationContext)
  const classes = useStyles();
  const [data, setData] = useState({});
  const [path, setPath] = useState({ productID: 0, zgradaID: 0, mestoID: 0 });
  const [userID, setUserID] = useState(props.id)
  const [noDevices, setNoDevices] = useState(false)

  useEffect(() => {
    let timer1 = setTimeout(() => {
      response.refetch();
    }, 1);

    setUserID(props.id);

    return () => {
      clearTimeout(timer1);
    };
  }, [props.id, userID])


  const response = useQuery("user-access", async () => {
    try {
      const token = localStorage.getItem("token");
      
      const response = await axios.get(
        `${apiPath}fetchUserAccess.php?user_id=${props.id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.data.success) {
        if (response.data !== undefined) {
          //kodot vo prodolzenie na if-ot obezbeduva zapamtuvanje na selektrirana pateka pred kliknuvanje na checkbox
          response.data.allowed.forEach((singleProduct, indexProduct) => {
            if (singleProduct.productID === path.productID)
              response.data.allowed[indexProduct].selected = true;
            singleProduct.dataP.forEach((singleZgrada, indexZgrada) => {
              if (singleZgrada.zgradaID === path.zgradaID)
                response.data.allowed[indexProduct].dataP[
                  indexZgrada
                ].selected = true;
              singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                if (singleMesto.mestoID === path.mestoID)
                  response.data.allowed[indexProduct].dataP[indexZgrada].dataZ[
                    indexMesto
                  ].selected = true;
              });
            });
          });

          setData(response.data.allowed);

          if (response.data.allowed.length === 0)
            setNoDevices(true);
        }
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403)// unauthorized
        setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingAccess, text : translations?.backend[response.data.message]});
      }

      return response.data.users;
    } catch (err) {
    }
  });

  const mutation = useMutation((accessData) => {
    const token = localStorage.getItem("token");
    
    if (accessData.add) {
      return axios.put(
        `${apiPath}updateUserAccessAdd.php`,
        { ...accessData, user_id: userID },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } else {
      return axios.put(
        `${apiPath}updateUserAccessRemove.php`,
        { ...accessData, user_id: userID },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    }
  });

  const updateUserAccess = (accessData) => {
    // setUserUpdating(true);
    mutation.mutate(accessData, {
      onError: (error) => {
      },
      onSuccess: (dataReturned) => {
        if (dataReturned.data.success)
          // na uspesen Add ili Remove napravi refetch
          response.refetch();
        else if (dataReturned.data.success === 0){
          if(dataReturned.data.status === 401 || dataReturned.data.status === 403) // unauthorized
            setRedirect({state : true, status : dataReturned.data.status})
          // na neuspesen Remove vrati checkiran box vo prethodna sostojba (checked)
          else{
            let newData = [...data];
            if (accessData.add === false) {
              data.forEach((singleProduct, indexProduct) => {
                if(singleProduct.productID === accessData.productID && accessData.zgradaID === undefined){ // ako se raboti za kliknat produkt
                  // kaj soodvetniot produkt
                  newData[indexProduct].checked = true;
                  if(dataReturned.data.message === "cantRemoveAccessUserAdmin") // ako e admin
                    setSuccessZero({state : true, name : translations?.access?.errorRemovingAccessAdmin, text : translations?.access?.errorRemovingAccessAdminText});
                }
                if (singleProduct.selected) {
                  // cepkaj samo kaj selektiraniot produkt
                  singleProduct.dataP.forEach((singleZgrada, indexZgrada) => {
                    // se kliknalo mesto
                    if (accessData.mestoID !== undefined) {
                      if (singleZgrada.selected) {
                        // samo kaj selektiraniot produkt i selektiranata zgrada
                        singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                          if (singleMesto.mestoID === accessData.mestoID) {
                            newData[indexProduct].dataP[indexZgrada].dataZ[
                              indexMesto
                            ].checked = true;
                            if(dataReturned.data.message === "cantRemoveAccessUserAdmin") // ako e admin
                              setSuccessZero({state : true, name : translations?.access?.errorRemovingAccessAdmin, text : translations?.access?.errorRemovingAccessAdminText});
                            else // ako ne e
                              setSuccessZero({state : true, name : translations?.access?.errorRemovingPlaceAccess, text : translations?.access?.errorRemovingPlaceAccessText});
                          }
                        });
                      }
                    } else if (singleZgrada.zgradaID === accessData.zgradaID) {
                      // kaj selektiraniot produkt, samo kaj soodvetnata zgrada
                      newData[indexProduct].dataP[indexZgrada].checked = true;
                      if(dataReturned.data.message === "cantRemoveAccessUserAdmin") // ako e admin
                        setSuccessZero({state : true, name : translations?.access?.errorRemovingAccessAdmin, text : translations?.access?.errorRemovingAccessAdminText});
                      else // ako ne e
                        setSuccessZero({state : true, name : translations?.access?.errorRemovingBuildingAccess, text : translations?.access?.errorRemovingBuildingAccessText});
                    }
                  });
                }
              });
            }
            setData(newData);
            // setSuccessZero({state : true, name : translations?.locations?.errorFetchingProducts, text : translations?.backend[dataReturned.data.message]});
          }
        }
      },
    });
  };

  const handleCheck = (type, id, checked) => {
    let accessData = {};

    if (type === "produkt") {
      accessData = { ...accessData, productID: id };
    } else if (type === "zgrada") {
      data.forEach((singleProduct) => {
        if (singleProduct.selected) {
          accessData = {
            ...accessData,
            productID: singleProduct.productID,
            zgradaID: id,
          };
        }
      });
    } else if (type === "mesto") {
      data.forEach((singleProduct) => {
        if (singleProduct.selected) {
          singleProduct.dataP.forEach((singleZgrada) => {
            if (singleZgrada.selected) {
              accessData = {
                ...accessData,
                productID: singleProduct.productID,
                zgradaID: singleZgrada.zgradaID,
                mestoID: id,
              };
            }
          });
        }
      });
    }

    // bilo checked, znacit sega e unchecked, znacit remove
    if (checked) {
      const newData = [...data];
      data.forEach((singleProduct, indexProduct) => {
        // ako bil unchekiran produkt
        if (type === "produkt"){
          if (singleProduct.productID === id)
            newData[indexProduct].checked = false // smeni mu ja sostojbata
        }
        // ako ne e produkt
        else if (singleProduct.selected) {
          // menuvame samo kaj selektiraniot produkt
          singleProduct.dataP.forEach((singleZgrada, indexZgrada) => {
            // se kliknalo mesto
            if (type === "mesto") {
              if (singleZgrada.selected) {
                // ako se raboti za mesto menuvame samo kaj selektiranata zgrada
                singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                  if (singleMesto.mestoID === accessData.mestoID) {
                    // selektiran produkt i zgrada so tocno mestoID
                    newData[indexProduct].dataP[indexZgrada].dataZ[
                      indexMesto
                    ].checked = false;
                  }
                });
              }
            } else if (type === "zgrada" && singleZgrada.zgradaID === accessData.zgradaID) {
              // selektiran produkt so tocno zgradaID
              newData[indexProduct].dataP[indexZgrada].checked = false;
            }
          });
        }
      });
      
      setData(newData);
      updateUserAccess({ add: false, ...accessData });
    } else {
      updateUserAccess({ add: true, ...accessData });
    }
  };

  const handleSelected = (type, id, selected) => {
    if (type === "produkt") {
      const newData = [...data];
      const newPath = { ...path };

      data.forEach((singleProduct, indexProduct) => {
        if (singleProduct.productID === id) {
          newData[indexProduct].selected = !selected; // collapse i expand voedno gi opfakjat
          selected ? (newPath.productID = 0) : (newPath.productID = id);
        }
        if (singleProduct.selected && singleProduct.productID !== id) {
          newData[indexProduct].selected = false;
          newPath.zgradaID = 0;
          newPath.mestoID = 0;

          singleProduct.dataP.forEach((singleZgrada, indexZgrada) => {
            if (singleZgrada.selected) {
              newData[indexProduct].dataP[indexZgrada].selected = false;
              singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                if (singleMesto.selected)
                  newData[indexProduct].dataP[indexZgrada].dataZ[
                    indexMesto
                  ].selected = false;
              });
            }
          });
        }
      });

      setData(newData);
      setPath(newPath);
    } else if (type === "zgrada") {
      const newData = [...data];
      const newPath = { ...path };
      data.forEach((singleProduct, indexProduct) => {
        if (singleProduct.selected) {
          singleProduct.dataP.forEach((singleZgrada, indexZgrada) => {
            if (singleZgrada.zgradaID === id) {
              newData[indexProduct].dataP[indexZgrada].selected = !selected; // collapse i expand voedno gi opfakjat
              selected ? (newPath.zgradaID = 0) : (newPath.zgradaID = id);
            }
            if (singleZgrada.selected && singleZgrada.zgradaID !== id) {
              newData[indexProduct].dataP[indexZgrada].selected = false;
              newPath.mestoID = 0;

              singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                if (singleMesto.selected)
                  newData[indexProduct].dataP[indexZgrada].dataZ[
                    indexMesto
                  ].selected = false;
              });
            }
          });
        }
      });
      setData(newData);
      setPath(newPath);
    } else if (type === "mesto") {
      const newData = [...data];
      data.forEach((singleProduct, indexProduct) => {
        if (singleProduct.selected) {
          singleProduct.dataP.forEach((singleZgrada, indexZgrada) => {
            if (singleZgrada.selected) {
              singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                if (singleMesto.mestoID === id) {
                  newData[indexProduct].dataP[indexZgrada].dataZ[
                    indexMesto
                  ].selected = !selected; // collapse i expand voedno gi opfakjat
                  selected
                    ? setPath({ ...path, mestoID: 0 })
                    : setPath({ ...path, mestoID: id });
                }
                if (singleMesto.selected && singleMesto.mestoID !== id) {
                  newData[indexProduct].dataP[indexZgrada].dataZ[
                    indexMesto
                  ].selected = false;
                }
              });
            }
          });
        }
      });
      setData(newData);
    }
  };

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>

  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <div>
      <AccessContext.Provider
        value={{
          handleCheck,
          handleSelected,
          data,
        }}
      >
        <h2 className={"editSectionGlavenNaslov"}>{translations?.access?.accessEditSection}</h2>
        {noDevices && <p className={classes.noDevices}>{translations?.access?.noDevices}</p>}
        <div className={classes.accessBigContainer}>
          <AccessChild type={"produkt"}></AccessChild>
          <AccessChild type={"zgrada"}></AccessChild>
          <AccessChild type={"mesto"}></AccessChild>
          <AccessChild type={"ured"}></AccessChild>
        </div>

      </AccessContext.Provider>
    </div>
  );
};

export default Access;
