import React, { useEffect, useState, useContext } from "react";
import {NTextField, SaveButton, EditSectionNaslov} from './../edit-elements';
import MaterialTable from "material-table";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Select from "react-select";
import CriticalAction from "../critical-action";
import './../styles/globalClasses.css';
import { TranslationContext } from "../../App";
// import {FirmwareSelection} from "./firmware-selection"
import {FirmwareSelection} from "./firmware-selection"
import {FirmwareUpload} from "./firmware-upload"

const EditSection = ({current, updateProduct, serversDropDown}) => {
    const {translations} = useContext(TranslationContext)
    const [isExpanded, setIsExpanded] = useState({description : false, servers : false, settings : false, setFirmware : false})
    const [newCurrent, setNewCurrent] = useState({});
    const [currentServer1, setCurrentServer1] = useState({});
    const [currentServer2, setCurrentServer2] = useState({});
    const [criticalAction, setCriticalAction] = useState({state : false, name : "", text : ""})
    const [criticalValue, setCriticalValue] = useState({});

    useEffect(()=>{
      setCurrentServer1({label : current.domain1, value : current.serverID1})
      setCurrentServer2({label : current.domain2, value : current.serverID2})
      setNewCurrent({...current})
    }, [current, serversDropDown])
    
    const handleSelect = (tip) => {
      let newIsExpanded = {...isExpanded}
      newIsExpanded[tip] = !newIsExpanded[tip]
      setIsExpanded(newIsExpanded)
    }
    const handleCritical = () => {
      updateProduct({...criticalValue});
      setCriticalValue({})
      setCriticalAction({state : false, name : "", text : ""})
    }  
    
    const handleEnter = ({tip, newValues, labels}) => {
    //   handleSelect(tip);  
      switch(tip){
        case "description": 
          setNewCurrent({...newCurrent, productDescription : newValues[0]})
          updateProduct({tip : tip, productDescription : newValues[0], pID : current.pID}); 
          break;
        case "servers":
          setCriticalAction({state : true, name : translations?.products?.criticalChangeServer, 
                              text : translations?.products?.criticalChangeServerText[0] + `\n\n${newCurrent.productName}\n`})
          setCriticalValue({tip : tip, server : 3, pID : current.pID, serverID1 : currentServer1.value, serverID2 : currentServer2.value})
          break;
        case "settings":
          setCriticalAction({state : true, name : translations?.products?.criticalChangeSettings, 
                              text : `${translations?.products?.criticalChangeSettingsText[0]}\n\n ${newCurrent.productName}.\n\n` +
                                      `${translations?.products?.criticalChangeSettingsText[1]}\n`})
          let dSettings = {};
          newValues.forEach((property)=> {
            dSettings[property.key] = property.value
          })
          setCriticalValue({tip : tip, pID : current.pID, defaultSettings : dSettings})
          break;
        case "setFirmware":
          if(parseInt(newValues.fID) === parseInt(current.fID)) // Napravi OTA samo ako novata firmware verzija e razlicna od prethodnata
            break;

          setCriticalAction({state : true, name : translations?.products?.criticalOTA, 
                              text : `${translations?.products?.criticalOTAText[0]}\n\n ${current.fileName}\n\n` +
                                      `${translations?.products?.criticalOTAText[1]}\n\n ${newValues.fileName} \n\n` + 
                                      `${translations?.products?.criticalOTAText[2]}`})
          setCriticalValue({tip : tip, pID : current.pID, new_fID : newValues.fID})
          break;
        default: 
            break;
      }
    }
  
    return (
      <React.Fragment>
        <h2 className={"editSectionGlavenNaslov"}>{translations?.products?.catalogEdit}</h2>
          <div className={"editSectionLeft"}>
            <EditSectionNaslov naslov={translations?.products?.productDescription} expanded={isExpanded.description} tip={"description"} handleSelect={handleSelect}/>
            {
              isExpanded.description && 
              <NTextField 
                labels={[translations?.products?.productDescription]}
                inits={[newCurrent.productDescription]}
                tip={"description"}
                multiline={[true]}
                save={true}
                handleEnter={handleEnter}
                klasa={"cellMultiline"}
                klasaButton={"buttonSave"}
              />
            }
            <EditSectionNaslov naslov={translations?.servers?.servers} expanded={isExpanded.servers} tip={"servers"} handleSelect={handleSelect}/>
            {isExpanded.servers && 
              <React.Fragment>
                <div className={"dropDownDouble"}>
                  <h3 className={"naslovMal"}>{translations?.servers?.primaryServer}</h3>
                    <Select
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      onChange={(value, actionMeta) => {
                        if (actionMeta.action === "select-option") 
                          setCurrentServer1(value)
                      }}
                      options={serversDropDown}
                      value={currentServer1}
                    />
                  </div>
                <div className={"dropDownDouble"}>
                  <h3 className={"naslovMal"}>{translations?.servers?.backupServer}</h3>
                    <Select
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      onChange={(value, actionMeta) => {
                        if (actionMeta.action === "select-option") 
                          setCurrentServer2(value)
                      }}
                      options={serversDropDown}
                      value={currentServer2}
                    />
                </div>
                <div style={{textAlign: "center"}}>
                  <SaveButton 
                    klasaButton={"buttonSave"}
                    name={translations?.core?.save}
                    handleButtonClick={() => handleEnter({tip : "servers", })}
                  />
                </div>
              </React.Fragment>
            }
          </div>
          <div className={"editSectionRight"}>  
            <EditSectionNaslov naslov={translations?.devices?.settings} expanded={isExpanded.settings} tip={"settings"} handleSelect={handleSelect}/>
            { isExpanded.settings && 
              <PropertyTableProduct pageSize={10} defaultSettings={current.defaultSettings} handleEnter={handleEnter}/>
            }
            <EditSectionNaslov naslov={translations?.products?.setFirmware} expanded={isExpanded.setFirmware} tip={"setFirmware"} handleSelect={handleSelect}/>
            { isExpanded.setFirmware &&
              <FirmwareSelection {...current} handleEnter={handleEnter} tip={"setFirmware"} />
            }
            <EditSectionNaslov naslov={translations?.products?.uploadFirmware} expanded={isExpanded.uploadFirmware} tip={"uploadFirmware"} handleSelect={handleSelect}/>
            { isExpanded.uploadFirmware &&
              <FirmwareUpload {...current} handleEnter={handleEnter} tip={"uploadFirmware"} />
            }

            {/* <EditSectionNaslov naslov={translations?.products?.firmwareTitle} expanded={isExpanded.firmware} tip={"firmware"} handleSelect={handleSelect}/>
            { isExpanded.firmware &&
              <FirmwareSelection {...current} handleEnter={handleEnter} tip={"firmware"} />
            } */}
          </div>
          {criticalAction.state && 
            <CriticalAction 
                name={criticalAction.name}
                text={criticalAction.text}
                handleYes={handleCritical} 
                handleNo={() => {
                  setNewCurrent({...current})
                  setCriticalAction({state : false, name : "", text : ""})}
                } 
            />
          }
      </React.Fragment>
    )
  }
    
export default EditSection;  

export const PropertyTableProduct = ({defaultSettings, handleEnter, pageSize}) => {
  const {translations} = useContext(TranslationContext)
  const [currentProperty, setCurrentProperty] = useState({})
  const [properties, setProperties] = useState([])
  const [settingsActionType, setSettingsActionType] = useState({edit : false, add : false})

  useEffect(()=>{
    setCurrentProperty({visible : false, key : "", value : ""})
    if (typeof(defaultSettings) !== "undefined" && defaultSettings !== null){
      let keys = Object.keys(defaultSettings)
      let newProperties = [];
      keys.forEach((key) => {
        newProperties.push({key : key, value : defaultSettings[key]})
      })
      setProperties(newProperties);
    }
    if(defaultSettings === null)
      setProperties([]);
  }, [defaultSettings])
    
  const handleChangeSettings = ({tip, newValues}) => {
    let newProperties = [...properties];
    if(settingsActionType.add){
      newProperties.push({key : newValues[0], value : newValues[1]})
    }
    if(settingsActionType.edit){
      if(typeof(newProperties[newValues[0]]) === "undefined")// se preimenuval key
        newProperties.splice(newProperties.findIndex((k) => k.key === currentProperty.key), 1) // brisit item
      newProperties.push({key : newValues[0], value : newValues[1]}) // dodavat item (ili modificirat)
      setCurrentProperty({key : newValues[0], value : newValues[1]})
    }
    setProperties(newProperties)
    setSettingsActionType({add : false, edit : false})
  }
  const handleSaveSettings = () => {
    handleEnter({tip : "settings", newValues : properties}) // go prakja celiot objekt od settings
    setSettingsActionType({add : false, edit : false})
  }

  return (
    <React.Fragment>
        <React.Fragment>
          <MaterialTable
            options={{
              tableLayout: "auto",
              padding: "dense",
              pageSize : pageSize,
              pageSizeOptions : [5, 10, 15, 20],
              actionsCellStyle : {paddingLeft : "8px"}
            }}
            localization={{
              header: { actions: translations?.core?.actions },
              pagination : {
                firstTooltip : translations?.core?.firstPage,
                lastTooltip : translations?.core?.lastPage,
                nextTooltip : translations?.core?.nextPage,
                previousTooltip : translations?.core?.previousPage,
                labelRowsSelect: translations?.core?.rows,
                labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
              },
              body : {
                emptyDataSourceMessage : translations?.core?.emptyTable
              },
              toolbar: {
                searchPlaceholder: translations?.core?.search,
                searchTooltip : translations?.core?.search},
            }}
            columns={[
              { title: translations?.devices?.property, field: "property", cellStyle : {paddingLeft : "35px"}, headerStyle : {paddingLeft : "35px"}},
              { title: translations?.devices?.value, field: "value", cellStyle : {paddingLeft : "15px"}},
            ]}
            data={properties.map((dat,) => {
              return {property : dat.key, value : dat.value}
            })}
            title={translations?.devices?.tableSettings}
            actions={[
              (rowData) => ({
                icon: DeleteOutlineIcon,
                tooltip: translations?.devices?.deleteKeyValuePair,
                onClick: (event, rowData) => {
                  let newProperties = [...properties];
                  newProperties.splice(newProperties.findIndex((k) => k.key === rowData.property), 1)
                  setProperties(newProperties);
                  setCurrentProperty({visible : false, key : rowData.property, value : rowData.value});
                  setSettingsActionType({add : false, edit : false})
                },
              }),
              (rowData) => ({
                icon: EditOutlinedIcon,
                tooltip: translations?.devices?.editKeyValuePair,
                onClick: (event, rowData) => {
                  setCurrentProperty({visible : true, key : rowData.property, value : rowData.value});
                  setSettingsActionType({add : false, edit : true})
                },
              }),
            ]}
            onRowClick={(event, rowData) => {
              setCurrentProperty({visible : true, key : rowData.property, value : rowData.value});
              setSettingsActionType({add : false, edit : true})
            }}
          />
        </React.Fragment>
        <div style={{textAlign: "center", marginTop : "20px"}}>
          {
            settingsActionType.add !== settingsActionType.edit && 
            <NTextField 
              labels={[translations?.devices?.property, translations?.devices?.value]}
              inits={settingsActionType.add ? ["", ""] :  [currentProperty.key, currentProperty.value]}
              tip={"settings"}
              autoFocus={[true, false]}
              handleEnter={handleChangeSettings}
              klasa={"cellDual"}
            />
          }
          {!settingsActionType.add &&
            <SaveButton 
              name={translations?.devices?.addNewSetting} handleButtonClick={()=> setSettingsActionType({add : true, edit : false})} klasaButton={"buttonAdd"}
            />
          }
          {!settingsActionType.add && !settingsActionType.edit &&
            <SaveButton 
              name={translations?.devices?.saveSettings} handleButtonClick={handleSaveSettings} klasaButton={"buttonSave"}
            />
          }
          {settingsActionType.add !== settingsActionType.edit &&
            <SaveButton 
              name={translations?.core?.cancel} handleButtonClick={()=> setSettingsActionType({add : false, edit : false})} klasaButton={"buttonCancel"}
            />
          }
        </div>
    </React.Fragment>
  )
}
