import React, { useContext} from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { TranslationContext } from '../../App';
import {formatDatumVreme} from './../formatDates';
import {getBatteryStatusAndIcon} from './../modules/devices.js'

const SPLIT_CARD_GRAPH = 900

const useStyles = makeStyles((theme) => ({
    deviceStateContainer: {
      width : "250px",
      marginTop : "110px",
      marginLeft : "25px",
      marginRight : "25px",
      backgroundColor: '#f5f6ff',
      padding:'10px',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius : "25px",
      display : "inline-block",
      border : "1px solid var(--blue)",
      [theme.breakpoints.down(SPLIT_CARD_GRAPH)] : {
          marginTop : "0px",
      },
    },
    deviceStateCO2Widget: {
      textAlign : "center",
      marginBottom: '15px'
    },
    circleDiv : {
      backgroundColor: '#f5f6ff',
      height: '100px',
      width: '140px',
      borderRadius: '25px',
      display: 'flex',
      textAlign: 'center',
      margin : "auto",
    },
    textInCircleDiv : {
      display: 'flex', 
      justifyContent: 'center', 
      flexGrow: 1, 
      flexDirection: 'column', 
      fontSize: '1.8em', 
    }
}));
  
export const CircleIndicator = (props) => {
    const classes = useStyles();
    const deviceState = props.deviceState
    const pragCrven = Number(props.deviceState?.pragCrven);
    const pragZolt = Number(props.deviceState?.pragZolt);
    const CO2 = Number(props.deviceState?.CO2)
    const { translations } = useContext(TranslationContext);
  
    const getCircleColor = () => {
      if(props.currentGraph === "T")
        return "#5ca9e0"

      if (CO2 < pragZolt)
        return "var(--confirm)";
      if (CO2 >= pragCrven)
        return "#d90000";
      if (CO2 >= pragZolt && CO2 < pragCrven)
        return "var(--orangeDarker)";

      return "var(--blue)"
    }
      
    const getCO2DonutBorder = () => {
      return  '12px solid ' + getCircleColor();
    }
  
    const getCardShadow = () => {
      return "0px 0px 9px 1px var(--blue)";
    }
  
    return (
        <div className={classes.deviceStateContainer} style={{boxShadow : getCardShadow()}}>
            { deviceState && deviceState.CO2 &&
            <div className={classes.deviceStateCO2Widget}>
              <div className={classes.circleDiv} style={{border : getCO2DonutBorder()}}>
                  <div className={classes.textInCircleDiv} style={{color : getCircleColor()}}>
                      <span>
                        {props.currentGraph === "CO2" ? (
                          <>
                            <span style={{fontSize: 'xx-large'}}><strong>CO</strong></span>
                            <span style={{fontSize: 'x-large'}}><strong><sub>2</sub></strong></span>
                          </>
                        ) : (
                          <>
                            <span style={{fontSize: 'xx-large'}}><strong>T</strong></span>
                          </>
                        )}
                      </span>
                      <span style={{fontSize: 'x-large'}}>
                        {props.currentGraph === "CO2" ? (
                          <>
                            <strong>{deviceState.CO2}</strong>
                            <span style={{fontSize: '0.5em', }}> &nbsp;<b>ppm</b></span>
                          </>
                        ) : (
                          <>
                            <strong>{parseFloat(deviceState.Tem).toFixed(1)}</strong>
                            <span style={{fontSize: '1em', fontWeight : "500"}}> &nbsp;°C</span>
                          </>
                        )}
                      </span>
                  </div>
              </div>
            </div>
            }
  
            { !deviceState &&
            <div className={classes.deviceStateCO2Widget}>
              <div className={classes.circleDiv} style={{border : getCO2DonutBorder()}}>
                  <div className={classes.textInCircleDiv} style={{color : getCircleColor()}}>
                        <span style={{fontSize: 'x-large'}}><strong>{translations?.reports?.select}</strong></span>
                        <span style={{fontSize: 'x-large'}}><strong>{translations?.reports?.device}</strong></span>
                  </div>
              </div>
            </div>
            }
  
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid container item md direction="row" justify="space-between" alignItems="center">
                <Grid item xs>{translations?.devices?.battery}: </Grid>
                <Grid item xl style={{display:'flex', alignItems:'center', justifyItems:'center'}}>
                  {getBatteryStatusAndIcon(deviceState, translations?.data?.charging, translations?.data?.needToCharge)}
                  {deviceState === undefined && <EmptySpaces /> }  
                </Grid>
              </Grid>
              <Grid container item md direction="row" justify="space-between" alignItems="center">
                <Grid item xs>{translations?.devices?.lastUpdate}: </Grid>
                <Grid item xl>
                  { formatDatumVreme(deviceState?.lastUpdate, translations?.data?.day) }
                  {deviceState === undefined && <EmptySpaces /> }  
                </Grid>
              </Grid>
            </Grid>
        </div>
    )
  }
  

const EmptySpaces = () => {
  return (
    <React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>
  )
}