import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { TextField } from "@material-ui/core";
import { TranslationContext, DialogContext } from "../../App";
import Select from "react-select";
import {SaveButton} from './../edit-elements'
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

export const FirmwareSelection = (props) => {
    const {translations} = useContext(TranslationContext)
    const {apiPath, setSuccessZero, setNotifyPopUp} = useContext(DialogContext)
    const [currentFirmware, setCurrentFirmware] = useState({fID : 0, pID : 0, fileName : "", version : ""})
    const [firmwares, setFirmwares] = useState([])

    useEffect(() => {
        response.refetch()
    }, [props])

    const response = useQuery("firmwareVersions", async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${apiPath}fetchFirmwareVersions.php?pID=${props.pID}`, { headers: { Authorization: `Bearer ${token}` } });

            if(response.data.success){
                if(response.data.firmwares){
                    let newFirmwares = []
                    response.data.firmwares.forEach((firmware) => {
                        newFirmwares.push({
                            label : firmware.fileName,
                            value : firmware.fID,
                            fID : firmware.fID,
                            pID : firmware.pID,
                            version : firmware.version,
                            fileName : firmware.fileName
                        })
                    })

                    let changed = 0;
                    setFirmwares(newFirmwares)
                    newFirmwares.forEach((firmware) => {
                        if(firmware.version === props.fVersion){
                            changed = 1;
                            setCurrentFirmware(firmware)
                        }
                    })
                    if(changed === 0)
                        setCurrentFirmware(newFirmwares[0])
                }
                else{
                    setFirmwares([])
                    setCurrentFirmware({fID : 0, pID : 0, fileName : "", version : "", label : "", value : ""})
                }
            }

            return response.data;
        } catch (err) {
        }
    });

    const mutation = useMutation((firmwareData) => {
        const token = localStorage.getItem("token");

        switch(firmwareData.tip){
            case "deleteFirmware":
            return axios.post(`${apiPath}deleteFirmware.php`, firmwareData, { headers: { Authorization: `Bearer ${token}` } });
          default: 
            break;
        }
      });
    
    const updateFirmwares = (firmwareData) => {
        mutation.mutate(
          { ...firmwareData },
          {
            onError: (error) => {
            },
            onSuccess: (dataReturned) => {
              if(dataReturned.data.success){
                response.refetch();
                if (firmwareData.tip === "deleteFirmware")
                    setNotifyPopUp({state : true, name : translations?.products?.entryFirmwareDeleteButNotFile, text : translations?.backend[dataReturned.data.message]})
              }
              else{
                switch(firmwareData.tip){
                  case "deleteFirmware":
                      setSuccessZero({state : true, name : translations?.products?.errorDeletingFirmware, text : translations?.backend[dataReturned.data.message]});
                      break;
                  default: 
                    break;
                }
              }
            },
          }
        );
    };
    
    return (
        <React.Fragment>
            <div className={"dropDownDouble"}>
                <h3 className={"naslovMal"}>{translations?.products?.firmwareName}</h3>
                <div className={"cellDual"}>
                    <DeleteOutlineIcon
                        style={{float : "left", position : "relative", top : "7px", left : "-8px", zIndex : "10"}}
                        onClick={() => {
                            if(currentFirmware.fID !== 0)
                                updateFirmwares({tip : "deleteFirmware", ...currentFirmware});
                        }}
                    />
                    <Select
                        maxMenuHeight={120}
                        onChange={(newValue) => { setCurrentFirmware(newValue) }}
                        options={firmwares}
                        value={currentFirmware}
                    />
                </div>
            </div>
            <div className={"dropDownDouble"}>
                <h3 className={"naslovMal"}>{translations?.products?.firmwareVersion}</h3>
                <div className={"cellDual"} style={{position: "relative", top : "-10px", left : "10px"}}>
                    <TextField 
                        label={translations?.products?.firmwareVersion}
                        value={currentFirmware.version}
                        disabled={true}
                    />
                </div>
            </div>
            <div style={{textAlign: "center"}}>
            <SaveButton 
                klasaButton={"buttonSave"}
                name={translations?.core?.update}
                handleButtonClick={() => props.handleEnter({tip : props.tip, newValues : currentFirmware})}
            />
            </div>
        </React.Fragment>
    )
}
