import React, { useEffect, useState, useContext } from "react";
import {NTextField, EditSectionNaslov} from './../edit-elements'
import Locations from "./locations";
import './../styles/globalClasses.css';
import { TranslationContext } from "../../App";

const EditSection = ({current, updateCompany, serversDropDown}) => {
    const {translations} = useContext(TranslationContext)
    const [isExpanded, setIsExpanded] = useState({name : false, mail1 : false, mail2 : false, mail3 : false, locations : false})
    const [newCurrent, setNewCurrent] = useState({});

    useEffect(()=>{
      setNewCurrent({...current})
    }, [current])
    
    const handleSelect = (tip) => {
      let newIsExpanded = {...isExpanded}
      newIsExpanded[tip] = !newIsExpanded[tip]
      setIsExpanded(newIsExpanded)
    }
    
    const handleEnter = ({tip, newValues, labels}) => {
    //   handleSelect(tip);  
      switch(tip){
        case "name": 
          setNewCurrent({...newCurrent, companyName : newValues[0]})
          updateCompany({tip : tip, companyName : newValues[0], companyID : newCurrent.companyID}); 
          break;
        case "mail1":
          setNewCurrent({...newCurrent, mail1 : newValues[0]})
          updateCompany({tip : tip, mail : newValues[0], number : 1, companyID : newCurrent.companyID})
          break;
        case "mail2":
          setNewCurrent({...newCurrent, mail2 : newValues[0]})
          updateCompany({tip : tip, mail : newValues[0], number : 2, companyID : newCurrent.companyID})
          break;
        case "mail3":
          setNewCurrent({...newCurrent, mail3 : newValues[0]})
          updateCompany({tip : tip, mail : newValues[0], number : 3, companyID : newCurrent.companyID})
          break;
        default: 
            break;
      }
    }
  
    return (
      <React.Fragment>
        <h2 className={"editSectionGlavenNaslov"}>{translations?.companies?.companiesEdit}</h2>
        <div className={"editSectionLeft"}>
          <EditSectionNaslov naslov={translations?.companies?.companyName} expanded={isExpanded.name} tip={"name"} handleSelect={handleSelect}/>
          {
            isExpanded.name && 
            <NTextField 
              labels={[translations?.companies?.companyName]}
              inits={[newCurrent.companyName]}
              tip={"name"}
              disabled={newCurrent.companyID === "100" ? [true] : [false]}
              save={true}
              allowEnter={false}
              handleEnter={handleEnter}
              klasa={"cellSingle"}
              klasaButton={"buttonSave"}
            />
          }
          <EditSectionNaslov naslov={translations?.locations?.location} expanded={isExpanded.locations} tip={"locations"} handleSelect={handleSelect}/>
          { isExpanded.locations && 
            <Locations companyID={newCurrent.companyID}  />
          }
        </div>
        <div className={"editSectionRight"}>
          <EditSectionNaslov naslov={translations?.companies?.domain1} expanded={isExpanded.mail1} tip={"mail1"} handleSelect={handleSelect}/>
          {isExpanded.mail1 && 
            <NTextField 
                labels={[translations?.companies?.domain1label]}
                inits={[newCurrent.mail1]}
                tip={"mail1"}
                save={true}
                allowEnter={false}
                handleEnter={handleEnter}
                klasa={"cellSingle"}
                klasaButton={"buttonSave"}
            />
          }
          <EditSectionNaslov naslov={translations?.companies?.domain2} expanded={isExpanded.mail2} tip={"mail2"} handleSelect={handleSelect}/>
          {isExpanded.mail2 && 
            <NTextField 
                labels={[translations?.companies?.domain2label]}
                inits={[newCurrent.mail2]}
                tip={"mail2"}
                save={true}
                allowEnter={false}
                handleEnter={handleEnter}
                klasa={"cellSingle"}
                klasaButton={"buttonSave"}
            />
          }
          <EditSectionNaslov naslov={translations?.companies?.domain3} expanded={isExpanded.mail3} tip={"mail3"} handleSelect={handleSelect}/>
          {isExpanded.mail3 && 
            <NTextField 
                labels={[translations?.companies?.domain3label]}
                inits={[newCurrent.mail3]}
                tip={"mail3"}
                save={true}
                allowEnter={false}
                handleEnter={handleEnter}
                klasa={"cellSingle"}
                klasaButton={"buttonSave"}
            />
          }
        </div>
      </React.Fragment>
    )
  }
    
export default EditSection;  