import { useHistory, useLocation } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext } from './../App';
import { DialogContext } from './../App'
import { makeStyles } from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';

const DMpikseli = 1051;
const Apikseli = 501;

const useStyles = makeStyles((theme) => ({
    meniOsnova : {
        width : "100%",
        height : "34px",
        backgroundColor : "white"
    },
    listBar : {
        listStyleType : "none",
        margin : "0",
        padding : "0",
        display : "inline-block",
    },

    navigationBarDM : {
        textAlign : "center",
        position : "relative",
        marginBottom : "20px",
        paddingTop : "1px",
        paddingBottom : "1px",
        backgroundColor : "var(--blueDarker)",
        [theme.breakpoints.down(DMpikseli)] : {
            display : "inline-block",
            position : "absolute",
            width : "150px",
            right : "0px",
            top : "60px",
            zIndex : "100",
            boxSizing : "border-box"
        }
    },
    listItemDM : {
        display : "inline-block",
        width : "100px",
        height : "30px",
        textAlign : "center",
        lineHeight : "30px",
        borderRadius : "2px",
        color : "white",
        cursor : "pointer",
        margin : "2px 1px",
        userSelect: "none",
        "&:hover" : {
            backgroundColor : "var(--orangeBright)",
          },
        [theme.breakpoints.down(DMpikseli)] : {
            width : "146px",
            boxSizing : "border-box",
            margin : "2px 2px"
        }
    },
    selectedItemDM : {
        backgroundColor : "var(--orangeDarker)",
        cursor : "default",
        [theme.breakpoints.down(DMpikseli)] : {
            width : "146px",
            boxSizing : "border-box",
            margin : "2px 2px"
        }
    },

    navigationBar : {
        textAlign : "center",
        position : "relative",
        marginBottom : "20px",
        paddingTop : "1px",
        paddingBottom : "1px",
        // borderTop : "2px solid var(--orangeBright)",
        // borderBottom : "2px solid var(--orangeDarker)",
        backgroundColor : "var(--blueDarker)",
        // backgroundColor : "rgb(35,47,62)",
        [theme.breakpoints.down(Apikseli)] : {
            display : "inline-block",
            position : "absolute",
            width : "150px",
            right : "0px",
            top : "60px",
            zIndex : "100",
            boxSizing : "border-box"
        }
    },
    listItem : {
        display : "inline-block",
        width : "100px",
        height : "30px",
        textAlign : "center",
        lineHeight : "30px",
        borderRadius : "2px",
        color : "white",
        cursor : "pointer",
        margin : "2px 1px",
        userSelect: "none",
        "&:hover" : {
            backgroundColor : "var(--orangeBright)",
          },
        [theme.breakpoints.down(Apikseli)] : {
            width : "146px",
            boxSizing : "border-box",
            margin : "2px 2px"
        }
    },

    selectedItem : {
        backgroundColor : "var(--orangeDarker)",
        cursor : "default",
        [theme.breakpoints.down(Apikseli)] : {
            width : "146px",
            boxSizing : "border-box",
            margin : "2px 2px"
        }
    },
    closeIcon : {
        fontSize : "1.2em",
        paddingLeft : "6px",
        position : "relative",
        top : "5px",
    },
    expandMenuDiv : {
        width : "100%",
        height : "40px"
    },
    expandMenuIconDiv : {
        float : "right",
        position : "relative",
        right : "5px",
        top : "2px",
        height : "30px",
        width : "130px",
        backgroundColor : "var(--blue)",
        borderRadius : "4px",
        zIndex : "100",
    },
    expandMenuIcon : {
        float : "right",
        color : "white",
        position : "relative",
        top : "3px",
        right : "3px"
    },
    menuSeparatorVertical : {
        margin : "2px 4px",
        display : "inline-block",
        color : "white",
        transform : "scaleY(1.56) translateY(-2px)",
        userSelect : "none"
    },
    menuSeparatorHorizontal : {
        margin : "auto",
        width : "90%",
        height : "1px",
        backgroundColor : "white"
    },
    mobileCurrentTab : {
        color : "white",
        height : "30px",
        lineHeight : "30px",
        paddingLeft : "8px"
    }
}));

export const Menu = () => {
    const { translations } = useContext(TranslationContext);
    const { userInfo } = useContext(DialogContext);
    const location = useLocation();
    const classes = useStyles();
    const [expandMenu, setExpandMenu] = useState(false)

    const [width, setWidth]   = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        window.addEventListener("orientationchange", updateOrientation)
        return () => {
            window.removeEventListener("resize", updateDimensions)
            window.removeEventListener("orientationchange", updateOrientation)
        };
    }, []);

    const updateDimensions = () => {setWidth(window.innerWidth)};
    const updateOrientation = () => {
        setTimeout(() => {setWidth(window.innerWidth)}, 10) 
        setTimeout(() => {setWidth(window.innerWidth)}, 50) 
        setTimeout(() => {setWidth(window.innerWidth)}, 100) 
    }
    
    if(location.pathname === '/login' || location.pathname === '/register')
        return <React.Fragment></React.Fragment>

    if(userInfo?.isadmin === "1" && userInfo?.isDMadmin === "1"){
        return (
            <React.Fragment>
                <div className={classes.meniOsnova}>
                    {(width >= DMpikseli || (expandMenu === true && width < DMpikseli)) &&
                        <nav className={classes.navigationBarDM}>
                            <ul className={classes.listBar}>
                                {width < DMpikseli &&  <MenuButtonClose DM={true} buttonText={translations?.core?.close} setExpandMenu={setExpandMenu}/>}
                                {/* Admin */}
                                <MenuButton DM={true} key={1} buttonText={translations?.core?.titleUsers} buttonLink={"/users"} setExpandMenu={setExpandMenu}></MenuButton>
                                <MenuButton DM={true} key={2} buttonText={translations?.core?.titleDevices} buttonLink={"/devices"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={true} key={3} buttonText={translations?.core?.titleReports} buttonLink={"/reports"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={true} key={4} buttonText={translations?.core?.titleAnaliza} buttonLink={"/analiza"} setExpandMenu={setExpandMenu} ></MenuButton>
                                
                                {width >= DMpikseli ? <MenuSeparatorVertical /> : <MenuSeparatorHorizontal />}

                                {/* DM Admin */}
                                <MenuButton DM={true} key={5} buttonText={translations?.core?.titleState} buttonLink={"/state"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={true} key={6} buttonText={translations?.core?.titleInventory} buttonLink={"/inventar"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={true} key={7} buttonText={translations?.core?.titleCompanies} buttonLink={"/companies"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={true} key={8} buttonText={translations?.core?.titleCatalog} buttonLink={"/catalog"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={true} key={9} buttonText={translations?.core?.titleServers} buttonLink={"/servers"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={true} key={10} buttonText={translations?.core?.titleTest} buttonLink={"/test"} setExpandMenu={setExpandMenu} ></MenuButton>
                            </ul>
                        </nav>
                    }
                    {(expandMenu === false && width < DMpikseli) &&
                        <MenuButtonExpand setExpandMenu={setExpandMenu} />
                    }
                </div>
            </React.Fragment>
        )
    }

    if(userInfo?.isadmin === "1"){
        return (
            <React.Fragment>
                <div className={classes.meniOsnova}>
                    {(width >= Apikseli || (expandMenu === true && width < Apikseli)) &&
                        <nav className={classes.navigationBar}>
                            <ul className={classes.listBar}>
                                {width < Apikseli &&  <MenuButtonClose DM={false} buttonText={translations?.core?.close} setExpandMenu={setExpandMenu}/>}

                                <MenuButton DM={false} key={1} buttonText={translations?.core?.titleUsers} buttonLink={"/users"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={false} key={2} buttonText={translations?.core?.titleDevices} buttonLink={"/devices"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={false} key={3} buttonText={translations?.core?.titleReports} buttonLink={"/reports"} setExpandMenu={setExpandMenu} ></MenuButton>
                                <MenuButton DM={false} key={4} buttonText={translations?.core?.titleAnaliza} buttonLink={"/analiza"} setExpandMenu={setExpandMenu} ></MenuButton>
                            </ul>
                        </nav>
                    }
                    {(expandMenu === false && width < Apikseli) &&
                        <MenuButtonExpand setExpandMenu={setExpandMenu} />
                    }
                </div>
            </React.Fragment>
        )
    }
    
    return <React.Fragment></React.Fragment>
}

const MenuButton = (props) => {
    const {buttonText, buttonLink, setExpandMenu, DM} = {...props};
    const {selectedModule, setSelectedModule} = useContext(DialogContext)
    const history = useHistory();
    const classes = useStyles();

    return (
        <React.Fragment>
            <li onClick={() => {
                if(selectedModule !== buttonLink){
                    setSelectedModule(buttonLink)
                    history.push(buttonLink)
                    setExpandMenu(false)
                }
            }} 
                className={
                    DM ? 
                    classes.listItemDM + ' ' + (selectedModule === buttonLink ? classes.selectedItemDM : "") :
                    classes.listItem + ' ' + (selectedModule === buttonLink ? classes.selectedItem : "")

                } 
                style={{backgroundColor : selectedModule === buttonLink && "var(--orangeDarker)"}}
            >
                {buttonText}
            </li>
        </React.Fragment>
    )
}

const MenuButtonClose = (props) => {
    const classes = useStyles();
    const {buttonText, DM} = {...props};

    return (
        <React.Fragment>
            <li onClick={() => {props.setExpandMenu(false)}} 
                className={DM ? classes.listItemDM : classes.listItem} 
                style={{backgroundColor : "var(--orangeBright)"}}
            >
                {buttonText}<CloseIcon className={classes.closeIcon}/>
            </li>
        </React.Fragment>
    )
}

const MenuButtonExpand = (props) => {
    const classes = useStyles();
    const {selectedModule} = useContext(DialogContext)
    const { translations, langCode } = useContext(TranslationContext);
    const [currentTab, setCurrentTab] = useState('')

    useEffect(() => {
        if(selectedModule === "/reports"){
            if(langCode === "mk") setCurrentTab("Извештаи")
            if(langCode === "en") setCurrentTab("Reports")
            if(langCode === "sr") setCurrentTab("Извештаји")
            // setCurrentTab(langCode === "mk" ? "Извештаи" : "Reports")
        }
        else if(selectedModule === "/inventar")
            setCurrentTab(translations?.core?.titleInventory)
        else{
            let keyTranslations = "title" + selectedModule.charAt(1).toUpperCase() + selectedModule.slice(2)
            setCurrentTab(translations?.core[keyTranslations])
        }

    }, [selectedModule])

    return (
        <React.Fragment>
            <div className={classes.expandMenuDiv}>
                <div className={classes.expandMenuIconDiv}>
                    <span className={classes.mobileCurrentTab}>{currentTab}</span>
                    <MenuIcon className={classes.expandMenuIcon} onClick={() => props.setExpandMenu(true)} />
                </div>
            </div>
        </React.Fragment>
    )
}

const MenuSeparatorVertical = () => {
    const classes = useStyles();

    return (
        <div className={classes.menuSeparatorVertical}>|</div>
    )
}

const MenuSeparatorHorizontal = () => {
    const classes = useStyles();

    return (
        <div className={classes.menuSeparatorHorizontal}></div>
    )
}