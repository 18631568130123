import { useContext, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
  Container,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
} from '@material-ui/icons';
import { DialogContext } from '../../App';
import { TranslationContext } from '../../App';
import coverImage from './../styles/DamaLUFTCover.jpg';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "var(--orangeDark)",
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  successMessage: {
    color: '#0ba764',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  splitScreen : {
    display : "flex",
    gap : "100px"
  },
  coverImage : {
    position : "relative",
    top : "105px",
    width : "45vw",
    borderRadius : "10px",
    [theme.breakpoints.down(1600)] : {
      top : "130px"
    },
    [theme.breakpoints.down(1360)] : {
      top : "155px"
    },
    [theme.breakpoints.down(1150)] : {
      top : "180px"
    }
  },
  coverImageContainer : {
    [theme.breakpoints.down(1000)] : {
      display : "none"
    }
  }
}));

export const Register = () => {
  const classes = useStyles();
  const { translations } = useContext(TranslationContext);
  const { apiPath } = useContext(DialogContext)
  const [register, setRegister] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    companyString : ''
  });
  const [error, setError] = useState('');
  const [data, setData] = useState('');


  const mutation = useMutation((formData) => {
    const token = localStorage.getItem("token");
    return axios.post(`${apiPath}register.php`, formData, { headers: { Authorization: `Bearer ${token}` } }); 
  });

  const onSubmit = (event) => {
    event.preventDefault();
    setData('');
    setError('');
    const data = new FormData(event.target);

    mutation.mutate(
      {
        email: data.get('email'),
        password: data.get('password'),
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        companyString: data.get('companyString'),
      },
      {
        onSuccess: (dataReturned) => {
          if(dataReturned.data.success === 0){
            setError(translations?.backend[dataReturned.data.message])
            return
          }
          
          setData(translations?.backend[dataReturned.data.message])
        },
        onError: (error) => {
          setError(error.response.data.message);
        }
      }
    );
  };
  
  return (
    <Container component='main' maxWidth='xl' style={{padding : "0px"}}>
      <CssBaseline />
      <div className={classes.splitScreen}>
          <Container maxWidth='xs' className={classes.coverImageContainer}>
            <img src={coverImage} className={classes.coverImage}></img>
          </Container>
          <Container maxWidth='xs'>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              {translations?.authentication?.register}
            </Typography>
            <form className={classes.form} onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete='fname'
                    name='firstName'
                    variant='outlined'
                    required
                    fullWidth
                    id='firstName'
                    onFocus={() => {window.scroll(0, 150);}} 
                    label={translations?.users?.firstName}
                    value={register.firstName}
                    onChange={(evt) => {
                      window.scroll(0, 150);
                      setRegister((state) => ({ ...state, firstName: evt.target.value, }))
                    }}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete='lname'
                    name='lastName'
                    variant='outlined'
                    required
                    fullWidth
                    id='lastName'
                    onFocus={() => {window.scroll(0, 200);}} 
                    label={translations?.users?.lastName}
                    value={register.lastName}
                    onChange={(evt) => setRegister((state) => ({...state, lastName: evt.target.value, }))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    id='email'
                    onFocus={() => {window.scroll(0, 250);}} 
                    label={translations?.users?.email}
                    name='email'
                    value={register.email}
                    onChange={(evt) => setRegister((state) => ({...state, email: evt.target.value, }))}
                    autoComplete='email'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    name='password'
                    label={translations?.authentication?.password}
                    onFocus={() => {window.scroll(0, 300);}} 
                    type='password'
                    id='password'
                    value={register.password}
                    onChange={(evt) => setRegister((state) => ({ ...state, password: evt.target.value, }))}
                    autoComplete='current-password'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    name='companyString'
                    label={translations?.authentication?.companyString}
                    id='companyString'
                    value={register.companyString}
                    onFocus={() => {window.scroll(0, 350);}} 
                    onChange={(evt) =>
                      setRegister((state) => ({
                        ...state,
                        companyString: evt.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
              <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                {translations?.authentication?.register}
              </Button>
              <Grid container>
                <Grid item xs style={{fontSize : "0.8em"}}>
                  <Link to='/register'
                    onClick={() => setRegister({ firstName: '', lastName: '', email: '', password: '', companyString : ''})}
                  >
                    {translations?.authentication?.createNewAccount}
                  </Link>
                </Grid>
                <Grid item style={{fontSize : "0.8em"}}>
                  <Link to='/login'>{translations?.authentication?.haveAccount}</Link>
                </Grid>
              </Grid>
              {!!error && (
                <>
                    <div style={{ textAlign: 'center', paddingTop: '15px', marginBottom : "50px", color: 'red',}}>
                      <ErrorOutlineRounded style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                      <label className={classes.errorMessage}>{error}</label>
                    </div>
                </>
              )}
              {!!data && (
                <div style={{ textAlign: 'center', paddingTop: '15px', marginBottom : "50px", color: '#0ba764', fontSize: '16px',}}>
                  <CheckCircleOutlineRounded style={{ verticalAlign: 'middle', marginRight: '5px' }}/>
                  <label className={classes.successMessage}>{data}</label>
                </div>
              )}
            </form>
          </div>
        </Container>
      </div>
    </Container>
  );
};
