import axios from 'axios';
import React from 'react';
import { SERVER_URL } from '../utils/env.config';
import { Header } from './header.js';
import { Footer } from './footer.js'
import { Menu } from './menu.js'

interface WrapperProps {
  children: React.ReactNode;
}

export const Wrapper = ({ children: Children }: WrapperProps) => {
  if (process.env.NODE_ENV !== 'development') {
    axios.defaults.withCredentials = true;
  }

  axios.defaults.baseURL = SERVER_URL;
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  
  return (
    <>
      <Header />
      <Menu />
      {Children}
      <Footer />
    </>
  );
};
