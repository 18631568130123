import React, {useEffect, useState, useContext } from "react";
import Select from "react-select";
import { NTextField, SaveButton } from "./../edit-elements";
import './../styles/globalClasses.css';
import { TranslationContext } from "../../App";

const AddProduct = ({addProduct, serversDropDown}) => {
    const {translations} = useContext(TranslationContext)
    const [addProductVisible, setAddProductVisible] = useState(false)
    const [currentServer1, setCurrentServer1] = useState({});
    const [currentServer2, setCurrentServer2] = useState({});
    
    useEffect(()=>{
      setCurrentServer1(serversDropDown[0])
      setCurrentServer2(serversDropDown[0])
    }, [serversDropDown])

    const stopAddProduct = () => {
        setAddProductVisible(false);
    }
    const handleConfirm = (ob) => {
        addProduct({productName : ob.newValues[0], productDescription : ob.newValues[4], subID : ob.newValues[1], 
                    version : ob.newValues[2], api : ob.newValues[3], serverID1 : currentServer1.value, serverID2 : currentServer2.value})
        setAddProductVisible(false);
    }

    return (
        <div className="addSection">
            {!addProductVisible &&
                <div style={{textAlign: "center"}}>
                    <SaveButton
                    name={translations?.products?.addProduct}
                    klasaButton={"buttonAdd"}
                    handleButtonClick={() => setAddProductVisible(true)}
                    />
                </div>
            }
            {addProductVisible &&
                <React.Fragment>
                  <h2 className={"addSectionGlavenNaslov"}>{translations?.products?.addProduct}</h2>
                  <div className={"childAddFS"} style={{position : "relative", top : "10px"}}>
                      <h3 className={"naslovMal"}>{translations?.servers?.primaryServer}</h3>
                      <Select
                          onChange={(newValue, actionMeta) => {
                          if (actionMeta.action === "select-option")
                              setCurrentServer1(newValue);
                          }}
                          options={serversDropDown}
                          value={currentServer1}
                      />
                  </div>
                  <div className={"childAddFS"} style={{position : "relative", top : "10px"}}>
                      <h3 className={"naslovMal"}>{translations?.servers?.backupServer}</h3>
                      <Select
                          onChange={(newValue, actionMeta) => {
                          if (actionMeta.action === "select-option")
                              setCurrentServer2(newValue);
                          }}
                          options={serversDropDown}
                          value={currentServer2}
                      />
                  </div>                
                  <NTextField 
                    naslov={[translations?.products?.productName, "Sub ID", translations?.products?.version, translations?.products?.streamingAPI, translations?.products?.fotaAPI, translations?.products?.productDescription]}
                    labels={[translations?.products?.productName, "Sub ID", translations?.products?.version, translations?.products?.streamingAPI, translations?.products?.fotaAPI, translations?.products?.productDescription]}
                    inits={["", "", "", "", "", ""]}
                    tip={"add"}
                    autoFocus={[true, false, false, false, false, false]}
                    placeholder={["DamaLuft WiFi", "a", "1", "/hard4700a1.php", "/getFirmware.php", translations?.products?.productDescriptionPlaceholder]}
                    multiline={[false, false, false, false, false, true]}
                    handleEnter={handleConfirm}
                    klasa={"childAddFS"}
                    klasaNaslov={"naslovMal"}
                    klasaButton={"buttonConfirm"}
                    save={true}
                    buttonName={translations?.core?.confirm}
                    cancel={true}
                    cancelKlasa={"buttonCancel"}
                    cancelFunc={stopAddProduct}
                    allowEnter={false}
                    />
                    {/* <SaveButton name={"Cancel"} handleButtonClick={stopAddProduct} klasaButton={"buttonCancel"}/> */}
                </React.Fragment>
            }
        </div>
    )
}

export default AddProduct;