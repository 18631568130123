import React, { useEffect, useState, useContext } from "react";
import {NTextField, EditSectionNaslov} from './../edit-elements'
import CriticalAction from "../critical-action";
import './../styles/globalClasses.css';
import { TranslationContext } from "../../App";

const EditSection = ({current, updateServer}) => {
    const {translations} = useContext(TranslationContext)
    const [isExpanded, setIsExpanded] = useState({domain : false, certificate : false})
    const [newCurrent, setNewCurrent] = useState({});
    const [criticalAction, setCriticalAction] = useState({state : false, name : "", text : ""})
    const [criticalValue, setCriticalValue] = useState({});

    useEffect(()=>{
      setNewCurrent({...current})
    }, [current])
    
    const handleSelect = (tip) => {
      let newIsExpanded = {...isExpanded}
      newIsExpanded[tip] = !newIsExpanded[tip]
      setIsExpanded(newIsExpanded)
    }
    const handleCritical = () => {
      updateServer({...criticalValue});
      setCriticalValue({})
      setCriticalAction({state : false, name : "", text : ""})
    }  

    const handleEnter = ({tip, newValues, labels}) => {
      switch(tip){
        case "domain": 
            setCriticalAction({state : true, name : translations?.servers?.criticalDomain,
                               text : `${translations?.servers?.criticalDomainText[0]}\n\n` +
                               `${current.domain}\n\n${translations?.servers?.criticalDomainText[1]}`})
            setNewCurrent({...newCurrent, domain : newValues[0]})
            setCriticalValue({tip : tip, domain : newValues[0], serverID : current.id}); 
          break;
        case "certificate":
            setCriticalAction({state : true, name : translations?.servers?.criticalCertificate,
                              text : `${translations?.servers?.criticalCertificateText[0]}\n\n` + 
                              `${current.domain}\n\n${translations?.servers?.criticalCertificateText[1]}`})
            setNewCurrent({...newCurrent, certificate : newValues[0]})
            setCriticalValue({tip : tip, certificate : newValues[0], serverID : current.id}); 
          break;
        default: 
            break;
      }
    }
  
    return (
      <React.Fragment>
        <h2 className={"editSectionGlavenNaslov"}>{translations?.servers?.serversEdit}</h2>
          <div className={"editSectionLeft"}>  
            <EditSectionNaslov naslov={translations?.servers?.domain} expanded={isExpanded.domain} tip={"domain"} handleSelect={handleSelect}/>
            {
              isExpanded.domain && 
              <NTextField 
                labels={[translations?.servers?.domain]}
                inits={[newCurrent.domain]}
                tip={"domain"}
                multiline={[false]}
                handleEnter={handleEnter}
                klasa={"cellSingle"}
                save={true}
                buttonName={translations?.core?.save}
                klasaButton={"buttonSave"}
                allowEnter={false}
              />
            }
          </div>
          <div className={"editSectionRight"}>  
            <EditSectionNaslov naslov={translations?.servers?.certificate} expanded={isExpanded.certificate} tip={"certificate"} handleSelect={handleSelect}/>
            {
              isExpanded.certificate && 
              <NTextField 
                labels={[translations?.servers?.certificate]}
                inits={[newCurrent.certificate]}
                tip={"certificate"}
                multiline={[false]}
                handleEnter={handleEnter}
                klasa={"cellSingle"}
                save={true}
                buttonName={translations?.core?.save}
                klasaButton={"buttonSave"}
                allowEnter={false}
              />
            }
          </div>
          {criticalAction.state && 
            <CriticalAction 
                name={criticalAction.name}
                text={criticalAction.text}
                handleYes={handleCritical} 
                handleNo={() => {
                  setNewCurrent({...current})
                  setCriticalAction({state : false, name : "", text : ""})}
                } 
            />
          }
      </React.Fragment>
    )
  }
  
export default EditSection;  
