import React, {useState, useEffect, useContext} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';
import { TranslationContext } from '../../App';
import './localStyles.css';
  
const COLORS = {"pod" : "#1a660c", "medju" : "#f19100", "nad" : "#ee2f2f"};
const COLORS2 = ["#1a660c", "#f19100", "#ee2f2f"];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} fontWeight="bold" textAnchor="middle" fontSize="1.6em" fill={COLORS[payload.key]}>
                {/* {`${(percent * 100).toFixed(1)}%`} */}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={COLORS[payload.key]}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={COLORS[payload.key]}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text fontWeight="bold" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={COLORS[payload.key]}>{`${payload.name}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} fontWeight="bold" textAnchor={textAnchor} fill={COLORS[payload.key]}>
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        </g>
    );
};

            
export const AnalizaPieChart = (props) => {
    const [activeIndex, setActiveIndex] = useState(1)
    const [data, setData] = useState(props.pieChartData)
    const {translations} = useContext(TranslationContext)

    useEffect(() => {
        let newPieChartData = [];
        props.pieChartData.forEach((row, index) => {
            if(index === 0) newPieChartData.push({...row, name : translations?.data?.underLimit1})
            if(index === 1) newPieChartData.push({...row, name : translations?.data?.betweenLimits})
            if(index === 2) newPieChartData.push({...row, name : translations?.data?.overLimit2})
        })

        setData(newPieChartData)

        newPieChartData[2].value !== 0 ? setActiveIndex(2) :
            newPieChartData[1].value !== 0 ? setActiveIndex(1) : setActiveIndex(0);

    }, [props, translations])

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };

    return (
    <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
        <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"            
            innerRadius={65}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            paddingAngle={1}
        >
            {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
            ))}
        </Pie>
        </PieChart>
    </ResponsiveContainer>
    );
}


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent === 0)
    return <React.Fragment></React.Fragment>

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

export const AnalizaPieChartSmall = (props) =>  {
    const [data, setData] = useState(props.pieChartData)
    const {translations} = useContext(TranslationContext)

    useEffect(() => {
        setData(props.pieChartData)
    }, [props])

    if(data === undefined)
        return <React.Fragment></React.Fragment>

    return (
        <React.Fragment>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    isAnimationActive={false}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                    ))}
                </Pie>
                </PieChart>
            </ResponsiveContainer>
            <div className="legendDiv">
                <span className="legendSpan" style={{backgroundColor : COLORS2[0]}}></span>{translations?.data?.underLimit1}
            </div>
            <div className="legendDiv">
                <span className="legendSpan" style={{backgroundColor : COLORS2[1]}}></span>{translations?.data?.betweenLimits}
            </div>
            <div className="legendDiv">
                <span className="legendSpan" style={{backgroundColor : COLORS2[2]}}></span>{translations?.data?.overLimit2}
            </div>
        </React.Fragment>
    );
}
