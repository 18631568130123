import React, { useEffect, useState, useContext } from "react";
import ErrorPrivilegesPath from "../auth/error-privileges";
import Select from "react-select";
import { useQuery } from "react-query";
import axios from "axios";
import {SaveButton} from './../edit-elements'
import './../styles/globalClasses.css';
import { DialogContext, TranslationContext} from './../../App';
import {useHistory} from 'react-router-dom';

const ChooseLocation = (props) => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const {translations} = useContext(TranslationContext)
  const {setSuccessZero, apiPath, refreshState} = useContext(DialogContext)
  const history = useHistory();
  const [locData, setLocData] = useState([]);
  const [zgradi, setZgradi] = useState({});
  const [mesta, setMesta] = useState({});
  const [currentZgrada, setCurrentZgrada] = useState({});
  const [currentMesto, setCurrentMesto] = useState({});
  const [companyID, setCompanyID] = useState(props.companyID);
  
  useEffect(() => {
    let timer1 = setTimeout(() => {
      response.refetch();
    }, 1);
    setCompanyID(props.companyID);
    return () => {
      clearTimeout(timer1);
    };
  }, [props.companyID, companyID, props.zgradaName, props.mestoName]);

  const response = useQuery("company-locations", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiPath}fetchLocations.php?companyID=${companyID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        let newZgradi = [];
        let newMesta = [];

        response.data.locations.forEach((singleZgrada, indexZgrada) => {
            newZgradi.push({
                label: singleZgrada.zgradaName,
                value: singleZgrada.zgradaName,
            });

            if (singleZgrada.zgradaName === props.zgradaName) {
                setCurrentZgrada(newZgradi[indexZgrada]);
                singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                newMesta.push({
                    label: singleMesto.mestoName,
                    value: singleMesto.mestoName,
                });
                if (singleMesto.mestoName === props.mestoName) 
                    setCurrentMesto(newMesta[indexMesto]);
                });
            }
        });

        setLocData(response.data.locations);
        setZgradi(newZgradi);
        setMesta(newMesta);
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchinfLocations, text : translations?.backend[response.data.message]});
      }
    } catch (err) {
      // setData(dataConst.allowed);
    }
  });

  const handleChangeZgrada = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      setCurrentZgrada(newValue);
      locData.forEach((singleZgrada) => {
        if (singleZgrada.zgradaName === newValue.value) {
          let newMesta = [];
          singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
            newMesta.push({ label: singleMesto.mestoName, value: singleMesto.mestoName, });
            if (indexMesto === 0) {
                setCurrentMesto(newMesta[newMesta.length - 1])
            }
          });
          setMesta(newMesta);
        }
      });
    }
  };

  const handleChangeMesto = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      setCurrentMesto(newValue);
    }
  };

  const handleEnter = () => {
    locData.forEach((singleZgrada) => {
      if (singleZgrada.zgradaName === currentZgrada.label){
        singleZgrada.dataZ.forEach((singleMesto)=>{
          if (singleMesto.mestoName === currentMesto.label)
          props.handleEnter({tip : "location", newValues : [singleZgrada.zgradaID, singleMesto.mestoID]})
        })
      }
    })
  }

  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <React.Fragment>
      <div className={"dropDownDouble"}>
        <h3 className={"naslovMal"}>{translations?.locations?.building}</h3>
          <Select
            maxMenuHeight={120}
            onChange={(v, a) => {
              handleChangeZgrada(v, a);
            }}
            options={zgradi}
            value={currentZgrada}
          />
        </div>
      <div className={"dropDownDouble"}>
        <h3 className={"naslovMal"}>{translations?.locations?.place}</h3>
          <Select
            maxMenuHeight={120}
            onChange={(v, a) => {
              handleChangeMesto(v, a);
            }}
            options={mesta}
            value={currentMesto}
          />
      </div>
      <div style={{textAlign: "center"}}>
        <SaveButton 
          klasaButton={"buttonSave"}
          name={translations?.core?.save}
          handleButtonClick={handleEnter}
        />
      </div>
    </React.Fragment>
  );
};

export default ChooseLocation;

    